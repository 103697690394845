import { useAuth } from "react-oidc-context"
import { DataAPI } from "../api/DataAPI"
import { usePipelineRunsDrawerStore } from "../stores/PipelineRunsDrawerStore"
import { PipelineRun, PipelineRunDTO } from "../types/PipelineRunTypes"
import { usePipelineRunStore } from "../stores/PipelineRunStore"
import { useAppStore } from "../stores/AppStore"
import { Toaster } from "../utils/Toaster"
import { usePipelineRunsDialogFunctions } from "../hooks/usePipelineRunsDialogFunctions";

interface PipelineRunsDrawerFunctions {
  loadPipelineRuns: () => Promise<void>
  loadPipelineRun: (id: string) => void
  sortFilterPipelineRuns: () => PipelineRun[]
}

export const usePipelineRunsDrawersFunctions = (): PipelineRunsDrawerFunctions => {
  const { isSortAsc, setLoading, statusFilter, pipelineName } = usePipelineRunsDrawerStore()
  const { PipelineRuns, setPipelineRuns } = usePipelineRunStore()  
  const { setShowPipelineRunsDrawer, setShowPipelineRunsDialog } = useAppStore()
  const auth = useAuth()
  const PipelineRunAPI = new DataAPI<PipelineRun, PipelineRunDTO>("pipelineruns", auth.user?.access_token ?? "")
  const { loadDialog } = usePipelineRunsDialogFunctions()

  const loadPipelineRuns = async () => {
    setLoading(true)
    try {
      const pipelineruns = await PipelineRunAPI.getAll()
      setPipelineRuns(pipelineruns)
    }
    catch (e) {
      Toaster.show({ icon: "error", message: `There was an error loading pipeline runs.`, intent: "danger" })      
    }        
    setLoading(false)
  }

  const loadPipelineRun = (id: string) => {
    //setShowPipelineRunsDrawer(false)
    //Load and display data of the selected pipelineId
    setShowPipelineRunsDrawer(false)
    setShowPipelineRunsDialog(true)
    loadDialog(id)
  }
  const sortFilterPipelineRuns = () => {
    const sortAsc = (a: PipelineRun, b: PipelineRun) => (a.runDateTime < b.runDateTime) ? -1 : ((a.runDateTime < b.runDateTime) ? 1 : 0)
    const sortDesc = (a: PipelineRun, b: PipelineRun) => (a.runDateTime > b.runDateTime) ? -1 : ((a.runDateTime < b.runDateTime) ? 1 : 0)
    return PipelineRuns
      .sort(isSortAsc ? sortAsc : sortDesc)
      .filter(v => statusFilter ? v.status === statusFilter : true)
      .filter(v => pipelineName ? v.pipelineId === pipelineName : true)
  }

  return { loadPipelineRuns, loadPipelineRun, sortFilterPipelineRuns }
}