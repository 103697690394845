import { UseControllerProps, useController } from 'react-hook-form';
import { MenuItem } from "@blueprintjs/core/lib/esm/components/menu/menuItem";
import { Tooltip2 } from "@blueprintjs/popover2/lib/esm/tooltip2"
import { FieldProps } from '../../types/OrganismTypes';
import { MultiSelect2 , Select2 } from "@blueprintjs/select"; 

interface MultiSelectInput extends UseControllerProps<any> {
  fieldProps: FieldProps,
  disabled?: boolean,
  required?: boolean,
  hideLable?: boolean,
}

export function MultiSelectInput(props: MultiSelectInput) {

  const { field, fieldState } = useController(props)

  const fieldProps = props.fieldProps

  const control = (() => {
    return (
        <MultiSelect2  
            disabled={props.disabled}
            activeItem={''}
            items={fieldProps.enum!} 
            selectedItems={field.value || []} 
            onActiveItemChange={
              ()=> {}
            }
            onItemSelect={
              ()=> {}
            }
            itemRenderer={(val, itemProps) => { 
                return ( 
                    <MenuItem 
                        key={val} 
                        text={val} 
                        onClick={(elm:any) => {
                            field.onChange([...field.value, elm.target.textContent])
                        }} 
                        active={itemProps.modifiers.active} 
                    /> 
                ); 
            }} 
            tagRenderer={(item) => item} 
            onRemove={(item) => { 
                field.onChange([...field.value].filter(el => el !== item)) 
            }} 
            onClear={() => field.onChange([])} 
        />) 
    }   
  )()

  return <div className='input-ctr'>
    <div className='bp4-label'>
      <div className='inline-flex'>
        <Tooltip2 content={fieldProps.description}>
          <div className="label-container">
            {props.hideLable ? '' : fieldProps.title || props.name}
            {props.required && <span className='required-asterisk'>*</span>}
          </div>
        </Tooltip2>
        <div className='inline-flex' style={{ gap: 5 }}>
          <div className='control-ctr'>{control}</div>
        </div>
      </div>
      <div className='errorText'>{fieldState.error?.message}</div>
    </div>
  </div>
}
