import { create } from "zustand"
import { useViewFormStore } from "./ViewFormStore"

interface ImportMetadataDialogState {
  showImportMetadataDialog: boolean
  setShowImportMetadataDialog: (showImportMetadataDialog: boolean) => void
}

export const useImportMetadataDialogStore = create<ImportMetadataDialogState>((set) => ({
  showImportMetadataDialog: false,
  setShowImportMetadataDialog: (showImportMetadataDialog: boolean) => {
    useViewFormStore.setState({ showViewForm: false })
    set({ showImportMetadataDialog })
  },
}))