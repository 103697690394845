import { create } from "zustand";

interface PipelineRunsDrawerState {
  isSortAsc?: boolean,
  isCountSortAsc?: boolean,
  loading: boolean
  showPipelineRunsDrawer: boolean
  statusFilter: string | undefined
  pipelineName: string | undefined
  setIsSortAsc: (isSortAsc: boolean) => void
  setIsCountSortAsc: (isCountSortAsc: boolean) => void
  setLoading: (loading: boolean) => void
  setStatusFilter: (statusFilter: string | undefined) => void
  setPipelineNameFilter: (pipelineName: string | undefined) => void
}

export const usePipelineRunsDrawerStore = create<PipelineRunsDrawerState>((set) => ({
  isSortAsc: false,
  isCountSortAsc: false,
  loading: false,
  showPipelineRunsDrawer: false,
  statusFilter: undefined,
  pipelineName: undefined,
  setIsSortAsc: (isSortAsc: boolean) => set({ isSortAsc, isCountSortAsc: undefined }),
  setIsCountSortAsc: (isCountSortAsc: boolean) => set({isCountSortAsc, isSortAsc: undefined}),
  setLoading: (loading: boolean) => set({ loading }),
  setStatusFilter: (statusFilter: string | undefined) => set({ statusFilter }),
  setPipelineNameFilter: (pipelineName: string | undefined) => set({ pipelineName })
}))