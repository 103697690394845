import { create } from "zustand";

interface OpenComparisonsDrawerState {
  showOpenComparisonDrawer: boolean
  setShowOpenComparisonDrawer: (showOpenComparisonDrawer: boolean) => void
}

export const useOpenComparisonDrawerStore = create<OpenComparisonsDrawerState>((set) => ({
  showOpenComparisonDrawer: false,
  setShowOpenComparisonDrawer: (showOpenComparisonDrawer: boolean) => set({ showOpenComparisonDrawer })
}))