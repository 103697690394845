import { create } from "zustand";
import { FileSource, SelectedFile } from "../components/startPipelineDialog/StartPipelineDialog";
import { SelectedSample } from "../components/startPipelineDialog/FileSelectBaseSpace";
import { Sample } from "../types/SampleTypes";
import { PipelineRun } from "../types/PipelineRunTypes";
import { usePipelinesStore } from "./PipelinesStore";

export interface ValidatedSample {
  Key: string,
  exists?: boolean,
}

export interface SelectedFileWithMetadata extends SelectedFile {
  identifier: string
  data: SampleDataMetadata
}


export interface SampleDataMetadata {
  metadata: {
    Key: string
    SEQUENCERRUN_ID?: string
    NCBI_ACCESSION?: string
    SRR_ID?: string
  }
}

export interface StartPipelineDialogState {
  showStartPipelineDialog: boolean
  showStartCalcPipelineDialog: boolean
  selectedFiles: SelectedFile[]
  source: FileSource | undefined  
  totalSelectedSamples: SelectedSample[]
  uploadComplete: boolean
  setSelectedFiles: (selectedFiles: SelectedFile[]) => void
  addSelectedFiles: (selectedFiles: SelectedFile[]) => void
  removeFile: (fileName: string) => void
  setSource: (source: FileSource | undefined) => void
  setTotalSelectedSamples: (totalSelectedSamples: SelectedSample[]) => void
  setUploadComplete: (uploadComplete: boolean) => void
  filesUploading: boolean
  setFilesUploading: (filesUploading: boolean) => void
  showPipelineRunStatus: boolean
  setShowPipelineRunStatus: (showPipelineRunStatus: boolean) => void
  pipelineRun?: PipelineRun
  setPipelineRun: (pipelineRun: PipelineRun) => void
  currentFile?: SelectedFileWithMetadata
  setCurrentFile: (currentFile?: SelectedFileWithMetadata) => void
  validatedSamples: ValidatedSample[]
  setValidatedSamples: (validatedSamples: ValidatedSample[]) => void
  pipelineStarting: boolean
  setPipelineStarting: (pipelineStarting: boolean) => void
  samples: Sample[]
  setSamples: (samples: Sample[]) => void
  linkingFiles: boolean
  setLinkingFiles: (linkingFiles: boolean) => void
  initialStepIndex: number
  setinitialStepIndex: (initialStepIndex: number) => void
  linkingComplete: boolean
  setLinkingComplete: (linkingComplete: boolean) => void
  resetDialog: () => void
  startPipeline: boolean
  setStartPipeline: (startPipeline: boolean) => void
  selectedTemplateName: string
  setSelectedPipelineId: (selectedTemplateName: string) => void
  selectedPipelineId: string
  setSelectedTemplateName: (selectedPipelineId: string) => void
  basespaceProjectAsSequencerRunId: boolean
  setBasespaceProjectAsSequencerRunId: (basespaceProjectAsSequencerRunId: boolean) => void
}

export const useStartPipelineDialogStore = create<StartPipelineDialogState>((set) => ({
  showStartPipelineDialog: false,
  showStartCalcPipelineDialog: false,
  selectedFiles: [],
  source: undefined,
  totalSelectedSamples: [],
  uploadComplete: false,
  setSelectedFiles: (selectedFiles: SelectedFile[]) => set({ selectedFiles }),
  addSelectedFiles: (selectedFiles: SelectedFile[]) => set((state) => ({
    selectedFiles: [
      ...state.selectedFiles, 
      ...selectedFiles.filter(file => !state.selectedFiles.some(f => f.name === file.name))
    ]
  })),
  removeFile: (fileName: string) => set((state) => ({
    selectedFiles: state.selectedFiles.filter(file => file.name !== fileName)
  })),
  setSource: (source: FileSource | undefined) => set({ source }),
  setTotalSelectedSamples: (totalSelectedSamples: SelectedSample[]) => set({ totalSelectedSamples }),
  setUploadComplete: (uploadComplete: boolean) => set({ uploadComplete }),
  filesUploading: false,
  setFilesUploading: (filesUploading: boolean) => set({ filesUploading }),
  showPipelineRunStatus: false,
  setShowPipelineRunStatus: (showPipelineRunStatus: boolean) => set({ showPipelineRunStatus }),
  pipelineRun: undefined,
  setPipelineRun: (pipelineRun: PipelineRun) => set({ pipelineRun }),
  currentFile: undefined,
  setCurrentFile: (currentFile?: SelectedFileWithMetadata) => set({ currentFile }),
  validatedSamples: [],
  setValidatedSamples: (validatedSamples: ValidatedSample[]) => set({ validatedSamples }),
  pipelineStarting: false,
  setPipelineStarting: (pipelineStarting: boolean) => set({ pipelineStarting }),
  samples: [],
  setSamples: (samples: Sample[]) => set({ samples }),
  linkingFiles: false,
  setLinkingFiles: (linkingFiles: boolean) => set({ linkingFiles }),
  initialStepIndex: 0,
  setinitialStepIndex: (initialStepIndex: number) => set({ initialStepIndex }),
  linkingComplete: false,
  setLinkingComplete: (linkingComplete) => set({ linkingComplete }),  
  resetDialog: () => set({
    showStartPipelineDialog: false,
    showStartCalcPipelineDialog: false,
    selectedFiles: [],
    source: undefined,
    totalSelectedSamples: [],
    uploadComplete: false,
    filesUploading: false,
    showPipelineRunStatus: false,
    pipelineRun: undefined,
    currentFile: undefined,
    validatedSamples: [],
    pipelineStarting: false,
    samples: [],
    linkingFiles: false,
    initialStepIndex: 0,
    linkingComplete: false,
    startPipeline: true,
    selectedPipelineId: (usePipelinesStore.getState().pipelines && usePipelinesStore.getState().pipelines![0] && usePipelinesStore.getState().pipelines![0].id) ?? "",
    selectedTemplateName: "",
    basespaceProjectAsSequencerRunId: false
  }),
  startPipeline: true,
  setStartPipeline: (startPipeline) => set({startPipeline}),
  selectedPipelineId: (usePipelinesStore.getState().pipelines && usePipelinesStore.getState().pipelines![0] && usePipelinesStore.getState().pipelines![0].id) ?? "",
  setSelectedPipelineId: (selectedPipelineId) => set({selectedPipelineId}),
  selectedTemplateName: "",
  setSelectedTemplateName: (selectedTemplateName) => set({selectedTemplateName}),
  basespaceProjectAsSequencerRunId: false,
  setBasespaceProjectAsSequencerRunId: (basespaceProjectAsSequencerRunId) => set({basespaceProjectAsSequencerRunId}),
}))

// const [showPipelineRunStatus, setShowPipelineRunStatus] = useState<boolean>(false)
// const [pipelineRun, setPipelineRun] = useState<PipelineRun>()
// const [currentFile, setCurrentFile] = useState<SelectedFile>()
// const [validatedSamples, setValidatedSamples] = useState<ValidatedSample[]>([])
// const [pipelineStarting, setPipelineStarting] = useState(false);
// const [samples, setSamples] = useState<Sample[]>([]);
// const [linkingFiles, setLinkingFiles] = useState<boolean>(false);