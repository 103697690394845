import { create } from "zustand"
import { Lab, PipelineTemplate } from "../types/LabTypes"

interface PipelineTemplatesDialogState {
  isPipelineTemplateDialogOpen: boolean
  setIsPipelineTemplateDialogOpen: (isPipelineTemplateDialogOpen: boolean) => void
  activeTemplate: PipelineTemplate | undefined
  setActiveTemplate: (activeTemplate: PipelineTemplate | undefined) => void
  deletingIndex: number | undefined
  setDeletingIndex: (deletingIndex: number | undefined) => void
  lab: Lab | undefined
  setLab: (lab: Lab) => void
}

export const usePipelineTemplatesDialogStore = create<PipelineTemplatesDialogState>((set) => ({
  isPipelineTemplateDialogOpen: false,
  setIsPipelineTemplateDialogOpen: (isPipelineTemplateDialogOpen: boolean) => set({ isPipelineTemplateDialogOpen }),
  activeTemplate: undefined,
  setActiveTemplate: (activeTemplate: PipelineTemplate | undefined) => set({ activeTemplate }),
  deletingIndex: undefined,
  setDeletingIndex: (deletingIndex: number | undefined) => set({ deletingIndex }),
  lab: undefined,
  setLab: (lab: Lab) => set({ lab })
}))