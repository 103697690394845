import { Dialog } from "@blueprintjs/core/lib/esm/components/dialog/dialog"
import { useEffect, useState, useMemo } from "react"
import { Button } from "@blueprintjs/core/lib/esm/components/button/buttons"
import { Spinner } from "@blueprintjs/core/lib/esm/components/spinner/spinner"
import { Card } from "@blueprintjs/core/lib/esm/components/card/card"
import { useAuth } from "react-oidc-context"
import { UserAPI , LabAPI } from "../api/DataAPI"
import { useAdminStore } from "../stores/AdminStore"
import { useOrganizationStore } from "../stores/OrganizationStore"
import { useUserStore } from "../stores/UserStore"
import { FormInput } from "./controls/FormInput"
import { MultiSelectInput } from "./controls/MultiSelectInput"
import { Toaster } from "../utils/Toaster"
import {User} from '../types/UserTypes'
import {Lab} from '../types/LabTypes'
import { useForm } from 'react-hook-form';
import { Fields } from "../types/OrganismTypes"

interface AdminDialogueProps {
    type: string
    fields: Fields
    setUserList: React.Dispatch<React.SetStateAction<User[]>>
    setLabList: React.Dispatch<React.SetStateAction<Lab[]>>
    labList: string[]    
    defaults: any
}

export const AdminDialogue = ({type , fields , setUserList, setLabList, labList, defaults}: AdminDialogueProps) => {

    const { adminItem , showAdminDialogue , setShowAdminDialogue, setAdminItem} = useAdminStore()
    const {organization, organizationList} = useOrganizationStore()
    const {user} = useUserStore()
    const auth = useAuth()
    const form = useForm({
        mode: 'onBlur',
    })
    const { register, control, handleSubmit, reset } = form
    const userAPI = new UserAPI(auth.user?.access_token ?? "")
    const labAPI = new LabAPI(auth.user?.access_token ?? "")

    const [saving, setSaving] = useState(false)
    const [focusSave, setFocusSave] = useState(false)
    const [focusDelete, setFocusDelete] = useState(false)

    const title = () => {
        if(type === 'users'){
            if(adminItem){
                return `Update User: ${adminItem.name}`
            } else {
                return 'Add User'
            }
        } else if(type ==='labs') {
            if(adminItem){
                return `Update Lab: ${adminItem.name}`
            } else {
                return 'Add Lab'
            }
        }
    }

    const getFieldControls = () => {
        return Object.entries(fields.properties)
          .map(([k, v]) => {
            if(v.type === 'date' || v.path === 'updatedBy' || v.path === 'createdBy'){
                return
            }
            if(v.type === 'multiselect' && v.title === "Organization"){
                v.enum = organizationList?.map(orgz => orgz.organizationName) ?? []
                return <MultiSelectInput key={k} name={k} control={control} fieldProps={v} />
            }
            if(v.type === 'multiselect'){
                return <MultiSelectInput key={k} name={k} control={control} fieldProps={v} />
            }            
            if(v.type === 'select2'){
                v.enum = labList
            }
            return <FormInput key={k} name={k} control={control} required={true} fieldProps={v} register={register} adminEntity={true}></FormInput>

          })
    }

    async function onSubmit(data: any){
        for(let key in data){
            if((data[key] === '') && key !== 'createdBy' && key !== 'updatedBy'){
                return Toaster.show({ icon: "error", message: `You must fill out all required fields.`, intent: "danger" })
            }if(key === 'role' && data[key].length === 0){
                return Toaster.show({ icon: "error", message: `You must fill out all required fields.`, intent: "danger" })
            }
        }
        const emailRegex: RegExp =  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

        if(adminItem){
            if(type === 'users'){
                if(!emailRegex.test(data.email)) {
                    return Toaster.show({ icon: "error", message: `Invalid email format.`, intent: "danger" })
                }
                try {
                    setSaving(true)
                    console.log(data)
                    const userRes = await userAPI.update(data.id, data)
                    setUserList(prev => [...prev].map((el) => {
                        if(el.id === data.id){
                            return data
                        }
                        return el
                    }))
                    Toaster.show({ icon: "tick", message: `Update successful.`, intent: "success" })
                    setShowAdminDialogue(false)
                    setSaving(false)
                } catch (error) {
                    console.error(error)
                    Toaster.show({ icon: "error", message: `Error: ${error}`, intent: "danger" })
                    setSaving(false)
                }
            } else if(type === 'labs'){
                try {
                    setSaving(true)
                    const labRes = await labAPI.update(data.id, data)
                    setLabList(prev => [...prev].map((el) => {
                        if(el.id === data.id){
                            return data
                        }
                        return el
                    }))
                    Toaster.show({ icon: "tick", message: `Update successful.`, intent: "success" })
                    setShowAdminDialogue(false)
                    setSaving(false)
                } catch (error) {
                    console.error(error)
                    Toaster.show({ icon: "error", message: `Error: ${error}`, intent: "danger" })
                    setSaving(false)
                }
            }
        }else{
            if(type === 'users'){
                if(!emailRegex.test(data.email)) return Toaster.show({ icon: "error", message: `Invalid email format.`, intent: "danger" })
                const userPayload = {
                    ...data,
                    createdBy: user?.userId, 
                    createdAt: new Date(),
                    updatedAt: new Date(),
                    updatedBy: user?.userId,
                    favoriteViews: [],
                    favoriteComparisons: [],
                    importTemplates: []
                } as User
                try {
                    console.log(userPayload)
                    setSaving(true)
                    const userRes = await userAPI.insert(userPayload)
                    setUserList(prev => [...prev , userRes])
                    Toaster.show({ icon: "tick", message: `New user saved successfully.`, intent: "success" })
                    setShowAdminDialogue(false)
                    setSaving(false)
                } catch (error) {
                   console.error(error)
                   Toaster.show({ icon: "error", message: `Error: ${error}`, intent: "danger" })
                   setSaving(false)

                }

            } else if(type === 'labs'){
                const labPayload = {
                    ...data,
                    organization: organization?.organizationName,
                    createdBy: user?.userId, 
                    createdAt: new Date(),
                    updatedAt: new Date(),
                    updatedBy: user?.userId
                } as Lab
                try {
                    setSaving(true)
                    const labRes = await labAPI.insert(labPayload)
                    setLabList(prev => [...prev , labRes])
                    Toaster.show({ icon: "tick", message: `New lab saved successfully.`, intent: "success" })
                    setShowAdminDialogue(false)
                    setSaving(false)
                } catch (error) {
                    console.error(error)
                    Toaster.show({ icon: "error", message: `Error: ${error}`, intent: "danger" })
                    setSaving(false)
                }

            }
                
        }
    }

    async function onDelete() {
        if(window.confirm("Are you sure you want to delete this item?")){
            try {
                setSaving(true)
                if(type === 'users'){
                    await userAPI.delete(adminItem?.id!)
                    setUserList((prev) => [...prev].filter((el) => {
                        if(el.id !== adminItem?.id!){
                            return el
                        } 
                    }))
                    Toaster.show({ icon: "tick", message: `User deleted successfully.`, intent: "success" })
                }
                else if(type === 'labs'){
                    await labAPI.delete(adminItem?.id!)
                    setLabList((prev) => [...prev].filter((el) => {
                        if(el.id !== adminItem?.id!){
                            return el
                        } 
                    }))
                    Toaster.show({ icon: "tick", message: `Lab deleted successfully.`, intent: "success" })
                }
                setShowAdminDialogue(false)
                setSaving(false)
            } catch (error) {
                console.error(error)
                Toaster.show({ icon: "error", message: `Error: ${error}`, intent: "danger" })
                setSaving(false)
            }
        }
    }

    useEffect(() => {
        if(adminItem)
            reset({...adminItem})
        else{
            reset({...defaults})
        }
    }, [adminItem])
    const focusBorder = {padding:'4px', borderStyle: 'solid', borderWidth:'2px', borderColor:'black'}
    return (
    <Dialog
    isOpen={showAdminDialogue}
    title={title()}
    onClose={() => {
        setAdminItem(null)
        setShowAdminDialogue(false)
    }}
    >
        <Card>
            <form onSubmit={handleSubmit(onSubmit)} className="pn-form" style={{ padding: 5, gap: 5, height: "100%" }}>
                    {getFieldControls()}
                <div style={{ marginTop: "10px", display: 'flex', gap: "5px", justifyContent: 'right', alignItems:'center'}}>  
                    <div style={focusSave?focusBorder:{}}>
                        <Button  disabled={saving} intent="primary" className="bp4-icon-floppy-disk" type="submit" onFocus={()=>setFocusSave(true)} onBlur={()=>setFocusSave(false)}>
                            <span style={{ display: 'flex', flexDirection: 'row' }}>
                                {saving?('saving...'):(adminItem?('Save Updates'):('Save New'))}
                                {saving && <Spinner style={{ marginLeft: '5px' }} size={15} />}
                            </span>
                        </Button> 
                    </div>              
                    {
                        adminItem && 
                            <div style={focusDelete? focusBorder:{}}>
                            <Button onFocus={()=>{setFocusDelete(true)}} onBlur={()=>{setFocusDelete(false)}} disabled={saving} intent="danger" onClick={onDelete}>Delete</Button>
                            </div>   
                    }  
                    <Button onClick={() => setShowAdminDialogue(false)}>Close</Button>
                </div>
            </form>
        </Card>
    </Dialog>
    )
}