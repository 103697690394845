export function getPatternValue(pattern: string, patternType: string, data: string) {
  let regex;
  if (patternType === 'regex' && isValidPattern(pattern, patternType)) {
    //case-insensitive
    regex = new RegExp(pattern, 'i');
  } else if (patternType === 'wildcard' && isValidPattern(pattern, patternType)) {
    let regexPattern = pattern.replace(/\*/g, '.*?').replace(/\[(d|D)(a|A)(t|T)(a|A)\]/, '(.+?)');
    regex = new RegExp(regexPattern);
  } else {
    return '';
  }
  const match = data.match(regex);
  return match && match[1] ? match[1] : '';
};

// Check pattern type and validate accordingly
function isValidPattern(pattern: string, patternType: string): boolean {
  try {
    if (patternType === 'regex') {
      //case-insensitive
      new RegExp(pattern, 'i');
    } else if (patternType === 'wildcard') {
      // Check if '[DATA]' (case-insensitive) exists exactly once in the pattern
      const matchData = pattern.match(/\[(d|D)(a|A)(t|T)(a|A)\]/g) || [];
      if (matchData.length !== 1) throw new Error();
      let regexPattern = pattern.replace(/\*/g, '.*?').replace(/\[(d|D)(a|A)(t|T)(a|A)\]/, '(.+?)');

      new RegExp(regexPattern);
    } else {
      throw new Error();
    }
    return true;
  } catch (e) {
    //console.error(e)
    return false;
  }
};  