import { create } from "zustand";

interface EditDataViewSelectStore {
  showSingleclickedit: boolean
  setShowSingleclickedit: (showSingleclickedit: boolean) => void
  isComparison: boolean
  setIsComparison: (isComparison: boolean) => void
}

export const useEditDataViewSelectStore = create<EditDataViewSelectStore>((set) => ({
  showSingleclickedit: false,
  setShowSingleclickedit: (showSingleclickedit: boolean) => set({ showSingleclickedit }),
  isComparison: false,
  setIsComparison: (isComparison: boolean) => set({ isComparison })
}))