import { create } from 'zustand'
import { PipelineRunEvent } from '../types/EventTypes';

interface EventState {
    Events: PipelineRunEvent[]
    setEvents: (Events: PipelineRunEvent[]) => void
  }

  export const useEventStore = create<EventState>((set) => ({
    Events: [],
    setEvents: (Events) => {
      set({ Events })
    }
}));