import { Elevation } from "@blueprintjs/core/lib/esm/common/elevation";
import { H5 } from "@blueprintjs/core/lib/esm/components/html/html";
import { Card } from "@blueprintjs/core/lib/esm/components/card/card";
import { Button } from "@blueprintjs/core/lib/esm/components/button/buttons";
import { FormGroup } from "@blueprintjs/core/lib/esm/components/forms/formGroup";
import { RadioGroup } from "@blueprintjs/core/lib/esm/components/forms/radioGroup";
import { Radio } from "@blueprintjs/core/lib/esm/components/forms/controls";
import { InputGroup } from "@blueprintjs/core/lib/esm/components/forms/inputGroup";
import { useState, useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { useAppStore } from "../../stores/AppStore";
import { usePatternStore } from "../../stores/PatternStore";
import { Popover2 } from "@blueprintjs/popover2";
import { Classes } from "@blueprintjs/core";
import { useUserStore } from "../../stores/UserStore";
import ImportTemplateForm from "../ImportTemplateForm";
import { useStartPipelineDialogStore } from "../../stores/StartPipelineDialogStore";

interface Pattern {
  field: string,
  patternType: string,
  pattern: string
}

export const ImportRules = () => {
  const { source, selectedFiles, setSelectedFiles, basespaceProjectAsSequencerRunId } = useStartPipelineDialogStore()
  const { setRulesImporting } = useAppStore()
  const [keyPattern, setKeyPattern] = useState("");
  const [keyPatternType, setKeyPatternType] = useState("");
  const [sequenceRunIdPattern, setSequenceRunIdPattern] = useState("");
  const [sequenceRunIdPatternType, setSequenceRunIdPatternType] = useState("");
  // to store the input patterns and associated data in the dialog state
  const [rules, setRules] = useState<Pattern[]>([]);
  const { patterns, setPatterns } = usePatternStore();
  const [showKeyTemplatePopOver, setShowKeyTemplatePopOver] = useState(false);
  const [showSequenceTemplatePopOver, setShowSequenceTemplatePopOver] = useState(false);
  const { user, addImportTemplate, deleteImportTemplate } = useUserStore();

  // Default data string
  const defaultPattern = '^(\\w*)_\\S*\\.fastq\\.gz$';

  const [keyOutput, setKeyOutput] = useState("");
  const [sequenceRunIdOutput, setSequenceRunIdOutput] = useState("");

  const auth = useAuth();
  if (!auth.user && !(process.env["REACT_APP_OVERRIDE_AUTH"] === "true"))
    throw new Error("No authenticated user found.")

  // When rendered, if 'patterns' is not null, display this data in the corresponding fields.
  useEffect(() => {
    if (patterns) {
      const keyPatternObject = patterns.find((pattern) => pattern.field === "Key");
      const sequenceRunIdPatternObject = patterns.find((pattern) => pattern.field === "SEQUENCERRUN_ID");

      if (keyPatternObject) {
        setKeyPatternType(keyPatternObject.patternType);
        setKeyPattern(keyPatternObject.pattern);
      }
      else if (user?.importTemplates?.filter(v => v.field === "Key" && v.defaultPattern)[0]) {
        const defaultTemplate = user?.importTemplates?.filter(v => v.field === "Key" && v.defaultPattern)[0]
        setKeyPatternType(defaultTemplate.patternType);
        setKeyPattern(defaultTemplate.pattern);
      }

      if (sequenceRunIdPatternObject) {
        setSequenceRunIdPatternType(sequenceRunIdPatternObject.patternType);
        setSequenceRunIdPattern(sequenceRunIdPatternObject.pattern);
      }
      else if (user?.importTemplates?.filter(v => v.field === "SEQUENCERRUN_ID" && v.defaultPattern)[0]) {
        const defaultTemplate = user?.importTemplates?.filter(v => v.field === "SEQUENCERRUN_ID" && v.defaultPattern)[0]
        setSequenceRunIdPatternType(defaultTemplate?.patternType ?? "");
        setSequenceRunIdPattern(defaultTemplate?.pattern ?? "");
      }
    }
    if (source === "sra")
      setRulesImporting(!selectedFiles.filter(v => !v.name).length)
  }, []);

  // Check if all required fields are valid and filled out
  useEffect(() => {
    if (source === "sra")
      return
    if (
      keyPattern.trim() !== "" &&
      keyPatternType.trim() !== "" &&
      isValidPattern(keyPattern, keyPatternType)
    ) {
      if (sequenceRunIdPattern.trim() !== "" && sequenceRunIdPatternType.trim() !== "") {
        if (!isValidPattern(sequenceRunIdPattern, sequenceRunIdPatternType)) {
          setRulesImporting(false);
          return;
        }
      }
      setRulesImporting(true);

      //store input patterns as state
      const updatedRules = updateRules();
      setPatterns(updatedRules);
    } else {
      setRulesImporting(false);
    }
  }, [keyPattern, keyPatternType, sequenceRunIdPattern, sequenceRunIdPatternType]);

  const updateRules = () => {
    let updatedRules: Pattern[] = [...rules];
    if (isValidPattern(keyPattern, keyPatternType)) {
      const newRule: Pattern = { field: "Key", patternType: keyPatternType, pattern: keyPattern };
      updatedRules = [...updatedRules.filter(rule => rule.field !== "Key"), newRule];
    }
    if (sequenceRunIdPattern.trim() !== "" && sequenceRunIdPatternType.trim() !== "" && isValidPattern(sequenceRunIdPattern, sequenceRunIdPatternType)) {
      const newRule: Pattern = { field: "SEQUENCERRUN_ID", patternType: sequenceRunIdPatternType, pattern: sequenceRunIdPattern };
      updatedRules = [...updatedRules.filter(rule => rule.field !== "SEQUENCERRUN_ID"), newRule];
    }
    setRules(updatedRules);
    return updatedRules;
  };

  // Check pattern type and validate accordingly
  const isValidPattern = (pattern: string, patternType: string): boolean => {
    try {
      if (patternType === 'regex') {
        //case-insensitive
        new RegExp(pattern, 'i');
      } else if (patternType === 'wildcard') {
        // Check if '[DATA]' (case-insensitive) exists exactly once in the pattern
        const matchData = pattern.match(/\[(d|D)(a|A)(t|T)(a|A)\]/g) || [];
        if (matchData.length !== 1) throw new Error();
        let regexPattern = pattern.replace(/\*/g, '.*?').replace(/\[(d|D)(a|A)(t|T)(a|A)\]/, '(.+?)');

        new RegExp(regexPattern);
      } else {
        throw new Error();
      }
      return true;
    } catch (e) {
      //console.error(e)
      return false;
    }
  };

  const generatePreview = (pattern: string, patternType: string, data: string) => {
    let regex;
    if (patternType === 'regex' && isValidPattern(pattern, patternType)) {
      //case-insensitive
      regex = new RegExp(pattern, 'i');
    } else if (patternType === 'wildcard' && isValidPattern(pattern, patternType)) {
      let regexPattern = pattern.replace(/\*/g, '.*?').replace(/\[(d|D)(a|A)(t|T)(a|A)\]/, '(.+?)');
      regex = new RegExp(regexPattern);
    } else {
      return '';
    }
    const match = data.match(regex);
    return match && match[1] ? match[1] : '';
  };

  const handlePreview = (setOutput: (value: string) => void, pattern: string, patternType: string, data: string) => {
    setOutput(generatePreview(pattern, patternType, data));
  };

  const handleRadioChange = (
    value: string,
    setter: React.Dispatch<React.SetStateAction<string>>
  ) => {
    setter(value);
  };

  return <>
    {source === "sra" ? <>
      <div style={{ display: 'flex', flexDirection: "column", gap: '5px' }}>
        <table>
          <thead>
            <tr>
              <th>Key</th>
              <th>NCBI_ACCESSION</th>
              <th>SRR_ID</th>
            </tr>
          </thead>
          <tbody>
            {selectedFiles.map((selectedFile, i) => <tr key={i}>
              <td>
                <input className="bp4-input" style={selectedFile.name ? {} : { border: "solid 3px red" }} value={selectedFile.name} onChange={(e) => {
                  const value = e.target.value
                  const newSelectedFile = { ...selectedFile, name: value }
                  const newSelectedFiles = selectedFiles.map((v, i2) => i === i2 ? newSelectedFile : v)
                  setSelectedFiles(newSelectedFiles)
                  setRulesImporting(!newSelectedFiles.filter(v => !v.name).length)
                }}></input>
              </td>
              <td>{selectedFile.ncbiMetadata?.NCBI_ACCESSION}</td>
              <td>{selectedFile.ncbiMetadata?.SRR_ID}</td>
            </tr>)}
          </tbody>
        </table>


      </div>
    </>
      : <div style={{ display: 'flex', gap: '20px' }}>
        <Card interactive={false} elevation={Elevation.TWO}>
          <H5 style={{ marginBottom: "20px" }}>Key <span style={{ color: 'red' }}>*</span></H5>
          <RadioGroup label={<span>Pattern Type</span>} selectedValue={keyPatternType} onChange={(event) =>
            handleRadioChange(event.currentTarget.value, setKeyPatternType)
          }
          >
            <Radio label="Regular expression: match the expression and use the subexpression" value="regex" />
            <Radio label="Parse component: find the component '[DATA]', use '*' as wildcard" value="wildcard" />
          </RadioGroup>
          {keyPatternType ? <>
            <FormGroup label={<span>Pattern <span style={{ color: 'red' }}>*</span></span>} labelFor="key-pattern-input" helperText={`Example: ${keyPatternType === 'regex' ? defaultPattern : '[DATA]-*'}`}>
              <div style={{ display: "flex", gap: 5 }}>
                <div style={{ flex: 1 }}>
                  <Popover2
                    content={<div style={{ display: "flex", flexDirection: "column", gap: 5, padding: 5, maxHeight: 200, overflowY: "auto" }}>
                      <em>Select an import template..</em>
                      {user?.importTemplates?.filter(v => v.field === "Key").map((v, i) => <div key={i} style={{ display: "flex", gap: 5 }}>
                        <Card
                          className={Classes.POPOVER_DISMISS}
                          style={{ display: "flex", flex: 1, flexDirection: "column", gap: 5, padding: 5 }}
                          interactive
                          onClick={() => {
                            setKeyPattern(v.pattern)
                            setKeyPatternType(v.patternType)
                          }}
                        >
                          <strong>{v.name}</strong>
                          <em>{v.description}</em>
                          {v.defaultPattern ? <em>Default</em> : null}
                        </Card>
                        <Button aria-label="Delete Import Template" title="Delete Import Template" icon="trash" minimal onClick={() => deleteImportTemplate(v, auth?.user?.access_token ?? "")}></Button>
                      </div>)}
                      {!user?.importTemplates?.filter(v => v.field === "Key").length ? <em>No Import Templates saved for Key. Click the floppy-disc icon to save the above entered pattern as a template for future use.</em> : null}
                    </div>}
                    position="bottom"
                    minimal
                    matchTargetWidth
                    fill
                    autoFocus={false}
                  >
                    <InputGroup
                      id="key-pattern-input"
                      value={keyPattern} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const target = event.target as HTMLInputElement;
                        setKeyPattern(target.value);
                      }}
                    />
                  </Popover2>
                </div>
                <Popover2
                  isOpen={showKeyTemplatePopOver}
                  content={<ImportTemplateForm
                    pattern={keyPattern}
                    patternType={keyPatternType}
                    field="Key"
                    onSubmit={(importTemplate) => {
                      addImportTemplate(importTemplate, auth.user?.access_token ?? "")
                      setShowKeyTemplatePopOver(false)
                    }}
                    onCancel={() => setShowKeyTemplatePopOver(false)}
                  ></ImportTemplateForm>}
                  position="bottom"
                >
                  <Button minimal icon="floppy-disk" title="Save the current pattern as an Import Template." disabled={!keyPattern} onClick={() => setShowKeyTemplatePopOver(true)}></Button>
                </Popover2>
              </div>
            </FormGroup>
            <fieldset>
              <legend>Preview</legend>
              <FormGroup label="Filename" labelFor="key-data-input"
                helperText="This is the data that will be parsed using the pattern.">
                <div>{selectedFiles[0]?.name}</div>
                <Button onClick={() => handlePreview(setKeyOutput, keyPattern, keyPatternType,
                  selectedFiles[0]?.name)}>Preview...</Button>
              </FormGroup>
              <FormGroup label="Output" labelFor="key-output-input"
                helperText="This is the result of parsing the data using the pattern.">
                <InputGroup id="key-output-input" value={keyOutput} readOnly />
              </FormGroup>
            </fieldset>
          </> : null}
        </Card>
        {!basespaceProjectAsSequencerRunId && <Card interactive={false} elevation={Elevation.TWO}>
          <H5 style={{ marginBottom: "20px" }}>SEQUENCERRUN_ID</H5>

          <RadioGroup label="Pattern Type" selectedValue={sequenceRunIdPatternType} onChange={(event) =>
            handleRadioChange(event.currentTarget.value, setSequenceRunIdPatternType)
          }
          >
            <Radio label="Regular expression: match the expression and use the subexpression" value="regex" />
            <Radio label="Parse component: find the component '[DATA]', use '*' as wildcard" value="wildcard" />
          </RadioGroup>
          {sequenceRunIdPatternType ? <>
            <FormGroup label="Pattern" labelFor="sequence-runId-pattern-input" helperText={`Example: ${sequenceRunIdPatternType === 'regex' ? defaultPattern : '*-[DATA]_*'}`}>
              <div style={{ display: "flex", gap: 5 }}>
                <div style={{ flex: 1 }}>
                  <Popover2
                    content={<div style={{ display: "flex", flexDirection: "column", gap: 5, padding: 5, maxHeight: 200, overflowY: "auto" }}>
                      <em>Select an import template..</em>
                      {user?.importTemplates?.filter(v => v.field === "SEQUENCERRUN_ID").map((v, i) => <div key={i} style={{ display: "flex", gap: 5 }}>
                        <Card
                          className={Classes.POPOVER_DISMISS}
                          style={{ display: "flex", flex: 1, flexDirection: "column", gap: 5, padding: 5 }}
                          interactive
                          onClick={() => {
                            setSequenceRunIdPattern(v.pattern)
                            setSequenceRunIdPatternType(v.patternType)
                          }}
                        >
                          <strong>{v.name}</strong>
                          <em>{v.description}</em>
                          {v.defaultPattern ? <em>Default</em> : null}
                        </Card>
                        <Button aria-label="Delete Import Template" title="Delete Import Template" icon="trash" minimal onClick={() => deleteImportTemplate(v, auth?.user?.access_token ?? "")}></Button>
                      </div>)}
                      {!user?.importTemplates?.filter(v => v.field === "SEQUENCERRUN_ID").length ? <em>No Import Templates saved for SEQUENCERRUN_ID.  Click the floppy-disc icon to save the above entered pattern as a template for future use.</em> : null}
                    </div>}
                    position="bottom"
                    minimal
                    matchTargetWidth
                    fill
                    autoFocus={false}
                  >
                    <InputGroup
                      id="sequence-runId-pattern-input"
                      value={sequenceRunIdPattern} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const target = event.target as HTMLInputElement;
                        setSequenceRunIdPattern(target.value);
                      }}
                    />
                  </Popover2>
                </div>
                <Popover2
                  isOpen={showSequenceTemplatePopOver}
                  content={<ImportTemplateForm
                    pattern={sequenceRunIdPattern}
                    patternType={sequenceRunIdPatternType}
                    field="SEQUENCERRUN_ID"
                    onSubmit={(importTemplate) => {
                      addImportTemplate(importTemplate, auth.user?.access_token ?? "")
                      setShowSequenceTemplatePopOver(false)
                    }}
                    onCancel={() => setShowSequenceTemplatePopOver(false)}
                  ></ImportTemplateForm>}
                  position="bottom"
                >
                  <Button minimal icon="floppy-disk" title="Save the current pattern as an Import Template." disabled={!sequenceRunIdPattern} onClick={() => setShowSequenceTemplatePopOver(true)}></Button>
                </Popover2>
              </div>
            </FormGroup>
            <fieldset>
              <legend>Preview</legend>
              <FormGroup label="Data" labelFor="sequence-runId-data-input"
                helperText="This is the data that will be parsed using the pattern.">
                <div>{selectedFiles[0]?.name}</div>
                <Button onClick={() => handlePreview(setSequenceRunIdOutput, sequenceRunIdPattern,
                  sequenceRunIdPatternType, selectedFiles[0]?.name)}>Preview...</Button>
              </FormGroup>
              <FormGroup label="Output" labelFor="sequence-runId-output-input"
                helperText="This is the result of parsing the data using the pattern.">
                <InputGroup id="sequence-runId-output-input" value={sequenceRunIdOutput} readOnly />
              </FormGroup>
            </fieldset>
          </> : null}
        </Card>}
      </div>}
  </>
}