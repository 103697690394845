import { Button } from '@blueprintjs/core/lib/esm/components/button/buttons';
import { ButtonGroup } from '@blueprintjs/core/lib/esm/components/button/buttonGroup';
import { Checkbox } from '@blueprintjs/core/lib/esm/components/forms/controls';
import { Icon } from '@blueprintjs/core/lib/esm/components/icon/icon';
import type { Identifier, XYCoord } from 'dnd-core'
import type { FC } from 'react'
import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import "../../styles/view-sort.css"
import { ViewColumn } from '../../types/ViewTypes'

const ItemTypes = {
  CARD: 'card',
}

const style = {
  border: '1px dashed gray',
  padding: 2,
  marginBottom: '.5rem',
  backgroundColor: 'white',
  cursor: 'move'
}

export interface CardProps {
  id: any
  text: string
  columnName: string
  index: number
  moveCard: (dragIndex: number, hoverIndex: number) => void
  checkbox_OnChange: (v: ViewColumn) => void
  cardIsFirst: (index: number) => boolean
  cardIsLast: (index: number) => boolean
  required?: boolean
}

interface DragItem {
  index: number
  id: string
  type: string
}

export const Card: FC<CardProps> = ({ id, text, index, moveCard, checkbox_OnChange, cardIsFirst, cardIsLast, required, columnName }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      if (text === 'Key') return {}; // Disable dragging if text is 'Key'
      return { id, index }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1
  //drag(drop(ref))
  // Only enable drag if text is not 'Key'
  if (text !== 'Key') { 
    drag(drop(ref))
  }

  const onMoveButtonClick = (moveUp: boolean) => {
    moveCard(index, moveUp ? index - 1 : index + 1)
  }

  const buttonStyle = {
    minHeight: "initial",
    padding: 0
  }

  return (
    <div title="Drag and drop to reorder." className="view-sort-card" ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId}>
      <Icon icon="drag-handle-vertical"></Icon>
      {required 
        ? <>
            <Icon icon="lock" style={{color:"grey", padding: 6}} title="This field is required in views."></Icon> 
            <div>{text}</div>
          </>
        : <Checkbox checked={true} disabled={required} onChange={() => checkbox_OnChange({ path: id, name: columnName, title: text})} label={text} style={{ margin: "5px" }} />
      }
              
      <div style={{ flex: 1 }}>
      </div>
      <ButtonGroup style={{ minWidth: 20 }} vertical>
        <Button icon="caret-up" style={buttonStyle} disabled={cardIsFirst(index)} onClick={() => onMoveButtonClick(true)} />
        <Button icon="caret-down" style={buttonStyle} disabled={cardIsLast(index) || (text==='Key'? true : false)} onClick={() => onMoveButtonClick(false)} />
      </ButtonGroup>
    </div>
  )
}
