import { Button, Card, H5, Icon, Spinner } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2/lib/esm/popover2"
import { useState } from "react";
import { useAuth } from "react-oidc-context";
import { PipelineRunAPI, SampleAPI } from "../api/DataAPI";
import { SampleProcess, SampleProcessIssue, SampleProcesses } from "../types/SampleTypes";
import { Toaster } from "../utils/Toaster";
import { useSampleDialogStore } from "../stores/SampleDialogStore";
import { PipelineRun } from "../types/PipelineRunTypes";
import { usePipelineRunsDialogStore } from "../stores/PipelineRunsDialogStore";
import { getDateTimeString } from "../utils/getDateTimeString";
import { usePipelineRunsDialogFunctions } from "../hooks/usePipelineRunsDialogFunctions";
import { useOrganizationStore } from "../stores/OrganizationStore";

interface PipelineStatusIconProps {
  status: string,
  sampleId: string,
  pipelineRunId?: string
  ncbiPipeline?: boolean
}

export function PipelineStatusIcon({ status, sampleId, pipelineRunId, ncbiPipeline }: PipelineStatusIconProps) {

  const { openSampleDialog } = useSampleDialogStore()
  const [loading, setLoading] = useState(true)
  const [processes, setProcesses] = useState<SampleProcesses | undefined>()
  const [pipelineRun, setPipelineRun] = useState<PipelineRun | undefined>()
  const { showPipelineRunsDialog } = usePipelineRunsDialogStore()
  const { loadDialog } = usePipelineRunsDialogFunctions()
  const { organization } = useOrganizationStore()

  const auth = useAuth();
  if (!auth.user && !(process.env["REACT_APP_OVERRIDE_AUTH"] === "true"))
    throw new Error("No authenticated user found.")
  const sampleAPI = new SampleAPI(auth.user?.access_token ?? "")
  const pipelineRunsApi = new PipelineRunAPI(auth.user?.access_token ?? "")

  const onIconHover = async () => {
    if (["Failed", "Warning"].includes(status)) {
      if (!processes)
        try {
          const processes = ncbiPipeline
            ? await sampleAPI.getNCBIProcessesById(sampleId)
            : await sampleAPI.getProcessesById(sampleId)
          setProcesses(processes)
        }
        catch (e) {
          Toaster.show({ icon: "error", message: `There was an error loading processes and metrics for sample.`, intent: "danger" })
        }
    }
    if (["Running", "Success", "Error", "Cancelled"].includes(status)) {
      if (!pipelineRun && pipelineRunId)
        try {
          const pipelineRun = await pipelineRunsApi.get(pipelineRunId)
          setPipelineRun(pipelineRun)
        }
        catch (e) {
          Toaster.show({ icon: "error", message: `There was an error loading pipelinerun sample.`, intent: "danger" })
        }
    }
    setLoading(false)
  }

  const getIcon = () => {
    let icon
    switch (status) {
      case "Success": icon = <Icon icon="tick-circle" aria-label="Sample Passed all QC checks." intent="success" />
        break;
      case "Failed": icon = <Icon icon="error" aria-label="Please check QC results." intent="danger" />
        break;
      case "Warning": icon = <Icon icon="warning-sign" aria-label="Sample failed one or more QC checks." intent="warning" />
        break;
      case "Cancelled": icon = <Icon icon="disable" aria-label="Pipeline Cancelled" title="Pipeline Cancelled" intent="danger" />
        break;
      case "Error": icon = <Icon icon="cross-circle" aria-label="Pipeline Errored." title="Pipeline Errored" intent="danger" />
        break;
      case "Running": icon = <Spinner size={15} aria-label="Pipeline Running" title="Pipeline Running"></Spinner>
        break;
      default: icon = null
    }
    return icon
  }
  const getStatusIcon = (status: "PASS" | "WARN" | "FAIL") => {
    switch (status) {
      case "PASS": return <Icon icon="tick" intent="success"></Icon>
      case "WARN": return <Icon icon="warning-sign" intent="warning"></Icon>
      case "FAIL": return <Icon icon="error" aria-label="Please check QC results." intent="danger" />
    }
  }

  const getPopoverContent = () => {
    if (loading)
      return <Spinner></Spinner>
    if (["Failed", "Warning"].includes(status))
      return organization?.organizationName === "CaliciNet" ? processesPopoverContentCaliciNet() : processesPopoverContent()
    if (["Running", "Success", "Error", "Cancelled"].includes(status))
      return pipelineRunId ? pipelinePopoverContent() : status === "Success" ? "Completed Successfully" : `Pipeline ${status}`
  }

  const processesPopoverContent = () => <div style={{ display: "flex", flexDirection: "column", gap: 5, padding: 5, maxWidth: "30vw" }}>
    {processes && Object.keys(processes)
      .filter(processName => processes[processName]?.issues && processes[processName]?.issues.length > 0)
      .map(processName =>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {processes[processName]?.issues && Array.isArray(processes[processName]?.issues) && (processes[processName]?.issues as SampleProcessIssue[] | string[]).map((issue, i) =>
            typeof (issue) === "string" ?
              <Card>
                {issue}
              </Card>
              : <Card key={i} style={{ display: "flex", flexDirection: "row", padding: 5 }} interactive onClick={() => openSampleDialog(sampleId, false, auth.user?.access_token ?? "", 1, processName)}>
                <div style={{ paddingRight: 5 }}>{getStatusIcon(issue.status)}</div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div><strong>{issue.status} - {processName}:</strong> {issue.description}</div>
                  <ul style={{ margin: 0 }}>
                    {issue.rules && Array.isArray(issue.rules) && issue.rules.map((rule, i) => <li key={i}>
                      {rule.key}: {processes && (processes[processName] as SampleProcess).metrics[rule.key]} {rule.operator} {Array.isArray(rule.value) ? rule.value[0] : rule.value} {Array.isArray(rule.value) && rule.value[1] && `and ${rule.value[1]}`}
                    </li>)}
                  </ul>
                </div>
              </Card>
          )}
        </div>
      )}
  </div>

  const processesPopoverContentCaliciNet = () => <div style={{ display: "flex", flexDirection: "column", gap: 5, padding: 5, maxWidth: "30vw" }}>
    {processes &&
      <div style={{ display: "flex", flexDirection: "column" }}>
        {Object.entries(processes["Issues"]).map(([k, v]) =>
        <Card key={k} style={{ display: "flex", flexDirection: "row", padding: 5 }} interactive onClick={() => openSampleDialog(sampleId, false, auth.user?.access_token ?? "", 1, "Issues")}>
                <div style={{ paddingRight: 5 }}>{getStatusIcon(v.status)}</div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div><strong>{v.status} - QC-Metrics:</strong> {v.description}</div>
            <ul style={{ margin: 0 }}>
              {v.rules && Array.isArray(v.rules) && v.rules.map((rule: any, i: any) => <li key={i}>
                {rule.key}:  {rule.operator} {Array.isArray(rule.value) ? rule.value[0] : rule.value} {Array.isArray(rule.value) && rule.value[1] && `and ${rule.value[1]}`}
              </li>)}
            </ul>
          </div>
          </Card>
        )
        }
      </div>
    }

  </div>
  const pipelinePopoverContent = () => <Card style={{ display: "flex", flexDirection: "column", padding: 5 }}>

    <div>
      <H5>Pipeline {pipelineRun?.status}</H5>
      <div style={{ display: "flex", gap: 5, alignItems: "center" }}>
        {pipelineRun?.status && ["Started", "Initiating", "Pending", "Restarting"].includes(pipelineRun?.status) ?
          <Spinner size={30}></Spinner>
          : null}
        {pipelineRun?.status && ["Error"].includes(pipelineRun?.status) ?
          <Icon icon="error" intent="danger"></Icon>
          : null}
      </div>
    </div>
    <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
      <div>Pipeline Run Started: {getDateTimeString(pipelineRun?.createdAt)}</div>
      {pipelineRun && ["Started", "Initiating", "Pending", "Restarting"].includes(pipelineRun.status)
        ? <div>Pipeline Run Updated: {getDateTimeString(pipelineRun?.updatedAt)}</div>
        : <div>Pipeline Run Completed: {getDateTimeString(pipelineRun?.updatedAt)}</div>
      }
    </div>
    {pipelineRun && !showPipelineRunsDialog && <Button icon="flows" onClick={() => {
      loadDialog(pipelineRun.id)
    }}>View Pipeline Run</Button>}
  </Card>

  return <Popover2
    interactionKind="hover"
    onOpened={() => onIconHover()}
    hoverOpenDelay={300}
    hoverCloseDelay={100}
    content={
      <div style={{ display: "flex", flexDirection: "column", gap: 5, padding: 5 }}>
        {getPopoverContent()}
      </div>
    }>
    {getIcon() ?? <div></div>}
  </Popover2>
}