// to safely extract nested child elements
export function getNestedChild(element: any, ...indices: number[]): any {
    for (const index of indices) {
        if (element && element.children && element.children[index]) {
            element = element.children[index];
        } else {
            return null;
        }
    }
    return element;
}

export function DOMcontrol_SampleDetail(mutationsList: MutationRecord[], selectState: { allSelectClicked: boolean | null }, updateAllSelectedCallback: (value: boolean) => void) {
    for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {

          let msDetailsListHeaderWrapper = document.getElementsByClassName('ms-DetailsList-headerWrapper');
          if (msDetailsListHeaderWrapper && msDetailsListHeaderWrapper[0]
          ) {
            let head_element = getNestedChild(msDetailsListHeaderWrapper[0], 0, 0, 0, 0) as HTMLElement;
            let head_element1 = head_element?.children?.[0] as HTMLElement;
            if (head_element1) {
              head_element1.style.zIndex = '1';
              head_element1.style.position = 'sticky';
              head_element1.style.left = '0px';
              head_element1.style.backgroundColor = 'white';
              head_element1.style.padding = '5px';
              head_element1.style.paddingBottom = '10px';
              head_element1.onclick = () => {
                const isSelected = head_element1.children[0] && head_element1.children[0].children[0] && head_element1.children[0].children[0].ariaChecked === 'false';
                updateAllSelectedCallback(isSelected);

                let msListPage = document.getElementsByClassName('ms-List-page');
                if (msListPage && msListPage.length > 0) {
                  for (let i = 0; i < msListPage.length; i++) {
                    let listElement = msListPage[i];
                    if (listElement) {
                      let count = listElement.childElementCount;
                      for (let j = 0; j < count; j++) {
                        if (listElement.children[j] &&
                            listElement.children[j].children[0] &&
                            listElement.children[j].children[0].children[1]) {
                          let element2 = listElement.children[j].children[0].children[1].children[0] as HTMLElement;
                          let element3 = listElement.children[j].children[0].children[1].children[1] as HTMLElement;
                          if(head_element1.children[0] && head_element1.children[0].children[0] && head_element1.children[0].children[0].ariaChecked === 'true'){
                             element2.style.backgroundColor = 'white';
                             element3.style.backgroundColor = 'white';
                          } else {
                             element2.style.backgroundColor = 'rgb(237, 235, 233)';
                             element3.style.backgroundColor = 'rgb(237, 235, 233)';
                          }
                        }
                      }
                    }
                  }
                }
              };

              // Hover event to show the popover.
              head_element1.onmouseover = (event) => {
                const popover = document.getElementById('popover');
    
                // Position the popover near the hovered element.
                if(popover){
                  const rect = head_element1.getBoundingClientRect();
                  popover.style.position = 'fixed';
                  popover.style.left = rect.left + "px";
                  popover.style.top = rect.bottom + "px";
      
                  popover.style.display = 'block';
                }
              };
  
              // Event to hide the popover when not hovering.
              head_element1.onmouseleave = () => {
                const popover = document.getElementById('popover');
                if(popover){
                  popover.style.display = 'none';
                }
              };
            }
            let head_element2 = head_element?.children?.[1] as HTMLElement;
            if (head_element2) {
              head_element2.style.zIndex = '1';
              head_element2.style.position = 'sticky';
              head_element2.style.left = '58px';
              head_element2.style.backgroundColor = 'white';
            }
            let head_element3 = head_element.children[2] as HTMLElement;
            if (head_element3) {
              head_element3.style.zIndex = '1';
              head_element3.style.position = 'sticky';
              head_element3.style.left = '94px';
              head_element3.style.backgroundColor = 'white';
            }
          }

          let msListPage = document.getElementsByClassName('ms-List-page');
          if (msListPage && msListPage.length > 0) {
            for (let i = 0; i < msListPage.length; i++) {
              let listElement = msListPage[i];
              if (listElement) {
                let count = listElement.childElementCount;
                for (let j = 0; j < count; j++) {
                  if (listElement.children[j] &&
                    listElement.children[j].children[0] &&
                    listElement.children[j].children[0].children[0]) {
                    let element = listElement.children[j].children[0].children[0] as HTMLElement;
                    if (element) {
                      element.style.zIndex = '1';
                      element.style.position = 'sticky';
                      element.style.left = '0px';
                      element.style.backgroundColor = 'rgb(243,242,241)';
                      element.style.padding = '5px';
                    }
                    if(listElement.children[j].children[0].children[1]){
                      let elementInTable = listElement.children[j].children[0].children[1] as HTMLElement;
                      let element2 = elementInTable.children[0] as HTMLElement;
                      let element3 = elementInTable.children[1] as HTMLElement;
                      if (element2) {
                        element2.style.zIndex = '1';
                        element2.style.position = 'sticky';
                        element2.style.left = '58px';
                        if(listElement.children[j].children[0].children[0].children[0].ariaChecked === 'true'){
                          element2.style.backgroundColor = 'rgb(237, 235, 233)';  
                        } else {
                          element2.style.backgroundColor = 'white';
                        }
                      }
                      if (element3) {
                        element3.style.zIndex = '1';
                        element3.style.position = 'sticky';
                        element3.style.left = '94px';
                        if(listElement.children[j].children[0].children[0].children[0].ariaChecked === 'true'){
                          element3.style.backgroundColor = 'rgb(237, 235, 233)';  
                        } else {
                          element3.style.backgroundColor = 'white';
                        }
                      }
                      let elementInRow = listElement.children[j].children[0] as HTMLElement;
                      elementInRow.onmouseover = () => {
                        if(listElement.children[j].children[0].children[0].children[0].ariaChecked === 'true'){
                          element2.style.backgroundColor = 'rgb(225, 223, 221)';
                          element3.style.backgroundColor = 'rgb(225, 223, 221)';
                        } else {
                          element2.style.backgroundColor = 'rgb(243,242,241)';
                          element3.style.backgroundColor = 'rgb(243,242,241)';
                        }  
                      };
                      elementInRow.onmouseleave = () => { 
                        if(listElement.children[j].children[0].children[0].children[0].ariaChecked === 'true'){
                          element2.style.backgroundColor = 'rgb(237, 235, 233)';
                          element3.style.backgroundColor = 'rgb(237, 235, 233)';
                        } else {
                          element2.style.backgroundColor = 'white';
                          element3.style.backgroundColor = 'white';
                        } 
                        //trigger scroll event
                        let triggeredevent2 = new Event('scroll', {
                          'bubbles': true,
                          'cancelable': true
                        });
                        elementInRow.dispatchEvent(triggeredevent2);   
                      };

                        let elementInRow1 = elementInRow.children[0] as HTMLElement;
                        let elementInRow2 = elementInRow.children[1] as HTMLElement;
                        elementInRow1.onclick = () => {
                          if(listElement.children[j].children[0].children[0].children[0].ariaChecked === 'true'){
                            element2.style.backgroundColor = 'white';
                            element3.style.backgroundColor = 'white';

                            //trigger mouse over event
                            let triggeredevent = new MouseEvent('mouseover', {
                              'view': window,
                              'bubbles': true,
                              'cancelable': true
                            });
                            elementInRow.dispatchEvent(triggeredevent);
                          } else {
                         
                              element2.style.backgroundColor = 'rgb(225, 223, 221)';
                              element3.style.backgroundColor = 'rgb(225, 223, 221)';
            
                          }
                        };

                        elementInRow2.onclick = () => {
                          if(listElement.children[j].children[0].children[0].children[0].ariaChecked === 'true'){

                              for (let i = 0; i < msListPage.length; i++) {
                                let listElement2 = msListPage[i];
                                if (listElement2) {
                                  let count2 = listElement2.childElementCount;
                                  for (let k = 0; k < count2; k++) {
                                    if (
                                        listElement2.children[k] &&
                                        listElement2.children[k].children[0] &&
                                        listElement2.children[k].children[0].children[1]) {
                                      let element2_2 = listElement2.children[k].children[0].children[1].children[0] as HTMLElement;
                                      let element3_2 = listElement2.children[k].children[0].children[1].children[1] as HTMLElement;
                                      element2_2.style.backgroundColor = 'white';
                                      element3_2.style.backgroundColor = 'white';
                                      
                                    }
                                  }
                                }
                              }

                              //trigger mouse over event
                              let triggeredevent = new MouseEvent('mouseover', {
                                'view': window,
                                'bubbles': true,
                                'cancelable': true
                              });
                              elementInRow.dispatchEvent(triggeredevent);                       
                          } else {
                              element2.style.backgroundColor = 'rgb(225, 223, 221)';
                              element3.style.backgroundColor = 'rgb(225, 223, 221)';
                          }
                          
                        };

                    }    
                  }
                }
              }
            }
          }
        }
      }
}

export function DOMcontrol_ComparisonSampleDetail(eventChecker:boolean) {
        let dataList = document.getElementsByClassName('dataList');
        let msDetailsListHeaderWrapper = null as any;
  
        if(dataList && dataList[0] && getNestedChild(dataList[0], 0, 1, 0, 0, 0)){
          msDetailsListHeaderWrapper = getNestedChild(dataList[0], 0, 1, 0, 0, 0).children;
        }
        
        if(msDetailsListHeaderWrapper && msDetailsListHeaderWrapper[0]
          ){
            let head_element = getNestedChild(msDetailsListHeaderWrapper[0], 0, 0, 0, 0) as HTMLElement;
            let head_element1 = head_element?.children?.[0] as HTMLElement;
            if (head_element1) {
              head_element1.style.zIndex = '1';
              head_element1.style.position = 'sticky';
              head_element1.style.left = '0px';
              head_element1.style.backgroundColor = 'white';
              head_element1.style.padding = '5px';
              head_element1.style.paddingBottom = '10px';
  
              head_element1.onclick = () => {
                let msListPage = null as any;
                if(getNestedChild(dataList[0], 0, 1, 0, 0, 0, 1, 0, 0, 0, 0)){
                  msListPage = getNestedChild(dataList[0], 0, 1, 0, 0, 0, 1, 0, 0, 0, 0).children;
                }
                if (msListPage && msListPage.length > 0) {
                  for (let i = 0; i < msListPage.length; i++) {
                    let listElement = msListPage[i];
                    if (listElement) {
                      let count = listElement.childElementCount;
                      for (let j = 0; j < count; j++) {
                        if (listElement.children[j] &&
                            listElement.children[j].children[0] &&
                            listElement.children[j].children[0].children[1]) {
                          let element2 = listElement.children[j].children[0].children[1].children[0] as HTMLElement;
                          if(head_element1.children[0] && head_element1.children[0].children[0] && head_element1.children[0].children[0].ariaChecked === 'true'){
                             element2.style.backgroundColor = 'white';
                          } else {
                             element2.style.backgroundColor = 'rgb(237, 235, 233)';
                          }
                        }
                      }
                    }
                  }
                }
              };

              // Hover event to show the popover.
              head_element1.onmouseover = (event) => {
                const popover = document.getElementById('popover');
    
                // Position the popover near the hovered element.
                if(popover){
                  const rect = head_element1.getBoundingClientRect();
                  popover.style.position = 'fixed';
                  popover.style.left = rect.left + "px";
                  popover.style.top = rect.bottom + "px";
      
                  popover.style.display = 'block';
                }
              };
  
              // Event to hide the popover when not hovering.
              head_element1.onmouseleave = () => {
                const popover = document.getElementById('popover');
                if(popover){
                  popover.style.display = 'none';
                }
              };
            }
            let head_element2 = head_element?.children?.[1] as HTMLElement;
            if (head_element2) {
              head_element2.style.zIndex = '1';
              head_element2.style.position = 'sticky';
              head_element2.style.left = '58px';
              head_element2.style.backgroundColor = 'white';
            }
        }
  
        let msListPage = null as any;    
        if(dataList && dataList[0] && getNestedChild(dataList[0], 0, 1, 0, 0, 0, 1, 0, 0, 0, 0)){
          msListPage = getNestedChild(dataList[0], 0, 1, 0, 0, 0, 1, 0, 0, 0, 0).children;
        }
        if(msListPage && msListPage.length > 0){
          for (let i = 0; i < msListPage.length; i++) {
            let listElement = msListPage[i];
            if(listElement){
              let count = listElement.childElementCount;
              for (let j = 0; j < count; j++) {
                if (listElement.children[j] && 
                  listElement.children[j].children[0] ) {
                    let element = listElement.children[j].children[0].children[0] as HTMLElement;
                    if (element) {
                      element.style.zIndex = '1';
                      element.style.position = 'sticky';
                      element.style.left = '0px';
                      element.style.backgroundColor = 'rgb(243,242,241)';
                      element.style.padding = '5px';
                    }
                    if(listElement.children[j].children[0].children[1]){
                      let elementInTable = listElement.children[j].children[0].children[1] as HTMLElement;
                      let element2 = elementInTable.children[0] as HTMLElement;
                      if (element2) {
                        element2.style.zIndex = '1';
                        element2.style.position = 'sticky';
                        element2.style.left = '58px';
                        if(listElement.children[j].children[0].children[0].children[0].ariaChecked === 'true'){    
                        } else {
                          if(eventChecker === false){
                            element2.style.backgroundColor = 'white';
                          }
                          //eventChecker = true;  
                        }
                      }
                      let elementInRow = listElement.children[j].children[0] as HTMLElement;
                      elementInRow.onmouseover = () => {
                        if(listElement.children[j].children[0].children[0].children[0].ariaChecked === 'true'){
                          if (element2) {
                            element2.style.backgroundColor = 'rgb(225, 223, 221)';
                          }
                        } else {
                          if (element2) {
                            element2.style.backgroundColor = 'rgb(243,242,241)';
                          }
                        }
                        //hoveredIndex = i*10+j;
                        //callback(hoveredIndex); 
                      };
                      elementInRow.onmouseleave = () => { 
                        if(listElement.children[j].children[0].children[0].children[0].ariaChecked === 'true'){
                          if (element2) {
                            element2.style.backgroundColor = 'rgb(237, 235, 233)';
                          }
                        } else {
                          if (element2) {
                            element2.style.backgroundColor = 'white';
                          }
                        } 
                        //trigger scroll event
                        let triggeredevent2 = new Event('scroll', {
                          'bubbles': true,
                          'cancelable': true
                        });
                        elementInRow.dispatchEvent(triggeredevent2);
                      };
  
                        let elementInRow1 = elementInRow.children[0] as HTMLElement;
                        let elementInRow2 = elementInRow.children[1] as HTMLElement;
                        elementInRow1.onclick = () => {
                          if(listElement.children[j].children[0].children[0].children[0].ariaChecked === 'true'){
  
                            if (element2) {
                              element2.style.backgroundColor = 'white';
                            }
                            //trigger mouse over event
                            let triggeredevent = new MouseEvent('mouseover', {
                              'view': window,
                              'bubbles': true,
                              'cancelable': true
                            });
                            elementInRow.dispatchEvent(triggeredevent);
                            
                          } else {    
                            if (element2) {  
                              element2.style.backgroundColor = 'rgb(225, 223, 221)';
                            }
                          }
                        };
  
                        elementInRow2.onclick = () => {
                          if(listElement.children[j].children[0].children[0].children[0].ariaChecked === 'true'){
                            for (let i = 0; i < msListPage.length; i++) {
                              let listElement2 = msListPage[i];
                              if (listElement2) {
                                let count2 = listElement2.childElementCount;
                                for (let k = 0; k < count2; k++) {
                                    if (
                                        listElement2.children[k] &&
                                        listElement2.children[k].children[0] &&
                                        listElement2.children[k].children[0].children[1]) {
                                      let element2_2 = listElement2.children[k].children[0].children[1].children[0] as HTMLElement;
                                      if(element2_2){
                                        element2_2.style.backgroundColor = 'white';
                                      }
                                    }
                                }
                              }
                            }
  
                            //trigger mouse over event
                            let triggeredevent = new MouseEvent('mouseover', {
                              'view': window,
                              'bubbles': true,
                              'cancelable': true
                            });
                            elementInRow.dispatchEvent(triggeredevent);    
                          } else {
                            if(element2){
                              element2.style.backgroundColor = 'rgb(225, 223, 221)';
                            }
                          }
                        };
  
                    }    
                }  
              }
            }
          }
        }
        return eventChecker;
}

export function addCSSRule(rule: string): HTMLStyleElement {
  const style = document.createElement('style');
  style.type = 'text/css';
  style.appendChild(document.createTextNode(rule));
  document.head.appendChild(style);
  return style;
}

export function removeCSSRule(styleElement: HTMLStyleElement): void {
  if (styleElement && styleElement.parentNode) {
    styleElement.parentNode.removeChild(styleElement);
  }
}