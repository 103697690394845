export function downloadCSV(sampleData, dataPath, csv, fileName, skipHeader = false) {
    let csvdata = '';

    if (!skipHeader) {
        csvdata = String(csv) + '\n'; // header
    }

    var rowData = "";
    for (let i = 0; i < sampleData.length; i++) {
        // var v = sampleData[i][1];
        // var vFlat = flattenObject(v);
        var vFlat = sampleData[i]
        for (let i = 0; i < dataPath.length; i++) {
            let value = (typeof (vFlat[dataPath[i]]) === 'undefined' || vFlat[dataPath[i]] === null) ? "" : `"${vFlat[dataPath[i]].toString().replace(/"/g, '""')}"`;
            rowData += value;
            //don't add ',' at the end of value for the end index of this loop.
            if (i !== dataPath.length - 1) {
                rowData += ",";
            }
        }
        csvdata = csvdata + rowData;
        csvdata += "\n";
        rowData = "";
    }

    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvdata);
    hiddenElement.target = '_blank';

    //provide the name for the CSV file to be downloaded  
    hiddenElement.download = fileName;
    //hiddenElement.click();
    const clickEvt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
    });

    hiddenElement.dispatchEvent(clickEvt);
    hiddenElement.remove();
    clickEvt.preventDefault();

};