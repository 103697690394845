import { Button } from "@blueprintjs/core/lib/esm/components/button/buttons"
import { FormGroup } from "@blueprintjs/core/lib/esm/components/forms/formGroup"
import { InputGroup } from "@blueprintjs/core/lib/esm/components/forms/inputGroup"
import { TextArea } from "@blueprintjs/core/lib/esm/components/forms/textArea"
import { ImportTemplate } from "../types/UserTypes";
import { useController, useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { H5 } from "@blueprintjs/core/lib/esm/components/html/html";
import { Checkbox } from "@blueprintjs/core/lib/esm/components/forms/controls";

const yupSchema = yup.object().shape({
  name: yup.string().required(`Name is required.`),
}).required()

interface ImportTemplateFormProps {
  pattern: string
  patternType: string
  field: string
  onCancel: () => void
  onSubmit: (importTemplate: ImportTemplate) => void
}

export default function ImportTemplateForm(props: ImportTemplateFormProps) {
  const form = useForm<ImportTemplate>({
    mode: "onBlur",
    resolver: yupResolver(yupSchema),
    defaultValues: {
      name: "",
      description: "",
      field: props.field,
      pattern: props.pattern,
      patternType: props.patternType,
      defaultPattern: false
    }
  });

  const { control, handleSubmit, getValues, formState: { errors, } } = form;

  const onCancel_Click = () => {
    props.onCancel()
  }

  const onSubmit = () => {
    props.onSubmit(getValues())
  }

  const nameField = useController({name: "name", control})
  const descriptionField = useController({name: "description", control})
  const defaultPatternField = useController({name: "defaultPattern", control})

  return <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", gap: 5, padding: 5 }}>
    <H5>Save Import Template</H5>
    <FormGroup
      label={<span>Template Name <span style={{ color: 'red' }}>*</span></span>}      
    >
      <InputGroup intent={errors.name ? "danger" : "none"} onChange={nameField.field.onChange} value={nameField.field.value}></InputGroup>
    </FormGroup>
    <FormGroup label="Template Description">
      <TextArea  onChange={descriptionField.field.onChange} value={descriptionField.field.value}></TextArea>
    </FormGroup>
    <Checkbox
      label="Default Template"
      onChange={defaultPatternField.field.onChange}
      checked={defaultPatternField.field.value}>
    </Checkbox>
    <div style={{ display: "flex", gap: 5, justifyContent: "flex-end" }}>
      <Button intent="primary" type='submit'>Save</Button>
      <Button onClick={() => onCancel_Click()}>Cancel</Button>
    </div>    
  </form>
}