import { Drawer } from "@blueprintjs/core/lib/esm/components/drawer/drawer";
import { Position } from "@blueprintjs/core/lib/esm/common/position";
import { DRAWER_BODY } from "@blueprintjs/core/lib/esm/common/classes";
import { Spinner } from "@blueprintjs/core/lib/esm/components/spinner/spinner";
import { useEffect } from "react";
import { usePipelineRunStore } from "../stores/PipelineRunStore";
import { useAppStore } from "../stores/AppStore";
import { useAuth } from "react-oidc-context";
import { Button } from "@blueprintjs/core/lib/esm/components/button/buttons";
import { Popover2 } from "@blueprintjs/popover2";
import { Menu, MenuItem } from "@blueprintjs/core";
import { usePipelineRunsDrawerStore } from "../stores/PipelineRunsDrawerStore";
import { usePipelineRunsDrawersFunctions } from "../hooks/usePipelineRunsDrawerFunction";
import { usePipelinesStore } from "../stores/PipelinesStore";
import { usePipelineRunsDialogFunctions } from "../hooks/usePipelineRunsDialogFunctions";

export function PipelineRunsDrawer() {
  const { isSortAsc, setIsSortAsc, showPipelineRunsDrawer, loading, setStatusFilter, setPipelineNameFilter } = usePipelineRunsDrawerStore()
  const { setShowPipelineRunsDrawer } = useAppStore()
  const { PipelineRuns } = usePipelineRunStore()
  const { pipelines } = usePipelinesStore();
  const { loadPipelineRuns, sortFilterPipelineRuns } = usePipelineRunsDrawersFunctions()
  const { loadDialog } = usePipelineRunsDialogFunctions()
  const auth = useAuth();
  if (!auth.user && !(process.env["REACT_APP_OVERRIDE_AUTH"] === "true"))
    throw new Error("No authenticated user found.")

  useEffect(() => {
    if (showPipelineRunsDrawer) {
        loadPipelineRuns()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPipelineRunsDrawer])

  return <>
    {showPipelineRunsDrawer ? <Drawer
      isOpen={showPipelineRunsDrawer}
      onClose={() => setShowPipelineRunsDrawer(false)}
      icon="list-columns"
      title="PipelineRuns"
      position={Position.RIGHT}
      size={750}
    >
      <div className={DRAWER_BODY}>
        {loading ?
          <Spinner></Spinner>
          : <table className="bp4-html-table bp4-interactive" style={{ width: 600 }}>
            <thead>
              <tr>
                <th>
                  <Popover2
                    content={<Menu>
                      {[...new Set(PipelineRuns.map(v => v.pipelineId))].map(pipelineId => <MenuItem text={pipelines?.find(pipeline => pipeline.id === pipelineId)?.pipelineName} onClick={() => setPipelineNameFilter(pipelineId)} />) }
                      <MenuItem icon="filter-remove" text="Clear Filter" onClick={() => setPipelineNameFilter(undefined)} />
                    </Menu>}
                  >
                    <Button icon="filter" minimal>Pipeline</Button>
                  </Popover2>

                </th>
                <th>
                  <Button icon={isSortAsc ? "sort-asc" : "sort-desc"} onClick={() => setIsSortAsc(!isSortAsc)} minimal>Run DateTime</Button>
                </th>
                <th>
                  <Popover2
                    content={<Menu>
                      <MenuItem text="Completed" onClick={() => setStatusFilter("Completed")} />
                      <MenuItem text="Error" onClick={() => setStatusFilter("Error")} />
                      <MenuItem text="Initiated" onClick={() => setStatusFilter("Initiated")} />
                      <MenuItem text="Pending" onClick={() => setStatusFilter("Pending")} />
                      <MenuItem text="Running" onClick={() => setStatusFilter("Running")} />
                      <MenuItem text="Cancelled" onClick={() => setStatusFilter("Cancelled")} />
                      <MenuItem icon="filter-remove" text="Clear Filter" onClick={() => setStatusFilter(undefined)} />
                    </Menu>}
                  >
                    <Button icon="filter" minimal>Status</Button>
                  </Popover2>

                </th>
                <th>Sample Count</th>
                <th>Created By</th>
              </tr>
            </thead>
            <tbody>
              {PipelineRuns ? sortFilterPipelineRuns()
                .map(v => <tr key={v.id} onClick={() => {
                  setShowPipelineRunsDrawer(false)
                  loadDialog(v.id)
                }}>
                  <td>{pipelines?.find(pipeline => pipeline.id === v.pipelineId)?.pipelineName}</td>
                  <td>{(new Date(v.runDateTime)).toLocaleDateString()} {(new Date(v.runDateTime)).toLocaleTimeString()}</td>
                  <td>{v.status}</td>
                  <td>{v.sampleKeys?.length}</td>
                  <td>{v.createdBy}</td>
                </tr>)
                : <tr><td colSpan={3}>loading pipelineruns...</td></tr>}
              {PipelineRuns && sortFilterPipelineRuns().length ? null : <td colSpan={4}>No Pipeline Runs</td>}
            </tbody>
          </table>}

      </div>
    </Drawer> : null}
  </>
}