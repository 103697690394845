import { create } from "zustand";
import { ComparedSample } from "../types/ComparisonTypes";


interface ComparisonFormState {
  alert: boolean
  dataLoading: boolean
  errorMessage: string
  loading: boolean
  showComparisonForm: boolean
  updatedComparisonSamples: ComparedSample[]
  updatedGroups: Map<string,string>
  setAlert: (alert: boolean) => void
  setDataLoading: (dataLoading: boolean) => void
  setErrorMessage: (errorMessage: string) => void
  setLoading: (loading: boolean) => void
  setUpdatedComparisonSamples: ( updatedComparisonSamples: ComparedSample[]) => void
  setUpdatedGroups: (updatedGroups: Map<string,string>) => void
}

export const useComparisonFormStore = create<ComparisonFormState>((set) => ({
  alert: false,
  dataLoading: false,
  errorMessage: "",
  loading: false,
  showComparisonForm: false,
  updatedComparisonSamples: [],
  updatedGroups: new Map<string,string>(),
  setAlert: (alert: boolean) => set({ alert }),
  setDataLoading: (dataLoading: boolean) => set({ dataLoading }),
  setErrorMessage: (errorMessage: string) => set({ errorMessage }),
  setLoading: (loading: boolean) => set({ loading }),
  setUpdatedComparisonSamples: ( updatedComparisonSamples: ComparedSample[]) => set({ updatedComparisonSamples }),
  setUpdatedGroups: (updatedGroups: Map<string,string>) => set({ updatedGroups })
}))