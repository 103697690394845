import { Button, ButtonGroup, Dialog, Text, Spinner, Callout } from "@blueprintjs/core";
import { usePipelineTemplatesDialogStore } from "../stores/PipelineTemplatesDialogStore";
import { useEffect, useState } from "react";
import PipelineTemplateForm from "./PipelineTemplateForm";
import { useAuth } from "react-oidc-context";
import { LabAPI } from "../api/DataAPI";
import { FieldValues } from "react-hook-form";
import { Toaster } from "../utils/Toaster";
import { useUserStore } from "../stores/UserStore";
import { useOrganismStore } from "../stores/OrganismStore";
import { useOrganizationStore } from "../stores/OrganizationStore";
import { useLabsStore } from "../stores/LabsStore";

export default function PipelineTemplatesDialog() {
  const { isPipelineTemplateDialogOpen, setIsPipelineTemplateDialogOpen, activeTemplate, setActiveTemplate, deletingIndex, setDeletingIndex, lab, setLab } = usePipelineTemplatesDialogStore()
  const { user } = useUserStore()
  const { organism } = useOrganismStore()
  const { organization } = useOrganizationStore()
  const { setUserLab} = useLabsStore()
  const [error, setError] = useState('')
  const auth = useAuth()
  const labAPI = new LabAPI(auth.user?.access_token ?? "")

  const getLab = async () => {
    const labs = await labAPI.getAll()
    if (labs.length < 1) {
      setError("No lab found")
    }
    const lab = labs.find(v => user?.labId == v.labId);
    if (lab){
      setLab(lab)
      setUserLab(lab)
    }
    else
      setError("No lab found")
  }

  useEffect(() => {
    getLab()
  }, [])

  const handleClose = () => {
    setActiveTemplate(undefined)
    setIsPipelineTemplateDialogOpen(false)
  }

  const handleTemplateSubmit = async (data: FieldValues) => {
    if (!lab) {
      throw new Error("No lab set")
    }
    if (!activeTemplate) {
      await labAPI.createPipelineTemplate({
        name: data.name,
        pipelineId: data.pipelineId,
        fields: data.fields,
        organism: organism?.name ?? "",
        organization: organization?.organizationName ?? ""
      }, lab.labId)
      Toaster.show({ icon: "tick", message: "Template created successfully.", timeout: 5000, intent: "success" })
    } else {
      await labAPI.updatePipelineTemplate({
        name: data.name, 
        pipelineId: data.pipelineId,
        fields: data.fields, 
        organism: organism?.name ?? "",
        organization: organization?.organizationName ?? ""
      }, lab.labId, activeTemplate.name)
      Toaster.show({ icon: "tick", message: "Template updated successfully.", timeout: 5000, intent: "success" })
    }
    await updateLab(lab.id)
  }

  const updateLab = async (labId: string) => {
    const updatedLab = await labAPI.get(labId)
    setLab(updatedLab)
    setUserLab(updatedLab)
    if (!activeTemplate) {
      setActiveTemplate(updatedLab.pipelineTemplates[updatedLab.pipelineTemplates.length - 1])
    }
  }

  const handleDelete = async (templateName: string, index: number) => {
    setDeletingIndex(index)
    if (!lab) {
      throw new Error("Lab not found")
    }
    await labAPI.deletePipelineTemplate(templateName, lab.labId)
    Toaster.show({ icon: "trash", message: "Template deleted successfully.", timeout: 5000, intent: "success" })
    const updatedLab = await labAPI.get(lab.id)
    setLab(updatedLab)
    setUserLab(updatedLab)
    setActiveTemplate(undefined)
    setDeletingIndex(undefined)
  }

  return (
    <Dialog isOpen={isPipelineTemplateDialogOpen} onClose={handleClose} style={{ width: "90%", maxWidth: "1000px", height: "90vh", maxHeight: "90vh", paddingTop: "12px", backgroundColor: "white" }}>
      {lab ?
        (
          <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "16px", borderBottom: "solid #E0E0E0 2px" }}>
              <div style={{ fontSize: "24px", fontWeight: 500 }}>Pipeline Templates</div>
              <Button minimal icon="cross" onClick={handleClose} />
            </div>
            <div style={{ display: "flex", flexGrow: 1 }}>
              <ButtonGroup style={{ backgroundColor: "lightgray", borderRight: "solid #c2c2c2 2px" }} vertical minimal alignText="left">
                <Button icon="small-plus" active={activeTemplate === undefined} onClick={() => { setActiveTemplate(undefined) }}>New Template</Button>
                {lab?.pipelineTemplates?.filter(v => v.organism === organism?.name && v.organization === organization?.organizationName)
                .map((template, index) => {
                  return (
                    <ButtonGroup fill style={{ display: "flex" }} key={template.name}>
                      <Button style={{ paddingLeft: "16px"}} fill onClick={() => setActiveTemplate(template)} active={template.name === activeTemplate?.name}>
                        <Text style={{maxWidth: 250}} ellipsize={true}>{template.name}</Text>
                      </Button>
                      <Button icon="trash" style={{ width: "10px" }} onClick={() => handleDelete(template.name, index)} loading={deletingIndex === index} />
                    </ButtonGroup>
                  )
                })}
              </ButtonGroup>              
              <PipelineTemplateForm handleTemplateSubmit={handleTemplateSubmit} activeTemplate={activeTemplate} />
            </div>
          </div>
        ) : (
          <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
            {error ? error : <Spinner />}
          </div>
        )
      }
    </ Dialog>
  );
}