import { Dialog, Button } from '@blueprintjs/core';
import { HTMLTable } from '@blueprintjs/core/lib/esm/index';
import '../styles/ConfirmationTable.css'
import { SampleMetadata } from '../types/SampleTypes';

interface props {  
    isOpen: boolean
    onConfirm: (parsedErrors : Record<number, Array<{ field: string, message: string }>>, standardizedData : SampleMetadata[]) => void
    onCancel: () => void
    data: {parsedErrors : Record<number, Array<{ field: string, message: string }>>, standardizedData : SampleMetadata[], changeLog : Log[]} | Record<string, any>
}

interface StandardizedDataItem {
    [key: string]: any;
}

export interface Log {
    field: string;
    before: string;
    after: string;
}

export function ConfirmationModal ({ isOpen, onConfirm, onCancel, data } : props) {
    // Sort the changeLog by the 'field'
    const sortedChangeLog = data.changeLog.sort((a: any, b: any) => a.field.localeCompare(b.field));

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onCancel}
            title="Data Transformation Required"
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            className="confirm-dialog"
        >
            <div className="bp4-dialog-body">
                <p>Some of the imported data need to be transformed.</p>
                <p>Please review the changes below:</p>
                <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    <HTMLTable className='confirm-table' bordered striped>
                        <thead className='confirm-table-header'>
                            <tr>
                                <th>Field Name</th>
                                <th>Before</th>
                                <th>After</th>
                            </tr>
                        </thead>
                        <tbody className='confirm-table-body'>
                            {sortedChangeLog.map((item: any, index: number) => (
                                <tr key={index}>
                                    <td>{item.field}</td>
                                    <td>{item.before}</td>
                                    <td>{item.after}</td>
                                </tr>
                            ))}
                        </tbody>
                    </HTMLTable>
                </div>
            </div>
            <div className="bp4-dialog-footer">
                <div className="bp4-dialog-footer-actions">
                    <Button intent="primary" onClick={() => onConfirm(data.parsedErrors, data.standardizedData)}>Confirm</Button>
                    <Button intent="none" onClick={onCancel}>Cancel</Button>
                </div>
            </div>
        </Dialog>
    );
};
