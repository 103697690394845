import '../styles/Grid.css';
import { UserProfilePopover } from "./UserProfilePopover";
import { EventNotificationPopover } from "./EventNotificationPopover";
import { AdminDialogue } from './AdminDialogue';
import { PNDataTable } from './PNDataTable';
import { User } from '../types/UserTypes'
import { Lab } from '../types/LabTypes'
import { Toaster } from "../utils/Toaster"
import { userFields, labFields} from "../fields"
import { Navbar } from "@blueprintjs/core/lib/esm/components/navbar/navbar";
import { NavbarGroup } from "@blueprintjs/core/lib/esm/components/navbar/navbarGroup";
import { Button } from "@blueprintjs/core/lib/esm/components/button/buttons";
import { ButtonGroup } from "@blueprintjs/core/lib/esm/components/button/buttonGroup";
import { Spinner } from "@blueprintjs/core/lib/esm/components/spinner/spinner";
import { NonIdealState } from "@blueprintjs/core/lib/esm/components/non-ideal-state/nonIdealState";
import { Menu, MenuItem, Divider } from "@blueprintjs/core"
import { useForm } from 'react-hook-form'; import { useState, useEffect, useRef, useMemo } from 'react';
import { useAuth } from "react-oidc-context";
import { useParams } from "react-router-dom";
import { useOrganizationStore } from '../stores/OrganizationStore';
import { useAdminStore } from '../stores/AdminStore';
import { UserAPI, LabAPI } from '../api/DataAPI';
import { NavLink } from 'react-router-dom';
import { FieldProps } from '../types/OrganismTypes';

interface AdminViewCtrProps {
    authorized: boolean
}

export function AdminViewCtr({ authorized }: AdminViewCtrProps) {
    const auth = useAuth()
    const params = useParams()
    // const { organization, setOrganization } = useOrganizationStore()
    const { setAdminItem, setShowAdminDialogue } = useAdminStore()
    // const form = useForm({
    //     mode: 'onBlur',
    // })

    // const { reset } = form
    // const organizationAPI = new OrganizationAPI(auth.user?.access_token ?? "")
    const userAPI = new UserAPI(auth.user?.access_token ?? "")
    const labAPI = new LabAPI(auth.user?.access_token ?? "")
    const { organizationList } = useOrganizationStore()

    const [tableMode, setTableMode] = useState('users')
    const [userList, setUserList] = useState<User[]>([])
    const [labList, setLabList] = useState<Lab[]>([])
    const [usersError, setUsersError] = useState(null)
    const [labsError, setLabsError] = useState(null)
    const [usersLoading, setUsersLoading] = useState(true)
    const [labsLoading, setLabsLoading] = useState(true)

    const getCurrentOrganization = async () => {
        try {
            // const organization = await organizationAPI.getOrganizationByName(params.organizationName || 'PulseNet');
            // setOrganization(organization)
        } catch (error) {
            console.error(error)
        }
    }

    const getUsers = async () => {
        try {
            setUsersLoading(true)
            const users = await userAPI.getAll()
            setUserList(users)
        } catch (error: any) {
            console.error(error)
            setUsersLoading(false)
            setUsersError(error)
            Toaster.show({ icon: "error", message: `An error occured fetching the users. Message from server: ${error}`, intent: "danger" })
        }
    }

    const getLabs = async () => {
        try {
            setLabsLoading(true)
            const labs = await labAPI.getAll()
            setLabList(labs)
            setLabsLoading(false)
        } catch (error: any) {
            console.error(error)
            setLabsError(error)
            Toaster.show({ icon: "error", message: `An error occured fetching the labs. Message from server: ${error}`, intent: "danger" })
        }
    }

    const formatDatesToString = (date: Date): any => {

        return `${date.getUTCFullYear()}-${('0' + (date.getUTCMonth() + 1)).slice(-2)}-${('0' + date.getUTCDate()).slice(-2)}`

    }

    const getUserRowsAndColumns = useMemo(() => {
        if (userList.length) {
            const filteredUserList = userList.map((el) => {
                const formattedData = { ...el } as User
                formattedData.createdAt = formatDatesToString(new Date(el.createdAt))
                if (el.updatedAt) formattedData.updatedAt = formatDatesToString(new Date(el.updatedAt))
                return formattedData
            })
            setUsersLoading(false)
            return {
                rows: filteredUserList,
                columns: ["name", "email", "userId", "labId", "role", "createdAt", "createdBy", "updatedAt", "updatedBy"]
            }
        } else {
            return {
                rows: [],
                columns: []
            }
        }
    }, [userList])

    const getLabRowsAndColumns = useMemo(() => {
        if (labList.length) {
            const filteredLabList = labList.map((el) => {
                const formattedData = { ...el } as Lab
                formattedData.createdAt = formatDatesToString(new Date(el.createdAt))
                if (el.updatedAt) formattedData.updatedAt = formatDatesToString(new Date(el.updatedAt))
                return formattedData
            })
            setLabsLoading(false)
            return {
                rows: filteredLabList,
                columns: ["name", "labId", "createdAt", "createdBy", "updatedAt", "updatedBy"]
            }
        } else {
            return {
                rows: [],
                columns: []
            }
        }
    }, [labList])

    const fields = useMemo(() => {
        if (tableMode === 'users') {
            return userFields
        }
        if (tableMode === 'labs') {
            return labFields
        }
    }, [tableMode])

    const defaultValues = Object.fromEntries(Object.entries(fields!.properties).map(([k, v]) => {
        const defaultValue = (v: FieldProps) => {
            switch (v.type) {
                case "text": return ''
                case "number": return v.min ? v.min : 0
                case "numbernull": return null
                case "date": return null
                default: return ""
            }
        }

        return [k, defaultValue(v)]
    }))

    const fetchData = async () => {
        await getUsers()
        await getLabs()        
        await getCurrentOrganization();
    }

    useEffect(() => {
        if (authorized) {
            fetchData()
        }
    }, [])

    return (
        <div className="grid-wrapper">
            <div className='grid-header'>
                <Navbar style={{ backgroundColor: "#3c84fb" }}>
                    <NavbarGroup align={'left'}>
                        <NavLink style={{ textDecoration: 'none', color: 'black', outline: 'none' }} to="/"><Button icon="home" minimal title="Site Homepage"></Button></NavLink>
                        <Divider style={{ height: '50%', margin: '10px' }} />
                        {/* <NavLink style={{ textDecoration: 'none', color: 'black', outline: 'none' }} to={`/${organization?.organizationName.toLowerCase()}`}>
                            <Button minimal><div className="bp4-navbar-heading" style={{fontSize: 16, fontFamily: 'Segoe UI'}}>{organization?.organizationName}</div></Button>
                        </NavLink>
                        <Divider style={{ height: '50%', margin: '10px' }} /> */}
                        <div className="bp4-navbar-heading">Admin</div>
                        <div className="bp4-navbar-heading">{window.parsedConfig.DEPLOY_ENVIRONMENT.toUpperCase()} {window.parsedConfig.DEPLOY_VERSION.toUpperCase()}</div>
                    </NavbarGroup>
                    <NavbarGroup align={'right'}>
                        <EventNotificationPopover />
                        <UserProfilePopover />
                    </NavbarGroup>
                </Navbar>
            </div>
            <AdminDialogue type={tableMode} fields={fields!} setUserList={setUserList} setLabList={setLabList} labList={
                labList.map(el => el.labId)
            }                
                defaults={defaultValues}
            />
            {authorized ?
                <>
                    <div className="grid-left-nav">
                        <Menu>
                            <Divider />
                            <MenuItem text="Users" selected={tableMode === 'users'} onClick={() => setTableMode('users')} />
                            <Divider />
                            <MenuItem text="Labs" selected={tableMode === 'labs'} onClick={() => setTableMode('labs')} />
                            <Divider />
                        </Menu>
                    </div>
                    <ButtonGroup minimal={true}>
                        <Button text={`Add New ${tableMode === 'users' ? 'User' : 'Lab'}`} icon="plus" onClick={() => {
                            setAdminItem(null)
                            setShowAdminDialogue(true)
                        }} />
                        <Divider />
                    </ButtonGroup>
                    <Divider />
                    {tableMode === 'users' ?
                        <div className="grid-main" style={{ borderTop: "1px solid rgb(237, 235, 233)" }}>
                            {getUserRowsAndColumns.rows.length > 0 ?
                                <PNDataTable
                                    className="comp-sample-datatable"
                                    data={getUserRowsAndColumns.rows}
                                    columnNames={getUserRowsAndColumns.columns}
                                    adminSelectorColumn={true}
                                    type={'users'}
                                ></PNDataTable>
                                :
                                <div>
                                    {usersLoading ?
                                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column", marginTop: 25 }}>
                                            <Spinner size={55}></Spinner>
                                            <h2>loading users...</h2>
                                        </div>
                                        :
                                        <div>
                                            {usersError ?
                                                <NonIdealState
                                                    icon={"error"}
                                                    title={"There seems to be an issue..."}
                                                    description={"Error Fetching Users.."}
                                                />
                                                :
                                                <div>filtered results show no users</div>
                                            }
                                        </div>
                                    }
                                </div>
                            }
                        </div> : <></>
                    }
                    {tableMode === 'labs' ?
                        <div className="grid-main" style={{ borderTop: "1px solid rgb(237, 235, 233)" }}>
                            {getLabRowsAndColumns.rows.length > 0 ?
                                <PNDataTable
                                    className="comp-sample-datatable"
                                    data={getLabRowsAndColumns.rows}
                                    columnNames={getLabRowsAndColumns.columns}
                                    adminSelectorColumn={true}
                                    type={'labs'}
                                ></PNDataTable>
                                :
                                <div>
                                    {labsLoading ?
                                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column", marginTop: 25 }}>
                                            <Spinner size={55}></Spinner>
                                            <h2>Loading labs...</h2>
                                        </div>
                                        :
                                        <div>
                                            {labsError ?
                                                <NonIdealState
                                                    icon={"error"}
                                                    title={"There seems to be an issue..."}
                                                    description={"Error Fetching Labs.."}
                                                />
                                                :
                                                <div>filtered results show no users</div>
                                            }
                                        </div>
                                    }
                                </div>
                            }
                        </div> : <></>
                    }
                </>
                :
                <div style={{ position: 'absolute', top: "20%", left: '50%', transform: 'translate(-50%, -50%)' }}>
                    <NonIdealState
                        icon={"error"}
                        title={"There seems to be an issue..."}
                        description={"You need to be an Admin to view this content.."}
                    />
                </div>
            }
        </div>
    )
}