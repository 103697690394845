import { lazy, Suspense } from 'react';

const TopNavBar = lazy(() => import('./TopNavBar').then(module => ({ default: module.TopNavBar })));

export function TopNavBarWrapper() {
    return (
        <Suspense fallback={<h4 style={{ marginLeft: 10 }}>Loading...</h4>}>
            <TopNavBar />
        </Suspense>
    );
}