import { create } from 'zustand'
import { PipelineRun } from '../types/PipelineRunTypes';

interface PipelineRunState {
  PipelineRuns: PipelineRun[]
  setPipelineRuns: (PipelineRuns: PipelineRun[]) => void
}

export const usePipelineRunStore = create<PipelineRunState>((set) => ({
  PipelineRuns: [],
  setPipelineRuns: (PipelineRuns) => {
    set({ PipelineRuns })
  }
}));