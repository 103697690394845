import React, { useState, useRef, useEffect, useMemo } from 'react';
import Papa from 'papaparse';
import { Tooltip2 } from "@blueprintjs/popover2/lib/esm/tooltip2"
import { Spinner } from "@blueprintjs/core/lib/esm/components/spinner/spinner";
import { Button } from "@blueprintjs/core/lib/esm/components/button/buttons"
import { Icon } from "@blueprintjs/core/lib/esm/components/icon/icon";
import * as XLSX from 'xlsx';
import '../styles/SampleFileUpload.css';
import { useOrganismStore } from '../stores/OrganismStore';
import { ConfirmationModal } from "./ConfirmationModal";
import { SampleMetadata } from '../types/SampleTypes';
import { Log } from "./ConfirmationModal";
import { H5 } from '@blueprintjs/core/lib/esm/components/html/html';
import { parse, format, isValid } from 'date-fns';

interface childToDataReturnType {
  parsedErrors: Record<number, {
    field: string;
    message: string;
  }[]>;
  validatedData: SampleMetadata[];
  standardizedData: SampleMetadata[];
  changeLog: Log[];
}

interface Props {
  childToParentData: (childdata: SampleMetadata[]) => Promise<childToDataReturnType>
  sampleExistsIndex: boolean[]
  sampleExistsNDBIndex: boolean[]
  setData: React.Dispatch<React.SetStateAction<any[]>>
  setImportLevel: React.Dispatch<React.SetStateAction<string>>
  ndb?: boolean
}

export function SampleFileUpload({ childToParentData, setData, setImportLevel, ndb, sampleExistsIndex, sampleExistsNDBIndex }: Props) {
  // This state will store the parsed data
  const [dataBulkInsert, setDataBulkInsert] = useState<SampleMetadata[]>([]);
  const [fileName, setFileName] = useState("");
  const [organismFields, setOrganismFields] = useState<Record<string, any>>({});
  const { organism } = useOrganismStore();
  const organismName = organism?.name
  //for xlsx file type
  const [sheets, setSheets] = useState<any[]>([]);
  const [selectedSheet, setSelectedSheet] = useState("");

  const [error, setError] = useState("");

  // It will store the file uploaded by the user
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  // Allowed extensions for input file
  // "xls" type : vnd.ms-excel
  // "xlsx" type : vnd.openxmlformats-officedocument.spreadsheetml.sheet
  const allowedExtensions = ["csv", "vnd.ms-excel", "vnd.openxmlformats-officedocument.spreadsheetml.sheet"];

  // This will store the error messages about imported data
  const [errorRecords, setErrorRecords] = useState<Record<string, any>>({});
  const tableRef = useRef<any>();
  interface parsedDataToModal {

  }
  // for confirmation modal regarding data standardization
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [parsedDataToModal, setParsedDataToModal] = useState<Omit<childToDataReturnType, "validatedData">>({
    parsedErrors: {},
    standardizedData: [],
    changeLog: []
  });

  const [loading, setLoading] = useState(false);

  const hiddenFileInput = useRef<any>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError("");

    // Check if user has entered the file
    if (e.target.files && e.target.files.length) {
      const inputFile = e.target.files[0];

      setFileName(inputFile.name);

      // Check the file extensions, if it not
      // included in the allowed extensions
      // we show the error
      const fileExtension = inputFile?.type.split("/")[1];

      if (!allowedExtensions.includes(fileExtension)) {
        setError("Please upload a file with .csv, .xls, or .xlsx extension");
        return;
      }

      if (fileExtension === "csv") {
        // Reset the sheets when a CSV file is selected
        setSheets([]);
      } else if (fileExtension === "vnd.ms-excel" || fileExtension === "vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        const reader = new FileReader();
        reader.onload = (e) => {
          const workbook = XLSX.read(e.target!.result, { type: 'binary' });
          const sheetNames = workbook.SheetNames;
          setSheets(sheetNames);
        };
        reader.readAsBinaryString(inputFile);
      }

      // If input type is correct set the state
      setSelectedFile(() => inputFile);

    }
  };

  //automatic parsing happens only for CSV files and not for other file types.
  useEffect(() => {
    if (selectedFile) {
      const fileExtension = selectedFile.type.split("/")[1]
      if (fileExtension === 'csv')
        handleParse()
    }
  }, [selectedFile])

  useEffect(() => {
    setOrganismFields({ required: organism!.required, properties: organism!.properties })
  }, [organismName, organism])

  useEffect(() => {
    setImportLevel(selectedSheet.toLowerCase())
  }, [selectedSheet])


  const handleParse = () => {

    // If user clicks the parse button without
    // a file we show a error
    if (!selectedFile) return setError("Enter a valid file");

    const fileExtension = selectedFile.type.split("/")[1]
    setLoading(true);

    if (fileExtension === 'csv') {
      // Initialize a reader which allows user
      // to read any file or blob.
      const reader = new FileReader();
      reader.readAsText(selectedFile);
      // Event listener on reader when the file
      // loads, we parse it and set the data.
      reader.onload = async ({ target }: any) => {
        // using papaparser
        const csv = Papa.parse(target?.result, { header: true });
        if (csv.errors && csv.errors.filter((v: any) => v.code === "TooManyFields").length) {
          alert("There is a formatting issue with the csv file. Ensure that commas are properly escaped.")
          return
        }
        const parsedData = csv?.data;

        // Standardize date format(To ensure all dates are formatted as 'yyyy-mm-dd')
        parsedData.forEach((row: any) => {
          Object.entries(organismFields.properties).forEach(([k, v]: [string, any]) => {
            if (v.type === 'date' && row[k]) {
              let date: any = new Date(row[k]);
              if(!isNaN(date.getTime())){
                let year = date.getFullYear();
                let month = String(date.getMonth() + 1).padStart(2, '0');
                let day = String(date.getDate()).padStart(2, '0');
                row[k] = `${year}-${month}-${day}`;
              }
            }
          });
        });
        // check if transformations are needed based on data standardization and store {standardizedData, change log}
        validation(parsedData);
      };
    } else if (fileExtension === 'vnd.ms-excel' || fileExtension === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      // Excel file parsing logic
      const reader = new FileReader();
      reader.readAsBinaryString(selectedFile);
      reader.onload = async (e) => {
        const workbook = XLSX.read(e.target!.result, { type: 'binary', cellDates: true, dateNF: 'yyyy-mm-dd' });
        const worksheet = workbook.Sheets[selectedSheet];
        const rawData: any = XLSX.utils.sheet_to_json(worksheet, { header: 1, raw: false, dateNF: 'yyyy-mm-dd' });

        // Skip the first row (header) and start processing from the second row
        const parsedData = rawData.slice(1).map((row: any) => {
          // Convert array to object based on header row
          let rowData: Record<string, any> = {};
          rawData[0].forEach((header: string, index: number) => {
            // Replace null or undefined with ''
            rowData[header] = (row[index] === null || row[index] === undefined) ? '' : row[index].toString().trim();
          });

          // Filter the fields based on properties of pulsenet or calicinet organization
          return Object.fromEntries(
            Object.entries(rowData).filter(([key, _]) => organism?.properties.hasOwnProperty(key) || findPropNameFromTitle(key) !== key)
          );
        });
        // check if transformations are needed based on data standardization and store {standardizedData, change log}
        validation(parsedData);
      };
    }
  };

  const validation = async (parsedData: Record<string, any>) => {
    var filteredBulkData = rowTrim(parsedData);

    const { parsedErrors, validatedData, standardizedData, changeLog } = await childToParentData(filteredBulkData);
    setData(validatedData);
    if (changeLog.length) {
      setParsedDataToModal({ parsedErrors, standardizedData, changeLog });
      setIsModalOpen(true);
    } else {
      display({ parsedErrors, standardizedData });
    }
  }

  const display = async ({ parsedErrors, standardizedData: filteredBulkData }: Omit<childToDataReturnType, "validatedData" | "changeLog">) => {
    const AlignedErrorRecords: any = Object.entries(parsedErrors).reduce((obj, [index, val]) => {
      const errorsInRow = val.reduce((a: any, b) => {
        // check if this field already has an error message. and if so, append it to the existing message.
        if (a[b.field]) {
          return { ...a, [b.field]: a[b.field] + ' ' + b.message };
        } else {
          return { ...a, [b.field]: b.message };
        }
      }, {});
      return { ...obj, [index]: errorsInRow };
    }, {});
    setErrorRecords(AlignedErrorRecords);

    setDataBulkInsert(filteredBulkData); // set dataBulkInsert

    setLoading(false)
    if (tableRef.current) {
      // find the target elements within the table
      let targets: NodeListOf<HTMLElement> = tableRef.current.querySelectorAll('[class*="popover2-target"]');
      targets.forEach(target => {
        target.style.display = 'inline-block';
        target.style.width = '100%';
        target.style.height = '100%';
      });
    }
  }

  const findPropNameFromTitle = (key: string) => {
    const properties = organism?.properties
    let propName
    for (let property in properties) {
      if (properties[property].title === key) {
        propName = property
        break
      }
    }
    return propName
  }

  const dateFieldsArray = useMemo<string[]>(() => {
    const propertiesEntries = Object.entries(organism?.properties!)
    const dateProperties = propertiesEntries.reduce((accumulator: string[], [key, value]: [string, any]) => {
      if(value.type === "date"){
        accumulator.push(key)
      }
      return accumulator
    },[])
    return dateProperties
  },[organism])

  const reFormatDate = (incomingDate: string | Date) => {
    const dateFormats = [
      'yyyy-MM-dd',
      'MM/dd/yyyy',
      'dd/MM/yyyy',
      'yyyy/MM/dd',
      'MM-dd-yyyy',
      'dd-MM-yyyy',
      'dd/mm/yy',
      'mm/dd/yy',
      'dd-mm-yy',
      'mm-dd-yy'
      // Add other formats as needed
    ];
    let  parsedDate = null
    for (const formatStr of dateFormats) {
      parsedDate = parse(incomingDate.toString(), formatStr, new Date());
      if (isValid(parsedDate)) {
        break;
      }
    }
    if (!isValid(parsedDate) || parsedDate === null) {
      return incomingDate
    }
    const outputDate = format(parsedDate, 'yyyy-MM-dd');
    return outputDate
  }

  const rowTrim = (parsedData: Record<string, any>) => {
    var trimmedData = [];
    for (var row = 0; row < parsedData.length; row++) {
      // when organization is pulsenet, key must be unique and is required
      if (organism?.organization.includes('PulseNet') && parsedData[row].Key === "") {
        continue;
      }
      var filteredRow = Object.fromEntries(
        Object.entries(parsedData[row])
          .reduce((accumulator: any, [key, value]: [any, any]) => {
            if (organism?.properties.hasOwnProperty(key)) {
                if(dateFieldsArray.includes(key)){
                  value = reFormatDate(value)
                }
              accumulator.push([key, value])
            } else {
              const propName = findPropNameFromTitle(key)
              if (propName) {
                  if(dateFieldsArray.includes(propName)){
                    value = reFormatDate(value)
                  }
                accumulator.push([propName, value])
              }
            }
            return accumulator
          }
            , [])
      );

      // Create a new row with all fields, then overwrite the values from filteredRow
      // var allFieldsRow = Object.fromEntries(Object.keys(fieldProp).map((key) => [key, ""]));
      // filteredRow = {...allFieldsRow, ...filteredRow};

      const trimmedRow = Object.fromEntries(Object.entries(filteredRow).map(([k, v]: any) => [k, v.trim()]))
      if (Object.entries(trimmedRow).map(([k, v]) => v).filter(v => v).length)
        trimmedData.push(trimmedRow);
    }
    return trimmedData;
  }

  const onModalConfirm = (parsedErrors: Record<number, Array<{ field: string, message: string }>>, standardizedData: SampleMetadata[]) => {
    // Implement data standardization and continue process
    display({ parsedErrors, standardizedData });
    setIsModalOpen(false);
    setParsedDataToModal({
      parsedErrors: {},
      standardizedData: [],
      changeLog: []
    });
  }

  const onModalCancel = () => {
    setIsModalOpen(false);
    setLoading(false)
  }

  const TooltipContent = ({ index, errorRecords }: { index: number, errorRecords: Record<string, any> }) => {
    if (index === undefined || !errorRecords[index]) return <ul></ul>;
    return (
      <ul>
        {Object.entries(errorRecords[index]).map(([k, v]) => (
          <li key={k}>{v}</li>
        ))}
      </ul>
    );
  };

  const MemoizedTooltipContent = React.memo(TooltipContent);

  const getStatusIcon = (i: number) => ndb
    ? <td key={-1}>{errorRecords[i] ?
      <Tooltip2
        content={sampleExistsNDBIndex[i] ?
          <MemoizedTooltipContent index={i} errorRecords={errorRecords} />
          : <span>Sample does not exist in National Database</span>
        }
        openOnTargetFocus={false}
        placement="right"
        usePortal={true}
      >
        {sampleExistsIndex[i]
          ? <Icon icon="error" intent='danger' aria-label='validation error'></Icon>
          : <Icon icon="cross-circle" intent='danger' aria-label='sample does not exist error'></Icon>}
      </Tooltip2>
      : <Icon icon="tick" intent='success' aria-label='validation success'></Icon>}
    </td>
    : <td key={-1}>{errorRecords[i] 
      ? <Tooltip2
        content={<MemoizedTooltipContent index={i} errorRecords={errorRecords} />}
        openOnTargetFocus={false}
        placement="right"
        usePortal={true}
      >
        <Icon icon="error" intent='danger' aria-label='validation error'></Icon>
      </Tooltip2>
      : sampleExistsNDBIndex[i] 
        ? <Icon icon="cross-circle" intent='danger' aria-label='sample exists in national database' title="This sample exists in the national database and cannot be imported."></Icon>
        :<Icon icon={sampleExistsIndex[i] ? "tick" : "plus"} intent='success' aria-label='validation success' title={sampleExistsIndex[i] ? "Update existing sample." : "Insert new sample."}></Icon>
    }
    </td>

  return (
    <div className="sample-file-upload">
      <div className="file-select">
        <label htmlFor="csvInput" style={{ display: "block" }}>
          Choose Sample File <span className="supported-formats">(Supported Formats: csv, xls, xlsx)</span>
        </label>
      </div>
      <div>
        <Button onClick={() => { hiddenFileInput.current.value = null; hiddenFileInput.current.click() }}>Select Import File</Button>
        {fileName && <span className="file-name">{fileName}</span>}
        <input type="file" id="csvInput" ref={hiddenFileInput} onChange={handleFileChange} style={{ display: "none" }} />
        <div style={{ marginTop: "10px" }}>
          {/* Dropdown for selecting a sheet */}
          {sheets.length > 0 && (
            <div className="sheet-select">
              <label>Select a sheet:</label>
              <select onChange={(e) => setSelectedSheet(e.target.value)} value={selectedSheet}>
                <option value=""></option>
                {sheets.map((sheet, index) => (
                  <option key={index} value={sheet}>{sheet}</option>
                ))}
              </select>
              <Button icon="import" onClick={handleParse} disabled={!selectedSheet}>
                Import
              </Button>
            </div>
          )}
        </div>
      </div>
      <div style={{ maxHeight: 360, overflowX: "auto", overflowY: "auto" }}>
        {loading ? <div style={{ display: "flex", alignItems: "center", margin: "15px", gap:5 }}>
          <Spinner size={40}></Spinner>
          <H5>Validating Data...</H5>
        </div> :
          (dataBulkInsert.length === 0 || error ? error :
            <table ref={tableRef}>
              <thead>
                <tr key={"header"}>
                  <td></td>
                  {Object.keys(dataBulkInsert[0]).map((key, i) => (
                    <th key={i}>{key}</th>
                  ))}
                </tr></thead>
              <tbody>
                {dataBulkInsert.map((item: any, i: any) => (
                  <tr key={i} style={errorRecords[i] ? { position: "relative" } : {}}>
                    { getStatusIcon(i)}
                    {Object.entries(item).map(([field, val], j) => (
                      <td key={j} style={errorRecords[i] && errorRecords[i][field] ? { backgroundColor: "#ff49499e", position: "relative", zIndex: "1" } : {}}>
                        {errorRecords[i] && errorRecords[i][field] ? (
                          <Tooltip2
                            content={errorRecords[i][field]}
                            openOnTargetFocus={false}
                            placement="right"
                            usePortal={true}
                          >
                            {val ? val : <span style={{ opacity: 0.01 }}>i</span>}
                          </Tooltip2>
                        ) : (
                          val
                        )}
                      </td>
                    ))}
                    {/* {errorRecords[i] &&
              <Fragment>
                <td style={{ position: "absolute", top: "-2px", left: "-2px", height: "calc(100% + 4px)", width: "calc(100% + 2px)", border: "2px solid red", zIndex: "0" }} />
              </Fragment>
              } */}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
      </div>
      <ConfirmationModal
        isOpen={isModalOpen}
        onConfirm={onModalConfirm}
        onCancel={onModalCancel}
        data={parsedDataToModal}
      />
    </div>
  );
}
