import { create } from 'zustand'
import { Comparison } from '../types/ComparisonTypes';
import cloneDeep from 'lodash.clonedeep';

interface ComparisonState {
  comparisons: Comparison[]
  samples: string[]
  setComparisons: (comparisons: Comparison[]) => void
  addComparison: (comparison: Comparison) => void
  updateComparison: (comparison: Comparison) => void
  deleteComparisons: (comparisonIds: string[]) => void
  setSamples: (samples: string[]) => void
}

export const useComparisonStore = create<ComparisonState>((set) => ({
  comparisons: [],
  samples: [],
  setComparisons: (comparisons) => {
    set({ comparisons })
  },
  addComparison: (comparison) => {
    set((state) => ({ comparisons: [...state.comparisons, comparison] }))
  },
  updateComparison: (comparison) => {
    set((state) => {
      const index = state.comparisons.map(v => v.id).indexOf(comparison.id)
      let comparisons = cloneDeep(state.comparisons)
      comparisons[index] = comparison
      return { comparisons }
    })
  },
  deleteComparisons: (comparisonIds: string[]) => {
    set((state) => ({ comparisons: state.comparisons.filter(v => !comparisonIds.includes(v.id)) }))
  },
  setSamples: (samples) => {
    set({ samples })
  }
}));