import { User } from "oidc-client-ts";
import { useEffect } from "react";
import { useAuth, hasAuthParams, AuthProviderProps, AuthProvider } from "react-oidc-context";
import { useUserStore } from "../stores/UserStore";

export const SAMSOauthProvider = (props: { children: JSX.Element }) => {
  if (!window.location.href.includes('callback')) sessionStorage.setItem("navData", JSON.stringify(window.location))
  const parsedConfig: CONFIG = JSON.parse(window.rawConfig)
  window.parsedConfig = parsedConfig;

  //Reset url state to default domain
  const onSigninCallback = (_user: User | void): void => {
    window.history.replaceState(
      {},
      document.title,
      "/"
    )
  }

  const oidcConfig: AuthProviderProps = {
    authority: window.parsedConfig.OAUTH_AUTHORITY,
    client_id: window.parsedConfig.OAUTH_CLIENT_ID,
    redirect_uri: window.parsedConfig.OAUTH_REDIRECT_URI,
    // redirect_uri: "",
    scope: "openid profile email dex:upload dex:download dex:status",
    response_type: 'code',
    automaticSilentRenew: true,
    //use custom token endpoint
    metadata: {
      authorization_endpoint: window.parsedConfig.OAUTH_AUTH_URI,
      token_endpoint: window.parsedConfig.OAUTH_TOKEN_URI
    },
    disablePKCE: true,
    onSigninCallback,
    revokeTokensOnSignout: true,
    post_logout_redirect_uri: "https://auth-stg.cdc.gov/errors/globallogout.asp",
    // accessTokenExpiringNotificationTimeInSeconds: 3500,
  };

  return <AuthProvider {...oidcConfig}>
    <OAuthSignIn>
      {props.children}
    </OAuthSignIn>
  </AuthProvider>
}

const activity_events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

const OAuthSignIn = (props: { children: JSX.Element }) => {
  const { isAuthenticated, activeNavigator, isLoading, signinRedirect, error, removeUser, signoutSilent } = useAuth();
  const { loggedOut, setLoggedOut } = useUserStore()
  let timer: any;

  // this function sets the timer that logs out the user after 10 secs
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      // clears any pending timer.      
      resetTimer();
      // Listener clean up. Removes the existing event listener from the window
      Object.values(activity_events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      // logs out user
      logoutAction();
    }, 1800000); // 10000ms = 10secs. You can change the time.
  };

  // this resets the timer if it exists.
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  const logoutAction = () => {
    setLoggedOut(true)
    removeUser();
    signoutSilent();
    window.location.replace("https://auth-stg.cdc.gov/errors/globallogout.asp");
  };

  useEffect(() => {
    Object.values(activity_events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
  }, []);



  useEffect(() => {
    // if (auth.isAuthenticated)
    //   auth.signoutRedirect();


    // to run locally
    const isOverride = (process.env["REACT_APP_OVERRIDE_AUTH"] === "true")
    if (!isOverride) {
      if (!hasAuthParams() && !isAuthenticated && !activeNavigator && !isLoading && !loggedOut) {
        if (!window.location.href.includes("basespace-auth"))
          signinRedirect();
      }
    }
  }, [isAuthenticated, activeNavigator, isLoading, signinRedirect, loggedOut]);

  // useEffect(() => {
  //   // the `return` is important - addAccessTokenExpiring() returns a cleanup function
  //   return events.addAccessTokenExpiring(() => {
  //     if (window.confirm("You're about to be signed out due to inactivity. Press OK to stay signed in.")) {
  //       signinSilent();
  //     }
  //     else {
  //       setLoggedOut(true)
  //       removeUser();
  //       signoutSilent();
  //       window.location.replace("https://auth-stg.cdc.gov/errors/globallogout.asp");
  //     }
  //   })
  // }, [events, signinSilent, removeUser]);

  switch (activeNavigator) {
    case "signinSilent":
      return <div>Signing you in...</div>;
    case "signoutRedirect":
      return <div>Signing you out...</div>;
  }

  // if (auth.activeNavigator === "signoutRedirect")

  if (isLoading)
    return <div>Loading...</div>;

  if (error)
    return <div>OAUTH ERROR: {error.message} </div>;

  if (isAuthenticated || process.env["REACT_APP_OVERRIDE_AUTH"] === "true")
    return props.children

  return <div>Successfully logged out.</div>;
}