import { create } from 'zustand';
import { Organism } from '../types/OrganismTypes';

interface OrganismState {
  organism?: Organism
  setOrganism: (organism: Organism | undefined) => void
  organismList?: Organism[]
  setOrganismList: (organismList: Organism[]) => void
}

export const useOrganismStore = create<OrganismState>((set) => ({
  setOrganism: (organism) => {
    set({ organism })
  },
  setOrganismList: (organismList) => {
    set({ organismList })
  }
}));