import { useAuth } from "react-oidc-context"
import { PipelineRunAPI, SampleAPI } from "../api/DataAPI"
import { usePipelineRunsDialogStore } from "../stores/PipelineRunsDialogStore"
import { useAppStore } from "../stores/AppStore";
import { SampleKeyIdMapping } from "../types/SampleTypes";
import { Weblog } from "../types/WeblogTypes";
import { Toaster } from "../utils/Toaster";
import { useMemo } from "react";
import { useOrganizationStore } from "../stores/OrganizationStore";
import { PipelineRun } from "../types/PipelineRunTypes";

interface PipelineRunsDialogFunctions {
  loadWeblog: (piplineRunId: string) => Promise<void>
  loadDialog: (id: string | undefined) => Promise<void>
  restartPipelineRun: (pipelineRunId: string) => Promise<void>
  cancelPipelineRun: (pipelineRunId: string) => Promise<void>
  getWeblog: Weblog[]
  resetDialog: () => void
  loadSampleData: (pipelineRun: PipelineRun) => Promise<void>
}

export const usePipelineRunsDialogFunctions = (): PipelineRunsDialogFunctions => {
  const { pipelineRun, setCreatedAt, setCreatedBy, setUpdatedBy, setLoading, setErrorMessage, setShowErrorDialog,
    setSampleKeyIdMap, setLabId, setStatus, setUpdatedAt, setPipelineRun } = usePipelineRunsDialogStore()
  const { setShowPipelineRunsDialog } = useAppStore()
  const auth = useAuth()
  const { organization } = useOrganizationStore()
  const sampleAPI = new SampleAPI(auth.user?.access_token ?? "")
  const pipelineRunAPI = new PipelineRunAPI(auth.user?.access_token ?? "")

  const loadWeblog = async (pipelineRunId: string) => {
    try {
      const pipelinerunInfo = await pipelineRunAPI.get(pipelineRunId)
      setStatus(pipelinerunInfo.status)
      setUpdatedAt(pipelinerunInfo.updatedAt)
      setPipelineRun(pipelinerunInfo)
    }
    catch (e) {
    }
  }

  const resetDialog = () => {
    //Resetting the state    
    setPipelineRun(undefined)
    setStatus("")
    setUpdatedAt(null)
    setErrorMessage("")
    setShowErrorDialog(false);
    setSampleKeyIdMap([])
    // setPipelineRun(undefined)
    setLabId("");
    setCreatedAt("");
    setCreatedBy("");
    setUpdatedAt(null);
    setUpdatedBy(null);
  }

  const loadSampleData = async (pipelineRun: PipelineRun) => {
    const samplesKeyIdMap = pipelineRun.sampleKeys ? await Promise.all(
      pipelineRun.sampleKeys.map(async v => {
        let sampleKeyIdMapping: SampleKeyIdMapping = {
          identifier: v,
          id: ""
        }
        try {
          sampleKeyIdMapping.sample = (organization?.useLabAccession ? (await sampleAPI.getByLabAccessionNumber(v)) : (await sampleAPI.getByKey(v))) ?? undefined
          sampleKeyIdMapping.id = sampleKeyIdMapping?.sample?.id
        } catch (e) {
          if (e instanceof Error) {
            setErrorMessage("Error loading Pipeline Run. One or more sample key(s) associated with this pipeline run are missing")
          } else {
            setErrorMessage("An error occurred.")
          }
          setShowErrorDialog(true);
        }
        return sampleKeyIdMapping
      })
    ) : []
    setSampleKeyIdMap(samplesKeyIdMap)
  }

  const loadDialog = async (id: string | undefined) => {
    setShowPipelineRunsDialog(true)
    setLoading(true)
    resetDialog()

    if (id) {
      // load corresponding pipelinerun info
      try {
        const pipelinerunInfo = await pipelineRunAPI.get(id)
        const samplesKeyIdMap = pipelinerunInfo.sampleKeys ? await Promise.all(
          pipelinerunInfo.sampleKeys.map(async v => {
            let sampleKeyIdMapping: SampleKeyIdMapping = {
              identifier: v,
              id: ""
            }
            try {
              sampleKeyIdMapping.sample = (organization?.useLabAccession ? (await sampleAPI.getByLabAccessionNumber(v)) : (await sampleAPI.getByKey(v))) ?? undefined
              sampleKeyIdMapping.id = sampleKeyIdMapping?.sample?.id
            } catch (e) {
              if (e instanceof Error) {
                setErrorMessage("Error loading Pipeline Run. One or more sample key(s) associated with this pipeline run are missing")
              } else {
                setErrorMessage("An error occurred.")
              }
              setShowErrorDialog(true);
            }
            return sampleKeyIdMapping
          })
        ) : []

        setSampleKeyIdMap(samplesKeyIdMap)
        setPipelineRun(pipelinerunInfo)
        setStatus(pipelinerunInfo.status);
        setLabId(pipelinerunInfo.labId);
        setCreatedAt(pipelinerunInfo.createdAt);
        setCreatedBy(pipelinerunInfo.createdBy);
        setUpdatedAt(pipelinerunInfo.updatedAt);
        setUpdatedBy(pipelinerunInfo.updatedBy);

      } catch (e) {
        if (e instanceof Error) {
          setErrorMessage(e.message)
        } else {
          setErrorMessage("An error occurred.")
        }
        setShowErrorDialog(true);
      } finally {
        setLoading(false)
      }
    }
    else {
      setShowPipelineRunsDialog(false)
      setErrorMessage("PipelineRuns id is not found.")
      setShowErrorDialog(true);
      setLoading(false)
      throw (Error("PipelineRuns id is not found."))
    }
  }
  const restartPipelineRun = async (pipelineRunId: string) => {
    if (!window.confirm("Would you like to restart this pipeline run? All logs and results will be overwritten."))
      return
    setLoading(true)
    try {
      const pipelineRun = await pipelineRunAPI.restartPipeline(pipelineRunId);
      setPipelineRun({
        ...pipelineRun,
        nextflowWebLogs: []
      })
      setStatus(pipelineRun.status);
      setLabId(pipelineRun.labId);
      setCreatedAt(pipelineRun.createdAt);
      setCreatedBy(pipelineRun.createdBy);
      setUpdatedAt(pipelineRun.updatedAt);
      setUpdatedBy(pipelineRun.updatedBy);
    } catch (e) {
      alert("There was an error attempting to restart the pipeline run");
    }
    setLoading(false)
  }


  const cancelPipelineRun = async (pipelineRunId: string) => {
    if (!window.confirm("Would you like to cancel this pipeline run?"))
      return
    setLoading(true)
    try {
      await pipelineRunAPI.cancelPipeline(pipelineRunId);
      const pipelineRun = (await pipelineRunAPI.get(pipelineRunId))
      if (!pipelineRun)
        throw (new Error("PipelineRun not found."));
      setPipelineRun({
        ...pipelineRun,
        nextflowWebLogs: []
      })
      setStatus(pipelineRun.status);
      setLabId(pipelineRun.labId);
      setCreatedAt(pipelineRun.createdAt);
      setCreatedBy(pipelineRun.createdBy);
      setUpdatedAt(pipelineRun.updatedAt);
      setUpdatedBy(pipelineRun.updatedBy);
    } catch (e) {
      alert("There was an error attempting to cancel the pipeline run.");
    }
    setLoading(false)
  }

  const getWeblog = useMemo(() => {
    try {
      return (pipelineRun?.nextflowWebLogs && typeof (pipelineRun?.nextflowWebLogs[0]) === "string")
        ? (pipelineRun?.nextflowWebLogs as string[]).map((v) => JSON.parse(v) as Weblog)
        : pipelineRun?.nextflowWebLogs as Weblog[]
    } catch (e) {
      Toaster.show({ icon: "error", message: "Error parsing weblogs.", intent: "danger" })
      return []
    }
  }, [pipelineRun?.nextflowWebLogs])

  return { loadWeblog, loadDialog, restartPipelineRun, cancelPipelineRun, getWeblog, resetDialog, loadSampleData }
}