import { lazy, Suspense } from 'react';

const DataViewerCtr = lazy(() => import('./DataViewerCtr').then(module => ({ default: module.DataViewerCtr })));


export function DataViewerCtrWrapper() {
  return (
    <Suspense fallback={<h4 style={{ marginLeft: 10 }}>Loading...</h4>}>
      <DataViewerCtr />
    </Suspense>
  );
}