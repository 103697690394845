import { create } from 'zustand'
import { Sample } from '../types/SampleTypes';
//import { useAuth} from "react-oidc-context";
var cloneDeep = require('lodash.clonedeep');

interface SampleState {
  samples: Sample[]
  // loadSamples: () => Promise<void>  
  // saveSample: (sample: Sample) => Promise<void>
  // deleteSample: (sampleId: number) => Promise<void>
  // deleteSamples: (sampleIds: number[]) => Promise<void>
  setSamples: (samples: Sample[]) => void
  addSample: (sample: Sample) => void
  updateSample: (sample: Sample) => void
  deleteSamples: (sampleIds: string[]) => void
}


export const useSampleStore = create<SampleState>((set) => {  
  return {
    samples: [],
    setSamples: (samples) => {
      set({ samples })
    },
    addSample: (sample) => {
      set((state) => ({ samples: [...state.samples, sample] }))
    },
    updateSample: (sample) => {
      set((state) => {
        const index = state.samples.map(v => v.id).indexOf(sample.id)
        let samples = cloneDeep(state.samples)
        samples[index] = sample
        return { samples }
      })
    },
    deleteSamples: (sampleIds: string[]) => {
      set((state) => ({ samples: state.samples.filter(v => !sampleIds.includes(v.id)) }))
    }
    // saveSample: async (sample: Sample) => {
    //   await sample.id ? sampleAPI.updateSample(sample) : sampleAPI.addSample(sample)
    //   const samples = await sampleAPI.getSamples()
    //   set({samples})
    // },
    // deleteSample: async (sampleId: number) => {
    //   await sampleAPI.deleteSample(sampleId)
    //   const samples = await sampleAPI.getSamples()
    //   set({samples})
    // },
    // deleteSamples: async (sampleIds: number[]) => {    
    //   await Promise.all(sampleIds.map(v => sampleAPI.deleteSample(v)))
    //   set((state) => ({samples: state.samples.filter(v => !sampleIds.includes(v.id) )}))    
    // }
  }
});