import { create } from 'zustand'
import { SampleProcesses, SampleAnalysis, Sample } from '../types/SampleTypes';
import { SampleAPI } from '../api/DataAPI';
import { PipelineRun } from '../types/PipelineRunTypes';
import { Toaster } from '../utils/Toaster';


interface SampleDialogState {
  showSampleDialog: boolean;
  loading: boolean;
  showForm: boolean;
  isFormDirty: boolean;
  setShowForm: (showForm: boolean) => void
  setIsFormDirty: (isFormDirty: boolean) => void
  selectedSampleId?: string | undefined;
  setSelectedSampleId: (selectedSampleId?: string | undefined) => void
  processes: SampleProcesses | undefined;
  ncbiProcesses: SampleProcesses | undefined;
  analyses: SampleAnalysis[] | undefined;
  pipelineRun: PipelineRun | null | undefined
  sample: Sample | undefined
  setSample: (sample: Sample | undefined) => void
  selectedProcess: string
  setSelectedProcess: (selectedProcess: string) => void
  selectedAnalysis?: SampleDialogAnalyses | undefined
  setSelectedAnalysis: (selectedAnalysis: SampleDialogAnalyses | undefined) => void
  selectedTabIndex: number,
  setSelectedTabIndex: (selectedTabIndex: number) => void
  openSampleDialog: (sampleId: string | undefined, nationalDatabase: boolean, token: string, selectedTabIndex?: number, selectedProcess?: string) => void
  openSampleForm: () => void
  closeSampleDialog: () => void
}

export interface SampleDialogAnalyses {
  processName: string,
  data: object
}

export const useSampleDialogStore = create<SampleDialogState>((set) => ({
  selectedSampleId: undefined,
  setSelectedSampleId: (selectedSampleId?: string | undefined) => set({ selectedSampleId }),
  showSampleDialog: false,
  loading: false,
  showForm: false,
  isFormDirty: false,
  setShowForm: (showForm: boolean) => set({ showForm }),
  setIsFormDirty: (isFormDirty: boolean) => set({ isFormDirty }),
  processes: undefined,
  ncbiProcesses: undefined,
  analyses: undefined,
  pipelineRun: undefined,
  sample: undefined,
  setSample: (sample: Sample | undefined) => set({ sample }),
  selectedProcess: "",
  setSelectedProcess: (selectedProcess: string) => set({ selectedProcess }),
  selectedAnalysis: undefined,
  setSelectedAnalysis: (selectedAnalysis: SampleDialogAnalyses | undefined) => set({ selectedAnalysis }),
  selectedTabIndex: 0,
  setSelectedTabIndex: (selectedTabIndex: number) => set({ selectedTabIndex }),
  openSampleDialog: async (sampleId: string | undefined, nationalDatabase: boolean, token: string, selectedTabIndex?: number, selectedProcess?: string) => {
    const sampleAPI = new SampleAPI(token)
    set({ showSampleDialog: true })
    if (sampleId) {
      set({ loading: true })
      try {
        const sample = nationalDatabase
          ? await sampleAPI.getNdbById(sampleId)
          : await sampleAPI.get(sampleId)
        let pipelineRun = undefined
        if (sample.pipelineRunId && !sample.nationalDatabase)
          try {
            pipelineRun = await sampleAPI.getPipelineRunBySampleId(sampleId)
          }
          catch (e) {
            Toaster.show({ icon: "error", message: `There was an error loading the sample pipeline run.`, intent: "danger" })
          }
        let processes = undefined

        let sampleAnalyses = undefined
        if (["Success", "Warning", "Failed", "Error"].includes(sample.pipelineRunStatus)) {
          try {
            processes = nationalDatabase
              ? undefined
              : await sampleAPI.getProcessesById(sampleId)
          }
          catch (e) {
            // eslint-disable-next-line no-console
            console.error(`There was an error loading the sample metrics.`);
          }
        }
        let ncbiProcesses = undefined
        if (sample.otherPipelineRunStatuses && ["Success", "Warning", "Failed", "Error"].includes(sample.otherPipelineRunStatuses?.ncbiPipelineStatus)) {
          try {
            ncbiProcesses = nationalDatabase
              ? undefined
              : await sampleAPI.getNCBIProcessesById(sampleId)
          }
          catch (e) {
            // eslint-disable-next-line no-console
            console.error(`There was an error loading the sample metrics.`);
          }
        }

        try {
          sampleAnalyses = nationalDatabase
            ? await sampleAPI.getAnalysesNdb(sampleId)
            : await sampleAPI.getAnalyses(sampleId)
        }
        catch (e) {
          // eslint-disable-next-line no-console
          console.error(`here was an error loading the sample analyses.`);
        }

        set({
          sample,
          pipelineRun,
          processes,
          ncbiProcesses,
          analyses: sample.organism === "NOROVIRUS" ? (sampleAnalyses ? sampleAnalyses.filter((v: SampleAnalysis) => !v.processType?.name.startsWith('calicinet')) : undefined) : (sampleAnalyses ? sampleAnalyses.filter((v: SampleAnalysis) => v.result?.values) : undefined),
          selectedProcess: processes?.hasOwnProperty(selectedProcess ?? "") ? selectedProcess : ""
        })
      }
      catch (e) {
        Toaster.show({ icon: "error", message: `There was an error loading the sample.`, intent: "danger" })
      }
    }
    set({
      selectedSampleId: sampleId,
      selectedTabIndex: selectedTabIndex ?? 0,
      loading: false,
    })
  },
  openSampleForm: () => set({
    showSampleDialog: true,
    showForm: true
  }),
  closeSampleDialog: () => {
    set({
      showForm: false,
      showSampleDialog: false,
      isFormDirty: false,
      processes: undefined,
      analyses: undefined,
      selectedProcess: "",
      selectedAnalysis: undefined,
      sample: undefined,
      pipelineRun: undefined,
      selectedSampleId: undefined
    })
  }
}))