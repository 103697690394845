import { Dialog, Button } from "@blueprintjs/core";
import { ColorResult, SketchPicker } from "react-color";
import "../styles/AddToGroup.css";

interface ColorPickerDialogProps {
  isOpen: boolean;
  onClose: () => void;
  color: string;
  onChangeComplete: (color: ColorResult) => void;
}

export const ColorPickerDialog = ({ isOpen, onClose, color, onChangeComplete } : ColorPickerDialogProps) => {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      className="dialog"
    >
      <div>
        <SketchPicker key={color} color={color} onChangeComplete={onChangeComplete} />
      </div>
      <div className="color-picker-footer">
        <Button onClick={onClose}>Select</Button>
      </div>
    </Dialog>
  );
};