import { useState } from "react";
import { FieldRef, ICellLookup, ICellSetter } from "../EditDataViewer";
import { useEditDataViewSelectStore } from "../../stores/EditDataViewSelectStore";
import { useEditDataViewSelectFunctions } from "../../hooks/useEditDataViewSelectFunctions";

type copiedCellType = {
  leftmostIndex: number
  rightmostIndex: number
  topmostIndex: number
  bottommostIndex: number
}

interface EditDataViewSelectProps {
  columnIndex: number
  rowIndex: number
  getCellData: ICellLookup
  setCellData: ICellSetter
  values: string[]
  field: FieldRef
  resetBorders: (leftmostIndex:number, rightmostIndex:number, topmostIndex:number, bottommostIndex:number, activeIndex:number) => void
  copiedCells: copiedCellType | null
  activeIndex: number
  activeShowSingleClickEdit: boolean
}

  export function EditDataViewSelect({ columnIndex, rowIndex, getCellData, setCellData, values, field, resetBorders, copiedCells, activeIndex, activeShowSingleClickEdit }: EditDataViewSelectProps) {  
  const { showSingleclickedit, isComparison } = useEditDataViewSelectStore()
  const [showSelect, setShowSelect] = useState(showSingleclickedit);
  const [alreadyFreeTextSelected, setAlreadyFreeTextSelected] = useState(false);
  const [isFreeText, setIsFreeText] = useState(false);
  const [inputvalue, setInputvalue] = useState(getCellData(rowIndex, columnIndex));
  const {
    handleInteraction,
    handleSelectChange,
    onBlurCapture,
    handleClicked
  } = useEditDataViewSelectFunctions(setShowSelect, setAlreadyFreeTextSelected, isFreeText, setIsFreeText, inputvalue, setInputvalue)

  return (
    <div
      {...(showSingleclickedit ? { onClick: () => handleInteraction({ values, getCellData, rowIndex, columnIndex, copiedCells, resetBorders, activeIndex }) } : { onDoubleClick: () => handleInteraction({ values, getCellData, rowIndex, columnIndex, copiedCells, resetBorders, activeIndex }) })}
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        left: "0px"
      }}
      onFocus={(e) => {e.target.style.opacity = "1"}}
      onBlurCapture={() => onBlurCapture({setCellData, rowIndex, columnIndex, field})}
    >
      {showSelect ? (
        alreadyFreeTextSelected ? (
          isFreeText ? (
            <input
              style={{ width: "100%", marginTop: isComparison ? "1px" : undefined, height: isComparison ? "29px" : undefined } }
              onChange={(e) => setInputvalue(e.currentTarget.value)}
              onBlur={() => setCellData(inputvalue, rowIndex, columnIndex, field)}
              value={inputvalue}
            />
          ) : (
            <select
              style={{ width: "100%", marginTop: isComparison ? "1px" : undefined, height: isComparison ? "29px" : undefined , opacity: activeShowSingleClickEdit ? 0 : 1 }}
              onChange={(e) => handleSelectChange({ e, rowIndex, columnIndex, field, values, setCellData, getCellData })}
              onClick={handleClicked}
              value={ values.includes(getCellData(rowIndex, columnIndex)) ? getCellData(rowIndex, columnIndex) : "" }
            >
              <option value="" disabled hidden></option>
              {field.freeEntry && <option value={"freetext2"}>
                "Edit selected option"
              </option>}
              {values.map((v, i) => (
                <option key={i} value={v}>
                  {v}
                </option>
              ))}
            </select>
          )
        ) :
        (isFreeText ? (
          <input
            style={{ width: "100%", marginTop: isComparison ? "1px" : undefined, height: isComparison ? "29px" : undefined }}
            onChange={(e) => setInputvalue(e.currentTarget.value)}
            onBlur={() => setCellData(inputvalue, rowIndex, columnIndex, field)}
            value={inputvalue}
          />
        ) : (
          <select
            style={{ width: "100%", marginTop: isComparison ? "1px" : undefined, height: isComparison ? "29px" : undefined , opacity: activeShowSingleClickEdit ? 0 : 1  }}
            onChange={(e) => handleSelectChange({ e, rowIndex, columnIndex, field, values, setCellData, getCellData })}
            value={getCellData(rowIndex, columnIndex) || ""}
          >
            <option value="" disabled hidden></option>
            {field.freeEntry && <option value={"freetext"}>
              "Create new option"
            </option>}
            {values.map((v, i) => (
              <option key={i} value={v}>
                {v}
              </option>
            ))}
          </select>
        ))
      ) : null}
    </div>
  );
}
