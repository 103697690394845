import { create } from "zustand"

interface ViewsDrawerState {
  showViewsDrawer: boolean
  loading: boolean
  isOutbreak: boolean
  setLoading: (loading: boolean) => void
  setIsOutbreak: (isOutbreak: boolean) => void
}

export const useViewsDrawerStore = create<ViewsDrawerState>((set) => ({
  showViewsDrawer: false,
  loading: false,
  isOutbreak: false,
  setLoading: (loading: boolean) => set({ loading }),
  setIsOutbreak: (isOutbreak: boolean) => set({ isOutbreak }),
}))