import './styles/App.css';
import './styles/Grid.css';
import { Dialog } from "@blueprintjs/core/lib/esm/components/dialog/dialog";
import { Spinner } from "@blueprintjs/core/lib/esm/components/spinner/spinner";
import '../node_modules/@blueprintjs/core/lib/css/blueprint.css';
import '../node_modules/@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import '../node_modules/@blueprintjs/icons/lib/css/blueprint-icons.css';
import '../node_modules/@blueprintjs/popover2/lib/css/blueprint-popover2.css';
import '../node_modules/@blueprintjs/select/lib/css/blueprint-select.css';
import './styles/phylotree.css';
import { useEffect, useRef, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useParams } from "react-router-dom";
import { useAuth } from "react-oidc-context";

import { useAppStore } from './stores/AppStore';
import { DataViewerCtrWrapper } from './components/DataViewerCtrWrapper';
import { useUserStore } from './stores/UserStore';
import { TopNavBarWrapper } from './components/TopNavBarWrapper';
import { LabAPI, OrganismAPI, OrganizationAPI, PipelineAPI } from './api/DataAPI';
import { useOrganismStore } from './stores/OrganismStore';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useOrganizationStore } from './stores/OrganizationStore';
import { AdminViewCtr } from './components/AdminViewCtr';
import { LandingPage } from './components/LandingPage';
import { LandingPageSelection } from './components/LandingPageSelection';
import { usePipelinesStore } from './stores/PipelinesStore';
import { useLabsStore } from './stores/LabsStore';
import { User } from './types/UserTypes';
import { Toaster } from './utils/Toaster';
import { NonIdealState } from '@blueprintjs/core/lib/esm/components/non-ideal-state/nonIdealState';

const queryClient = new QueryClient()


function App() {

  const { setAppLoading } = useAppStore()
  const { user, loadUser } = useUserStore()

  const { setLabs, setUserLab } = useLabsStore()
  const { setOrganizationList } = useOrganizationStore()

  const mounted = useRef(true)

  const auth = useAuth()

  const labAPI = new LabAPI(auth.user?.access_token ?? "")
  const organizationAPI = new OrganizationAPI(auth.user?.access_token ?? "")

  let savedPath = '/'
  const navData = JSON.parse(sessionStorage.getItem("navData") || "")
  if (navData !== '') {
    savedPath = navData.href.split(navData.origin)[1]
  }
  useEffect(() => {
    mounted.current = true;
    if (!user)
      onPageLoad()
    return () => {
      mounted.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onPageLoad = async () => {

    if (mounted.current) {
      try {
        if (!auth.user && !(process.env["REACT_APP_OVERRIDE_AUTH"] === "true"))
          throw new Error("No authenticated user found.")
        const user = await loadUser(auth.user?.access_token ?? "")

        await getLabs(user);

      }
      catch (e) {
        Toaster.show({ icon: "error", message: `Error loading current user: ${e}`, intent: "danger" })
      }
      try {
        await loadOrganizationList();
      }
      catch (e) {
        Toaster.show({ icon: "error", message: `Error loading organizations.`, intent: "danger" })
      }
      setAppLoading(false)
    }
  }

  const getLabs = async (user: User) => {
    const labs = await labAPI.getAll()
    setLabs(labs)
    const userLab = labs.find(v => v.labId === user?.labId)
    if (userLab)
      setUserLab(userLab)
  }

  const loadOrganizationList = async () => {
    const organizationList = await organizationAPI.getAll();
    setOrganizationList(organizationList);
  }


  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:organizationName/:organismName" element={<OrganismHome />} />
        <Route path="/:organizationName/:organismName/view/:id" element={<OrganismHome />} />
        <Route path="/oauth-callback" element={<Navigate to={savedPath} />} />
        <Route path="/" element={<LandingPage />} />
        <Route path="/:organizationName" element={<LandingPageSelection />} />
        <Route path="/admin" element={
          user ?
            <>
              {
                user.role.includes("Admin") ?
                  <AdminViewCtr authorized={true} />
                  :
                  <AdminViewCtr authorized={false} />
              }
            </>
            :
            <div style={{ display: "flex", alignItems: "center", flexDirection: "column", marginTop: 25 }}>
              <Spinner size={85}></Spinner>
              <h2>Verifying Authorization...</h2>
            </div>
        } />
      </Routes>
    </BrowserRouter>
  );
}

function OrganismHome() {
  const [error, setError] = useState("")

  const { appLoading, setAppLoading } = useAppStore()
  const { organismName, organizationName } = useParams()

  const { organism, setOrganism } = useOrganismStore()
  const { organization, setOrganization } = useOrganizationStore()
  const { pipelines, setPipelines } = usePipelinesStore()

  const auth = useAuth()
  const organismAPI = new OrganismAPI(auth.user?.access_token ?? "")
  const organizationAPI = new OrganizationAPI(auth.user?.access_token ?? "")
  const pipelineAPI = new PipelineAPI(auth.user?.access_token ?? "")

  useEffect(() => {
    getCurrentOrganism();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organismName])


  useEffect(() => {
    if (!organization)
      getCurrentOrganization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationName])

  const getCurrentOrganism = async () => {
    if (organismName) {
      setAppLoading(true)
      try {
        if (organization) {
          const organism = await organismAPI.getOrganismByName(organization.organizationName, organismName);
          setOrganism(organism)
          if (!pipelines)
            setPipelines(await pipelineAPI.getPipelines(organization.organizationName))
        }
      }
      catch (e) {
        setError(`Error loading organism: ${e}`)
        Toaster.show({ icon: "error", message: `Error loading organism: ${e}`, intent: "danger" })
      }
      setAppLoading(false)
    }
  }

  const getCurrentOrganization = async () => {
    if (organizationName) {
      setAppLoading(true)
      try {
        const organization = await organizationAPI.getOrganizationByName(organizationName);
        setOrganization(organization)
        const pipelines = await pipelineAPI.getPipelines(organization.organizationName)
        setPipelines(pipelines)
        if (organismName) {
          const organism = await organismAPI.getOrganismByName(organization.organizationName, organismName);
          setOrganism(organism)
        }
      }
      catch (e) {
        setError(`Error loading organism: ${e}`)
        Toaster.show({ icon: "error", message: `Error loading organization: ${e}`, intent: "danger" })
      }
      setAppLoading(false)
    }
  }

  const returnAppOrHandleError = () => {
    try {
      return (
        <QueryClientProvider client={queryClient}>
          <div className="grid-wrapper">
            <div className='grid-header'>
              <TopNavBarWrapper />
            </div>
            {organism && organization ? <DataViewerCtrWrapper /> : error && <div style={{ marginLeft: "40vw", marginTop: "40vh" }}>
              <NonIdealState
                icon={"error"}
                title={"There seems to be an issue..."}
                description={"There was an error loading the application."}
              />
            </div>}
          </div>
          <Dialog className='appLoading' isOpen={appLoading} canEscapeKeyClose={false} canOutsideClickClose={false} style={{ width: 300 }}>
            <div style={{ display: "flex", alignItems: "center", flexDirection: "column", marginTop: 25 }}>
              <Spinner size={85}></Spinner>
              <h2>Working on it...</h2>
            </div>
          </Dialog>
        </QueryClientProvider>
      );

    }
    catch (e: any) {
      return <div>
        <NonIdealState icon="cross-circle" title={"There was a crititcal error."} description={"Please contact an administrator and report this error."}></NonIdealState>
        <pre>{JSON.stringify(e, null, 2)}</pre>
      </div>
    }
  }

  return returnAppOrHandleError()
}

export default App;
