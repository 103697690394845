import { PreviousUpload, Upload } from "tus-js-client";
import { SelectedFile } from "../components/startPipelineDialog/StartPipelineDialog";
import { AuthContextProps } from "react-oidc-context";
import * as tus from 'tus-js-client'
import { useStartPipelineDialogStore } from "../stores/StartPipelineDialogStore";
import { error } from "console";

export interface UploadedFile {
  name: string,
  url: string
}


export const resumeUpload = (previousUploads: PreviousUpload[], currentUpload: Upload) => {
  if (previousUploads.length === 0) return

  // let text = 'You tried to upload this file previously at these times:\n\n'
  // previousUploads.forEach((previousUpload, index) => {
  //   text += `[${index}] ${previousUpload.creationTime}\n`
  // })
  // text += '\nEnter the corresponding number to resume an upload or press Cancel to start a new upload'

  // const answer = prompt(text)
  // if (answer) {
  // const index = parseInt(answer, 10)

  // if (!Number.isNaN(index) && previousUploads[index]) {
  if (previousUploads[0]) {
    currentUpload.resumeFromPreviousUpload(previousUploads[0])
  }
  // }
}

export const startUpload = (file: File, auth: AuthContextProps, labid:string) => {
  return new Promise<UploadedFile>(async (resolve, reject) => {
    // const file = input.files[0]
    // Only continue if a file has actually been selected.
    // IE will trigger a change event even if we reset the input element
    // using reset() and we do not want to blow up later.
    if (!file) {
      return
    }

    // const endpoint = endpointInput.value
    const endpoint = `${window.parsedConfig.DEX_URI}/upload`

    // let chunkSize = parseInt(chunkInput.value, 10)
    let chunkSize = Infinity
    if (Number.isNaN(chunkSize)) {
      chunkSize = Infinity
    }

    // let parallelUploads = parseInt(parallelInput.value, 10)
    let parallelUploads = 1
    if (Number.isNaN(parallelUploads)) {
      parallelUploads = 1
    }

    // toggleBtn.textContent = 'pause upload'

    // 
    const uuidv4ID = crypto.randomUUID()

    const metadata = {
      version: "2.0",
      data_stream_id: `pulsenet`,
      data_stream_route: `localsequencefile`,
      sender_id: "PulseNet-App",
      data_producer_id: labid,
      jurisdiction: labid,
      received_filename: file.name
      // meta_destination_id: "pulsenet",
      // stream: "LocalSequenceFile",
      // meta_ext_source: "IZGW",
      // meta_ext_sourceversion: "V2022-12-31",
      // meta_ext_entity: "DD2",
      // meta_username: "lwe6@cdc.gov",
      // meta_ext_objectKey: uuidv4ID,
      // meta_ext_filename: file.name,
      // meta_ext_submissionperiod: '1',
    }

    if (!auth.user && !(process.env["REACT_APP_OVERRIDE_AUTH"] === "true"))
      throw new Error("No authenticated user found.")

    const authToken = `Bearer ${auth.user?.access_token ?? ""}`
    // console.log('authToken: ', authToken)

    const options = {
      endpoint,
      headers: {
        Authorization: authToken,
      },
      // chunkSize,
      retryDelays: [0, 1000, 3000, 5000],
      parallelUploads,
      metadata: metadata,
      // metadata   : {
      //   filename: file.name,
      //   filetype: file.type,
      // },
      onError(error: any) {
        if (error.originalRequest) {
          if (window.confirm(`Failed because: ${error}\nDo you want to retry?`)) {
            upload.start()
            // uploadIsRunning = true            
            return
          }
        } else {
          // window.alert(`Failed because: ${error}`)
          throw new Error(error)
        }

        // reset(file.name)
      },
      onProgress(bytesUploaded: number, bytesTotal: number) {
        let currentFile = useStartPipelineDialogStore.getState().currentFile ?? ((() => {throw new Error("Current file does not exist")})())
        currentFile.fileProgress = bytesUploaded / bytesTotal;
        useStartPipelineDialogStore.setState({ 
          selectedFiles: useStartPipelineDialogStore.getState().selectedFiles.map(v => ((v.file?.name === file.name) ? { ...v, fileProgress: bytesUploaded / bytesTotal } : v)),
          currentFile
        })
        // setCurrentFile((currentFile) => ({ ...currentFile, fileProgress: bytesUploaded / bytesTotal } as SelectedFile))
      },
      onSuccess() {
        if (!(upload.file instanceof File))
          throw new Error("Not a file")

        // console.log(`File ${upload.file.name} uploaded successfuly at ${upload.url}`)          
        useStartPipelineDialogStore.setState({
          selectedFiles: useStartPipelineDialogStore.getState().selectedFiles.map(v => ((upload.file instanceof File && v.file?.name === upload.file.name) ? { ...v, uploaded: true } : v))
        })
        // reset(file.name)
        if (upload.url)
          resolve({
            name: upload.file.name,
            url: upload.url
          })
        else
          reject("No file url returned.")
      },
    }

    let upload = new tus.Upload(file, options)

    // const previousUploads = await upload.findPreviousUploads()
    resumeUpload([], upload)

    upload.start()
    // uploadIsRunning = true
    // setuploadIsRunning(true)
    // })
  });
}