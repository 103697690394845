import { useState, useEffect } from "react";
import { Button } from "@blueprintjs/core/lib/esm/components/button/buttons";
import { Popover2 } from "@blueprintjs/popover2/lib/esm/popover2";
import { useUserStore } from '../stores/UserStore';
import { useAuth } from "react-oidc-context";
import { LabAPI, UserAPI } from "../api/DataAPI";
import { Select2 } from "@blueprintjs/select";
import { MenuItem } from "@blueprintjs/core/lib/esm/components/menu/menuItem";
import cloneDeep from "lodash.clonedeep";
import { Toaster } from "../utils/Toaster";

export function UserProfilePopover() {
    const auth = useAuth();
    const { user, setLoggedOut } = useUserStore()
    const [labIdList, setLabIdList] = useState<string[]>([])

    const isRoleDataBaseManager = user && user.role.includes("DatabaseManager") ? true : false

    useEffect(() => {
        if (isRoleDataBaseManager) {
            const fetchLabs = async () => {
                try {
                    const labAPI = new LabAPI(auth.user?.access_token ?? "")
                    const labs = await labAPI.getAll()
                    const uniqueLabIds = Array.from(new Set(labs.map(lab => lab.labId)))
                    setLabIdList(uniqueLabIds)
                } catch (e) {
                    throw (e)
                }
            };
            fetchLabs()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRoleDataBaseManager])

    const logOutButton_onClick = () => {
        setLoggedOut(true)
        auth.removeUser();
        window.location.replace("https://auth-stg.cdc.gov/errors/globallogout.asp");
    }

    const updateUserLabId = async (selectedLabId: string) => {
        if (user) {
            try {
                const userAPI = new UserAPI(auth.user?.access_token ?? "");
                const updatedUser = cloneDeep(user)
                updatedUser.labId = selectedLabId
                await userAPI.update(user.id, updatedUser)
                window.location.reload()
            } catch (e) {
                Toaster.show({ icon: "error", message: `Failed to update the LabId`, intent: "danger" })
                throw (e)
            }
        }
    }

    return (user ? <Popover2
        content={
            <div style={{ width: 150, padding: 5 }}>
                <div>{user.name}</div>
                <div>{user.email}</div>
                <div>LabId: {isRoleDataBaseManager && labIdList.length > 0 ?
                    <Select2
                        onItemSelect={updateUserLabId}
                        items={labIdList}
                        filterable={false}
                        itemRenderer={(labId, { handleClick, handleFocus, index }) => {
                            return (
                                <MenuItem
                                    key={index}
                                    onClick={handleClick}
                                    onFocus={handleFocus}
                                    roleStructure="listoption"
                                    text={labId}
                                />
                            );
                        }}
                    >
                        <Button aria-label="open/close lab selection options" text={user.labId || "Select Lab"} rightIcon="caret-down" />
                    </Select2>
                    : user.labId}</div>
                <div>Role: {user.role}</div>
                <Button aria-label="logout of your profile" icon="log-out" onClick={logOutButton_onClick}>Log Out</Button>
            </div>
        }
        interactionKind="click"
        placement="bottom-start"
        minimal
    >
        <Button aria-label="open/close user profile menu" icon="user" minimal data-testid="userProfileButton" text={user.name} />
    </Popover2 > : null
    );
}