import { Button } from "@blueprintjs/core/lib/esm/components/button/buttons";
import { Card } from "@blueprintjs/core/lib/esm/components/card/card";
import { Popover2 } from "@blueprintjs/popover2/lib/esm/popover2";
import { useEffect, useState } from 'react';
import { useAppStore } from "../stores/AppStore";
import { useEventStore } from '../stores/EventStore';
import { useAuth } from "react-oidc-context";
import { EventAPI } from "../api/DataAPI";
import { getDateTimeString } from "../utils/getDateTimeString";
import { H5, H6 } from "@blueprintjs/core/lib/esm/components/html/html";
import { PipelineRunEvent } from "../types/EventTypes";
import { useSampleDialogStore } from "../stores/SampleDialogStore";

export function EventNotificationPopover() {
  const { setSelectedPipelineRunId, setShowPipelineRunsDialog } = useAppStore()
  const { openSampleDialog } = useSampleDialogStore()
  const { Events, setEvents } = useEventStore()
  const [isOpen, setIsOpen] = useState(false)
  const [unreadCount, setUnreadCount] = useState(Number)
  useEffect(() => {
    loadPipelineRunEvents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //setShowPipelineRunEventDialog(true);
  const auth = useAuth();
  if (!auth.user && !(process.env["REACT_APP_OVERRIDE_AUTH"] === "true"))
    throw new Error("No authenticated user found.")

  const pipelineRunEventAPI = new EventAPI(auth.user?.access_token ?? "")

  const loadPipelineRunEvents = async () => {
    const events = (await pipelineRunEventAPI.getEvents()).filter(x => x.read === false)
    const count = events.length
    setEvents(events)
    setUnreadCount(count)
  }

  const eventCard_OnClick = (event: PipelineRunEvent) => {
    setUnreadCount(unreadCount - 1)
    updateEventById(event.id)
    if (event.eventItemLinks && event.eventItemLinks[0].model === "Sample") {
      // setSelectedSampleId(event.eventItemLinks[0].id)
      // setShowSampleDialog(true, event.eventItemLinks[0].id)
      openSampleDialog(event.eventItemLinks[0].id, false, auth.user?.access_token ?? "")
      setIsOpen(false)
    }
    if (event.eventItemLinks && event.eventItemLinks[0].model === "PipelineRun") {
      setSelectedPipelineRunId(event.eventItemLinks[0].id)
      setShowPipelineRunsDialog(true)
      setIsOpen(false)
    }
  }

  const updateEvents = () => {
    setUnreadCount(0)
    pipelineRunEventAPI.updateEvents()
  }
  const updateEventById = (id: string) => {
    pipelineRunEventAPI.updateEventById(id)
    loadPipelineRunEvents()
  }
  return <>
    <Popover2
      placement="bottom-start"
      isOpen={isOpen}
      onInteraction={(nextOpenState: boolean) => { setIsOpen(nextOpenState); if (nextOpenState) { loadPipelineRunEvents() } }}
      content={
        unreadCount > 0 ?
          <div style={{ display: "flex", flexDirection: "column", gap: 5, maxWidth: 400, maxHeight: 600, overflowY: "auto", padding: 5 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <H5>Events</H5> <Button aria-label="mark all notifications as read" minimal onClick={() => { setIsOpen(false); updateEvents(); loadPipelineRunEvents() }}>Mark All as Read</Button>
            </div>
            {Events.sort((a, b) => (new Date(b.createdAt)).getTime() - (new Date(a.createdAt).getTime())).map((event, i) => <Card key={i} elevation={1} interactive onClick={() => eventCard_OnClick(event)} style={{ padding: 10 }} title="Click to open related item.">
              <H6>{event.message}</H6>
              <div>{getDateTimeString(event.createdAt)}</div>
            </Card>)}
          </div>
          : <div style={{ padding: 5 }}>No Event Notification</div>
      }
      interactionKind="click"
    >
      <Button aria-label="open/collapse events menu" icon="inbox" onClick={() => { setIsOpen(true); }} minimal >
        {unreadCount ? <div style={{
          backgroundColor: "#FA3E3E",
          color: "white",
          border: "3px #FA3E3E solid",
          borderRadius: 15,
          fontSize: 11,
          fontWeight: "bold",
          position: "relative",
          left: -10,
          top: -8,
          minWidth: 17,
          paddingRight: 2

        }}>{unreadCount}</div> : null}
      </Button>
    </Popover2>

  </>
}