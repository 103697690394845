import { create } from "zustand";

interface ViewFormState {
  addViewToFavorites: boolean,
  alert: boolean
  errorMessage: string
  formResetComplete: boolean
  loading: boolean
  isQueryBuilderRendered: boolean,
  showViewForm: boolean
  viewFormId: string
  setAddViewToFavorites: (addViewToFavorites: boolean) => void
  setAlert: (alert: boolean) => void
  setErrorMessage: (errorMessage: string) => void
  setFormResetComplete: (formResetComplete: boolean) => void
  setIsQueryBuilderRendered: (isQueryBuilderRendered: boolean) => void
  setLoading: (loading: boolean) => void
  setShowViewForm: (showViewForm: boolean) => void
  setViewFormId: (viewFormId: string) => void
}

export const useViewFormStore = create<ViewFormState>((set) => ({
  addViewToFavorites: true,
  alert: false,
  errorMessage: '',
  formResetComplete: false,
  isQueryBuilderRendered: false,
  loading: false,
  showViewForm: false,
  viewFormId: ``,
  setAddViewToFavorites: (addViewToFavorites: boolean) => set({ addViewToFavorites }),
  setAlert: (alert: boolean) => set({ alert }),
  setErrorMessage: (errorMessage: string) => set({ errorMessage }),
  setFormResetComplete: (formResetComplete: boolean) => set({ formResetComplete }),
  setIsQueryBuilderRendered: (isQueryBuilderRendered: boolean) => set({ isQueryBuilderRendered }),
  setLoading: (loading: boolean) => set({ loading }),
  setShowViewForm: (showViewForm: boolean) => set({ showViewForm }),
  setViewFormId: (viewFormId) => set({viewFormId})
}))