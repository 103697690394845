import { MultistepDialog } from '@blueprintjs/core/lib/esm/components/dialog/multistepDialog';
import { useAppStore } from '../../stores/AppStore';
import { DialogStep } from '@blueprintjs/core/lib/esm/components/dialog/dialogStep';
import { Classes } from '@blueprintjs/core/lib/esm/common';
import { ButtonProps } from '@blueprintjs/core/lib/esm/components/button/buttons';
import { ImportMetadata } from '../ImportMetadata';
import { ImportRules } from './ImportRules';
import { SelectFilesStep } from './SelectFilesStep';
import { StartPipelineStep } from './StartPipelineStep';
import { useStartPipelineDialogStore } from '../../stores/StartPipelineDialogStore';
import { useStartPipelineDialogFunctions } from '../../hooks/useStartPipelineDialogFunctions';

export type FileSource = "local" | "basespace" | "sra"

export type FileType = "paired" | "assembly" | "sanger" | "sra"

export interface SelectedFile {
  name: string,
  source: FileSource
  file?: File,
  fileProgress?: number,
  uploaded?: boolean
  remoteFileUrl?: string,
  ncbiMetadata?: {
    NCBI_ACCESSION: string,
    SRR_ID: string
  },
  sequencerrun_id?: string
  fileType: FileType
}

export const RunPipelineDialog = () => {
  const { selectedFiles, linkingComplete, showStartPipelineDialog, initialStepIndex, resetDialog, linkingFiles } = useStartPipelineDialogStore()
  const { setShowStartPipelineDialog } = useAppStore()
  const { rulesImporting } = useAppStore()
  const { handleCloseDialog } = useStartPipelineDialogFunctions()

  const finalButtonProps: Partial<ButtonProps> = {
    intent: "primary",
    onClick: handleCloseDialog,
    text: "Close",
  };
  const invalidFileCount = selectedFiles.length === 0 || selectedFiles.filter(file => file.fileType === "paired").length % 2 !== 0


  return <MultistepDialog
    icon="link"
    navigationPosition={"top"}
    onClose={handleCloseDialog}
    finalButtonProps={finalButtonProps}
    title="Link Files"
    isOpen={showStartPipelineDialog}
    canOutsideClickClose={false}
    initialStepIndex={initialStepIndex}
    backButtonProps={{
      onClick: () => {
        resetDialog()
        setTimeout(() => setShowStartPipelineDialog(true), 1)
      },
      text: "Reset",
      disabled: linkingFiles
    }}
  >
    <DialogStep
      id="fileSource"
      panel={<div className={Classes.DIALOG_BODY}>
        <SelectFilesStep />
      </div>}
      title="Select Files"
      nextButtonProps={{
        disabled: !selectedFiles.length || invalidFileCount,
        tooltipContent: selectedFiles.length ? invalidFileCount ? "Unpaired file found. Please select 2 paired read files for each sample." : undefined : "Select a file source and files to proceed.",
      }}
    />
    <DialogStep
      id="importRules"
      panel={<div className={Classes.DIALOG_BODY}>
        <ImportRules />
      </div>}
      title="Import Rules"
      nextButtonProps={{
        disabled: !rulesImporting,
        tooltipContent: rulesImporting ? undefined : "Please fill out the Key field with valid data to proceed. And if the SEQUENCERRUN_ID field is filled out, valid data is required.",
      }}
    />
    <DialogStep
      id="files"
      panel={<div className={Classes.DIALOG_BODY}>
        <StartPipelineStep />
      </div>}
      title="Link Files"
      nextButtonProps={{
        disabled: !linkingComplete,
        tooltipContent: linkingComplete ? undefined : "Please link files to proceed",
      }}
    />
    <DialogStep
      id="metadata"
      panel={<div className={Classes.DIALOG_BODY}>
        <ImportMetadata />
      </div>}
      title="Import Metadata"
    />
  </MultistepDialog>
}