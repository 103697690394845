import { create } from 'zustand'
import { ComparedSample } from '../types/ComparisonTypes';
import { View } from '../types/ViewTypes';
import { useViewFormStore } from './ViewFormStore';
import { useViewsDrawerStore } from './ViewsDrawerStore';
import { useComparisopnDrawerStore } from './ComparisonsDrawerStore';
import { useComparisonFormStore } from './ComparisonFormStore';
import { useImportMetadataDialogStore } from './ImportMetadataDialogStore';
import { useStartPipelineDialogStore } from './StartPipelineDialogStore';
import { usePipelineRunsDrawerStore } from './PipelineRunsDrawerStore';
import { usePipelineRunsDialogStore } from './PipelineRunsDialogStore';
import { useOpenComparisonDrawerStore } from './OpenComparisonDrawerStore';

export interface AppState {
  appLoading: boolean;
  selectedViewId?: string;
  temporaryViewId?: string;
  selectedComparisonId?: string;
  selectedPipelineRunId?: string;
  rulesImporting: boolean;
  selectedSamples: Array<ComparedSample> | undefined;
  activeDialog: string;
  selectedView: View | undefined;
  isVisuallyClosed: boolean;
  showProfileMatchingDialog: boolean;
  isOutbreakView: boolean;
  setAppLoading: (appLoading: boolean) => void
  // setShowSampleDialog: (showSampleDialog: boolean, selectedSampleId?: string | undefined) => void
  // setSelectedSampleId: (selectedSampleId?: string) => void
  setSelectedViewId: (selectedViewId?: string) => void
  setSelectedComparisonId: (selectedComparisonId?: string) => void
  setShowViewsDrawer: (showViewsDrawer: boolean) => void
  setShowComparisonForm: (showComparisonForm: boolean) => void
  setShowComparisonDrawer: (setShowComparisonDrawer: boolean) => void
  setShowStartPipelineDialog: (filesUploading: boolean) => void
  setShowStartCalcPipelineDialog: (filesUploading: boolean) => void
  setShowPipelineRunsDrawer: (showPipelineRunsDrawer: boolean) => void
  setShowPipelineRunsDialog: (showPipelineRunsDialog: boolean) => void
  setSelectedPipelineRunId: (selectedPipelineRunId?: string) => void
  resetMenusDefault: () => void
  setRulesImporting: (rulesImporting: boolean) => void
  setSelectedSamples: (selectedSamples: Array<ComparedSample> | undefined) => void
  setActiveDialog: (activeDialog: string) => void
  setSelectedView: (selectedView: View | undefined) => void
  setTemporaryViewId: (temporaryViewId: string | undefined) => void
  setIsVisuallyClosed: (isVisuallyClosed: boolean) => void
  setShowProfileMatchingDialog: (showProfileMatchingDialog: boolean) => void
  setIsOutbreakView: (isOutbreakView: boolean) => void
}

export const useAppStore = create<AppState>((set) => ({
  appLoading: true,
  selectedSampleId: undefined,
  selectedViewId: undefined,
  temporaryViewIds: undefined,
  selectedComparisonId: undefined,
  selectedPipelineRunId: undefined,
  rulesImporting: false,
  selectedSamples: undefined,
  activeDialog: "",
  selectedView: undefined,
  showOpenComparisonDrawer: false,
  isVisuallyClosed: false,
  showProfileMatchingDialog: false,
  isOutbreakView: false,
  setAppLoading: (appLoading: boolean) => set({ appLoading }),
  // setShowSampleDialog: (showSampleDialog: boolean, selectedSampleId?: string) => 
  //   set(() => ({ 
  //     showSampleDialog, 
  //     selectedSampleId: selectedSampleId ?? undefined 
  //   })),
  // setSelectedSampleId: (selectedSampleId) => set(() => ({ selectedSampleId })),
  setSelectedViewId: (selectedViewId) => set({ selectedViewId, selectedComparisonId: undefined }),
  setSelectedComparisonId: (selectedComparisonId?: string) => set({ selectedComparisonId, selectedViewId: undefined }),
  setShowViewsDrawer: (showViewsDrawer: boolean) => {
    useComparisopnDrawerStore.setState({ showComparisonDrawer: false })
    useViewsDrawerStore.setState({ showViewsDrawer })
  },
  setShowComparisonForm: (showComparisonForm: boolean) => {
    useViewFormStore.setState({ showViewForm: false })
    useComparisonFormStore.setState({ showComparisonForm })
  },
  setShowComparisonDrawer: (showComparisonDrawer: boolean) => {
    useViewsDrawerStore.setState({ showViewsDrawer: false })
    useComparisopnDrawerStore.setState({ showComparisonDrawer })
  },
  setShowStartPipelineDialog: (showStartPipelineDialog: boolean) => {
    useStartPipelineDialogStore.setState({ showStartPipelineDialog })
    if (showStartPipelineDialog)
      set({ activeDialog: "RunPipelineDialog" })
  },
  setShowStartCalcPipelineDialog: (showStartCalcPipelineDialog: boolean) => {
    useStartPipelineDialogStore.setState({ showStartCalcPipelineDialog })
    if (showStartCalcPipelineDialog)
      set({ activeDialog: "RunPipelineDialog" })
  },
  setShowPipelineRunsDrawer: (showPipelineRunsDrawer: boolean) => {
    useComparisopnDrawerStore.setState({ showComparisonDrawer: false })
    usePipelineRunsDrawerStore.setState({ showPipelineRunsDrawer: showPipelineRunsDrawer })
  },
  resetMenusDefault: () => {
    useViewFormStore.setState({ showViewForm: false })
    useViewsDrawerStore.setState({ showViewsDrawer: false })
    useComparisopnDrawerStore.setState({ showComparisonDrawer: false })
    useComparisonFormStore.setState({ showComparisonForm: false })
    useImportMetadataDialogStore.setState({ showImportMetadataDialog: false })
    usePipelineRunsDrawerStore.setState({ showPipelineRunsDrawer: false })
    useOpenComparisonDrawerStore.setState({ showOpenComparisonDrawer: false })
    set({
      isVisuallyClosed: false,
    })
  },
  setShowPipelineRunsDialog: (showPipelineRunsDialog: boolean) => {
    usePipelineRunsDialogStore.setState({ showPipelineRunsDialog })
    set({ activeDialog: "PipelineRunsDialog" })
  },
  setSelectedPipelineRunId: (selectedPipelineRunId) => set({ selectedPipelineRunId }),
  setRulesImporting: (rulesImporting: boolean) => set({ rulesImporting }),
  setSelectedSamples: (selectedSamples: Array<ComparedSample> | undefined) => set({ selectedSamples }),
  setActiveDialog: (activeDialog: string) => set({ activeDialog }),
  setSelectedView: (selectedView: View | undefined) => set({ selectedView }),
  setTemporaryViewId: (temporaryViewId: string | undefined) => set({ temporaryViewId }),
  setIsVisuallyClosed: (isVisuallyClosed: boolean) => set({ isVisuallyClosed }),
  setShowProfileMatchingDialog: (showProfileMatchingDialog: boolean) => set({ showProfileMatchingDialog }),
  setIsOutbreakView: (isOutbreakView: boolean) => set({ isOutbreakView }),
}))