import React, { HTMLProps, useEffect, useRef } from 'react'

import '../styles/pnDataTable.css'

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  Row,
  useReactTable,
  ColumnResizeMode,
  ColumnResizeDirection
} from '@tanstack/react-table'
import { userFields , labFields } from '../fields'
import { useVirtualizer, VirtualItem } from '@tanstack/react-virtual'
import { selectedIDsShiftKey } from './OpenComparisonView'
import { SampleMetaDataDetailListItem } from '../types/SampleTypes'
import { useAdminStore } from '../stores/AdminStore'
import { useOrganismStore } from '../stores/OrganismStore'
import { Button } from "@blueprintjs/core/lib/esm/components/button/buttons";
import { ButtonGroup } from "@blueprintjs/core/lib/esm/components/button/buttonGroup";
import { Divider } from "@blueprintjs/core"
import { Fields } from '../types/OrganismTypes'

interface TableDataItem {
  id: string
}

interface props {
  data: TableDataItem[]
  columnNames: string[]
  tableId?: string
  rowSelection?: { [key: string]: boolean }
  setRowSelection?: any
  focusRowIndex?: number
  setFocusRowIndex?: React.Dispatch<React.SetStateAction<number>>
  onScroll?: (event: React.UIEvent<HTMLDivElement, UIEvent>) => void
  className?: string | undefined
  enableSort?: boolean
  setShiftIds?: React.Dispatch<React.SetStateAction<selectedIDsShiftKey>>
  overrideColumns?: ColumnDef<SampleMetaDataDetailListItem, any>[]
  adminSelectorColumn? : boolean
  type?: string
}

export function PNDataTable({ data, columnNames, rowSelection, setRowSelection, focusRowIndex, setFocusRowIndex, onScroll, className, enableSort, tableId, setShiftIds, overrideColumns, adminSelectorColumn, type }: props) {
  const isShiftPressedRef = useRef<boolean>(false);
  const lastSelectedIndex = useRef<number>(-1);
  const { organism } = useOrganismStore()
  const {setAdminItem , setShowAdminDialogue} = useAdminStore()
  let entity : Fields
  if(adminSelectorColumn){
    if(type === 'users'){
      entity = userFields
    }
    if(type === 'labs'){
      entity = labFields
    }
  }
  const columns = React.useMemo<ColumnDef<TableDataItem>[]>(
    () => {
      if (!organism && !adminSelectorColumn)
        throw(new Error("Organism is not defined."))
      const dataCols = columnNames.reduce((pv, name) => {
        const field = (adminSelectorColumn? entity.properties[name] : organism?.properties[name]) ?? {
          path: name,
          type: "string",
          title: name
        }
        if (field && field.path) {
          return [...pv, {
            id: field.path,
            accessorFn: (row: any) => row[field.path!],
            cell: (info) => {
              let fieldValue: any = info.getValue()
              if(typeof info.getValue() === 'object' && info.getValue()){
                //for the role field, field.getValue will return an array so this if-statement is for joining that array into a legible string
                fieldValue = fieldValue.join(', ')
              }
              return <span title={typeof (info.getValue()) === 'string' ? (info.getValue() as string) : ""}>{fieldValue ?? ""}</span>},
            footer: props => props.column.id,
            header: () => <span>{field.title ?? name}</span>,
            minSize: 100
          }]
        }
        return pv
      }, [] as ColumnDef<TableDataItem>[])

      if(adminSelectorColumn){
        return [{
          id: 'select',
          header: '',
          cell: ({row}) => {
            return <ButtonGroup minimal={true}>
              <Button text='Edit' onClick={() => {
                setAdminItem(row.original)
                setShowAdminDialogue(true)
              }} />
              <Divider />
              </ButtonGroup>
          },
          size: 75
        }, ...dataCols]
      }
      
      return (rowSelection && setRowSelection) ? [
        {
          id: 'select',
          header: ({ table }) => (
            <IndeterminateCheckbox
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
              }}
            />
          ),
          cell: ({ row }) => (
            <div className="px-1">
              <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
              />
            </div>
          ),
          size: 25
        },
        ...dataCols] : dataCols
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnNames, rowSelection, setRowSelection]
  )

  const columnResizeMode: ColumnResizeMode = 'onChange'
  const columnResizeDirection: ColumnResizeDirection = 'ltr'

  let tableColumns: any = columns
  if (overrideColumns) {
    tableColumns = overrideColumns
  }
  const table = useReactTable({
    columnResizeMode,
    columnResizeDirection,
    data,
    columns: tableColumns,
    state: {
      rowSelection,
    },
    enableRowSelection: true, //enable row selection for all rows    
    onRowSelectionChange: (value) => { setRowSelection && setRowSelection(value) },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  })
  const { rows } = table.getRowModel()

  const visibleColumns = table.getVisibleLeafColumns()

  //The virtualizers need to know the scrollable container element and add eventListener
  const tableContainerRef = React.useRef<HTMLDivElement>(null)

  //we are using a slightly different virtualization strategy for columns (compared to virtual rows) in order to support dynamic row heights
  const columnVirtualizer = useVirtualizer({
    count: visibleColumns.length,
    estimateSize: index => visibleColumns[index].getSize(), //estimate width of each column for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    horizontal: true,
    overscan: 3, //how many columns to render on each side off screen each way (adjust this for performance)
  })

  //dynamic row height virtualization - alternatively you could use a simpler fixed row height strategy without the need for `measureElement`
  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 33, //estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    //measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== 'undefined' &&
        navigator.userAgent.indexOf('Firefox') === -1
        ? element => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  })

  const virtualColumns = columnVirtualizer.getVirtualItems()
  const virtualRows = rowVirtualizer.getVirtualItems()

  //different virtualization strategy for columns - instead of absolute and translateY, we add empty columns to the left and right
  let virtualPaddingLeft: number | undefined
  let virtualPaddingRight: number | undefined

  if (columnVirtualizer && virtualColumns?.length) {
    virtualPaddingLeft = virtualColumns[0]?.start ?? 0
    virtualPaddingRight =
      columnVirtualizer.getTotalSize() -
      (virtualColumns[virtualColumns.length - 1]?.end ?? 0)
  }

  const onClick_tbodyRow = (row: Row<SampleMetaDataDetailListItem>): void => {
    let isAllChecked = true
    if (isShiftPressedRef.current) {
      //select All between last selected index and current selected index
      const startIndex = lastSelectedIndex.current !== -1 ? Math.min(lastSelectedIndex.current, row.index) : row.index
      const endIndex = lastSelectedIndex.current !== -1 ? Math.max(lastSelectedIndex.current, row.index) : row.index

      // If all items within the range are already selected, deselect them

      // if last selected item was checked, then it won't be 'deselected'
      if (rows.filter(i => i.index === lastSelectedIndex.current)?.[0]?.getIsSelected() === true) isAllChecked = false
      // if at least one item in the range is checked, then it won't be 'deselected'
      if (isAllChecked) {
        for (let i = startIndex; i <= endIndex; i++) {
          if (i !== lastSelectedIndex.current) {
            if (rows.filter(row => row.index === i)?.[0]?.getIsSelected() === false) isAllChecked = false
          }
        }
      }

      // Update selected items within the range
      let shiftIdList: string[] = []
      for (let i = startIndex; i <= endIndex; i++) {
        //false : deselect
        //true : select
        if (setRowSelection) {
          rows.filter(row => row.index === i)?.[0]?.toggleSelected(isAllChecked ? false : true)
          const id = rows.filter(row => row.index === i)?.[0]?.original?.id
          if (id) {
            shiftIdList.push(id)
          }
        }
      }
      if (setShiftIds)
        setShiftIds({ shouldDeselect: isAllChecked, id_list: shiftIdList })
    } else {
      isAllChecked = false
      if (setRowSelection)
        row.toggleSelected(!row.getIsSelected())
    }

    if (setFocusRowIndex)
      setFocusRowIndex(row.index)

    if (isAllChecked) {
      // After deselect all items within the range, reset lastSelectedIndex 
      lastSelectedIndex.current = -1
    } else {
      lastSelectedIndex.current = row.index
    }
  }

  const onClick_SelectAll = (vc: VirtualItem): void => {
    if (vc.index === 0) {
      lastSelectedIndex.current = -1
      if (setShiftIds)
        setShiftIds({ shouldDeselect: false, id_list: [] })
    }
  }

  useEffect(() => {
    if (tableContainerRef.current) {
      const table = document.getElementsByClassName('comparisonView')[0] as HTMLElement;
      if (table) {
        const handleKeyUp = (event: KeyboardEvent) => {
          if (event.key === 'Shift') {
            isShiftPressedRef.current = false;
            tableContainerRef.current?.focus()
          }
        };

        const handleKeyDown = async (event: KeyboardEvent) => {
          if (event.key === 'Shift') {
            isShiftPressedRef.current = true;
            tableContainerRef.current?.focus()
          }
        }

        table.addEventListener('keydown', handleKeyDown);
        table.addEventListener('keyup', handleKeyUp);

        return () => {
          table.removeEventListener('keydown', handleKeyDown);
          table.removeEventListener('keyup', handleKeyUp);
        };
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={className ?? 'pn-datatable'} onScroll={onScroll}
      ref={tableContainerRef}
      tabIndex={0}
      style={{
        height: "100%",
        width: "100%",
        overflow: 'auto', //our scrollable table container           
        background: 'white'
      }}
    >
      <table id={tableId} className="datatable-table" style={{
        position: 'relative', //needed for sticky header
        borderCollapse: 'collapse',
        display: 'grid'
      }}
      >
        <thead
          style={{
            display: 'grid',
            position: 'sticky',
            top: 0,
            zIndex: 1,
            backgroundColor: '#f2f2f2', /* Set a background color for the sticky header */
            borderBottom: ""
          }}
        >
          {table.getHeaderGroups().map(headerGroup => (
            <tr
              key={headerGroup.id}
              style={{ display: 'flex', width: '100%' }}
            >
              {virtualPaddingLeft ? (
                //fake empty column to the left for virtualization scroll padding
                <th style={{ display: 'flex', width: virtualPaddingLeft }}><span style={{ display: "none" }}>-</span></th>
              ) : null}
              {virtualColumns.map(vc => {
                const header = headerGroup.headers[vc.index]
                return (
                  <th
                    key={header.id}
                    style={{
                      display: 'flex',
                      width: header.getSize(),
                    }}
                    onClick={() => onClick_SelectAll(vc)}
                  >
                    <div
                      {...(enableSort && header.column.getCanSort() ? {
                        className: 'can-sort select-none',
                        onClick: header.column.getToggleSortingHandler(),
                      } : {})}
                      style={{
                        width: "100%",
                        // cursor: "pointer"
                      }}
                    // className='noselect'
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {{
                        asc: ' 🔼',
                        desc: ' 🔽',
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                    <div
                      {...{

                        onDoubleClick: () => header.column.resetSize(),
                        onMouseDown: header.getResizeHandler(),
                        onTouchStart: header.getResizeHandler(),
                        className: `resizer ${table.options.columnResizeDirection
                          } ${header.column.getIsResizing() ? 'isResizing' : ''
                          }`,
                        style: {
                          cursor: "col-resize",
                          transform: '',
                        },
                      }}

                    >{header.id !== 'select' ? "|" : ""}</ div>
                  </th>
                )
              })}
              {virtualPaddingRight ? (
                //fake empty column to the right for virtualization scroll padding
                <th style={{ display: 'flex', width: virtualPaddingRight }}><span style={{ display: "none" }}>-</span></th>
              ) : null}
            </tr>
          ))}
        </thead>
        {/* <tbody>
          {table.getRowModel().rows.map(row => {
            return (
              <tr key={row.id} className={row.index === focusRowIndex ? "pn-datatable-row-selected" : ""} 
                onClick={() => onClick_tbodyRow(row)}>
                {row.getVisibleCells().map(cell => {
                  return (
                    <td key={cell.id}>                      
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody> */}
        <tbody
          style={{
            display: 'grid',
            height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
            position: 'relative', //needed for absolute positioning of rows
          }}
        >
          {virtualRows.map(virtualRow => {
            const row = rows[virtualRow.index] as Row<SampleMetaDataDetailListItem>
            const visibleCells = row.getVisibleCells()

            return (
              <tr
                data-index={virtualRow.index} //needed for dynamic row height measurement
                ref={node => rowVirtualizer.measureElement(node)} //measure dynamic row height
                key={row.id}
                style={{
                  display: 'flex',
                  position: 'absolute',
                  transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                  width: '100%',
                  backgroundColor: row.index%2 === 0 ? 'lightblue': '#f1f6f9',
                  alignItems: 'center'
                }}
                className={row.index === focusRowIndex ? "pn-datatable-row-selected" : ""}
                onClick={() => onClick_tbodyRow(row)}
              >
                {virtualPaddingLeft ? (
                  //fake empty column to the left for virtualization scroll padding
                  <td
                    style={{ display: 'flex', width: virtualPaddingLeft }}
                  />
                ) : null}
                {virtualColumns.map(vc => {
                  const cell = visibleCells[vc.index]
                  return (
                    <td
                      key={cell.id}
                      style={{
                        display: 'flex',
                        width: cell.column.getSize(),
                      }}
                    ><span style={{overflowX:"hidden", textOverflow:"ellipsis", whiteSpace:"nowrap"}}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </span>
                    </td>
                  )
                })}
                {virtualPaddingRight ? (
                  //fake empty column to the right for virtualization scroll padding
                  <td
                    style={{ display: 'flex', width: virtualPaddingRight }}
                  />
                ) : null}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

//**TO BE IMPLEMENTED
// function Filter({
//   column,
//   table,
// }: {
//   column: Column<any, any>
//   table: Table<any>
// }) {
//   const firstValue = table
//     .getPreFilteredRowModel()
//     .flatRows[0]?.getValue(column.id)

//   return typeof firstValue === 'number' ? (
//     <div className="flex space-x-2">
//       <input
//         type="number"
//         value={((column.getFilterValue() as any)?.[0] ?? '') as string}
//         onChange={e =>
//           column.setFilterValue((old: any) => [e.target.value, old?.[1]])
//         }
//         placeholder={`Min`}
//         className="w-24 border shadow rounded"
//       />
//       <input
//         type="number"
//         value={((column.getFilterValue() as any)?.[1] ?? '') as string}
//         onChange={e =>
//           column.setFilterValue((old: any) => [old?.[0], e.target.value])
//         }
//         placeholder={`Max`}
//         className="w-24 border shadow rounded"
//       />
//     </div>
//   ) : (
//     <input
//       type="text"
//       value={(column.getFilterValue() ?? '') as string}
//       onChange={e => column.setFilterValue(e.target.value)}
//       placeholder={`Search...`}
//       className="w-36 border shadow rounded"
//     />
//   )
// }

function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!)

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, indeterminate])

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + ' cursor-pointer'}
      {...rest}
    />
  )
}
