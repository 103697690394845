import { create } from 'zustand';

interface TableItem {
    id: string
    name?: string
}

interface AdminState {
  adminItem?: TableItem | null
  showAdminDialogue: boolean
  setAdminItem: (adminItem: TableItem | null) => void
  setShowAdminDialogue: (showAdminDialogue: boolean) => void
}

export const useAdminStore = create<AdminState>((set) => ({
    showAdminDialogue: false,
    setAdminItem: (adminItem: TableItem | null) => set({adminItem}),
    setShowAdminDialogue: (showAdminDialogue: boolean) => set({showAdminDialogue})
}));