import { FieldRef, ICellLookup, ICellSetter, copiedCellType } from '../components/EditDataViewer';

interface handleInteraction {
  values:string[]
  getCellData: ICellLookup
  rowIndex: number
  columnIndex: number
  copiedCells: copiedCellType | null
  resetBorders: (leftmostIndex:number, rightmostIndex:number, topmostIndex:number, bottommostIndex:number, activeIndex:number) => void
  activeIndex: number
}

interface handleSelectChange {
  e: any, 
  rowIndex: number, 
  columnIndex: number,
  field: FieldRef, 
  values:string[], 
  setCellData: ICellSetter, 
  getCellData: ICellLookup
}

interface onBlurCapture {
  setCellData: ICellSetter, 
  rowIndex: number, 
  columnIndex: number,
  field: FieldRef
}

interface EditDataViewSelectFunctions {
  handleInteraction: ({values, getCellData, rowIndex, columnIndex, copiedCells, resetBorders, activeIndex}:handleInteraction) => void
  handleSelectChange: ({e, rowIndex, columnIndex, field, values, setCellData, getCellData} : handleSelectChange) => void
  onBlurCapture: ({setCellData, rowIndex, columnIndex, field} : onBlurCapture) => void
  handleClicked: (e: any) => void
}

export const useEditDataViewSelectFunctions = (
  setShowSelect:(showSelect:boolean) => void,
  setAlreadyFreeTextSelected:(alreadyFreeTextSelectedisFreeText:boolean) => void,
  isFreeText: boolean,
  setIsFreeText:(isFreeText:boolean) => void,
  inputvalue: any,
  setInputvalue:(inputvalue:any) => void
): EditDataViewSelectFunctions => {
  const handleInteraction = ({values, getCellData, rowIndex, columnIndex, copiedCells, resetBorders, activeIndex} : handleInteraction) => {
    if (!values.includes(getCellData(rowIndex, columnIndex)) && getCellData(rowIndex, columnIndex) !== '') {
      setAlreadyFreeTextSelected(true);
    }
    setShowSelect(true);
    if (copiedCells) {
      resetBorders(copiedCells.leftmostIndex, copiedCells.rightmostIndex, copiedCells.topmostIndex, copiedCells.bottommostIndex, activeIndex);
    }
  }

  const handleSelectChange = ({e, rowIndex, columnIndex, field, values, setCellData, getCellData} : handleSelectChange) => {
    if (e.currentTarget.value === "freetext") {
      setCellData("", rowIndex, columnIndex, field);
      setInputvalue("")
      setIsFreeText(true);
    } else if (e.currentTarget.value === "freetext2") {
      if(values.includes(getCellData(rowIndex, columnIndex))){
        setCellData("", rowIndex, columnIndex, field);
      }
      e.currentTarget.value = ""
      setIsFreeText(true);
    } else {
      setCellData(e.currentTarget.value, rowIndex, columnIndex, field);
    }
  }

  const onBlurCapture = ({setCellData, rowIndex, columnIndex, field} : onBlurCapture) => {
    if(isFreeText){
      setCellData(inputvalue, rowIndex, columnIndex, field)
    }
    setShowSelect(false); 
    setIsFreeText(false); 
    setAlreadyFreeTextSelected(false);
  };

  const handleClicked = (e:any) => {
    if(e && e.currentTarget.value === "freetext2"){
      e.currentTarget.value = ""
    }
  };
  
  return {
    handleInteraction,
    handleSelectChange,
    onBlurCapture,
    handleClicked
  };
}