import { Button } from "@blueprintjs/core/lib/esm/components/button/buttons";
import { Dialog } from "@blueprintjs/core/lib/esm/components/dialog/dialog";
import { Card } from "@blueprintjs/core/lib/esm/components/card/card";
import { useEffect, useState } from 'react';
import "../styles/FormStyle.css"
import "../styles/FormInput.css"
import { PipelineRun } from "../types/PipelineRunTypes";
import { useAppStore } from '../stores/AppStore';
import { useAuth } from "react-oidc-context";
import { Spinner } from "@blueprintjs/core/lib/esm/components/spinner/spinner";
import { Weblog } from "../types/WeblogTypes";
import { getDateTimeString } from "../utils/getDateTimeString";
import { H4, H5,  Pre } from "@blueprintjs/core/lib/esm/components/html/html";
import { Icon } from "@blueprintjs/core/lib/esm/components/icon/icon";
import { Collapse } from "@blueprintjs/core/lib/esm/components/collapse/collapse";
import { useUserStore } from "../stores/UserStore";
import { useSampleDialogStore } from "../stores/SampleDialogStore";
import { usePipelineRunsDialogStore } from "../stores/PipelineRunsDialogStore";
import { usePipelineRunsDialogFunctions } from "../hooks/usePipelineRunsDialogFunctions";
import { PipelineStatusIcon } from "./PipelineStatusIcon";
import { usePipelinesStore } from "../stores/PipelinesStore";
import { Popover2 } from "@blueprintjs/popover2";

interface AggregatedWeblogSample {
  identifier: string
  updated: string
  currentProcess: string
  currentProcessStatus: string
  processes: AggregatedWeblogProcess[]
}
interface AggregatedWeblogProcess {
  name: string
  status: string
  updated: string
  processLog: AggregatedWeblogProcessLog[]
}

interface AggregatedWeblogProcessLog {
  event: string
  datetime: string
}


export function PipelineRunsDialog() {
  const {
    setShowPipelineRunsDialog,
    setShowPipelineRunsDrawer,
    activeDialog
  } = useAppStore();
  const {
    showPipelineRunsDialog,
    loading,
    status,
    pipelineRun,
    sampleKeyIdMap,
    showErrorDialog,
    setShowErrorDialog,
    errorMessage } = usePipelineRunsDialogStore()
  const { openSampleDialog } = useSampleDialogStore()
  const { user } = useUserStore()
  const auth = useAuth();
  const { loadWeblog, restartPipelineRun, cancelPipelineRun, getWeblog, loadSampleData } = usePipelineRunsDialogFunctions()
  const [collapsedSamples, setCollapsedSamples] = useState<CollapsedSamples>({})
  const { pipelines } = usePipelinesStore()

  if (!auth.user && !(process.env["REACT_APP_OVERRIDE_AUTH"] === "true"))
    throw new Error("No authenticated user found.")

  useEffect(() => {
    let interval: any
    if (pipelineRun && !["completed", "Completed", "Completed with Errors"].includes(pipelineRun.status)) {
      interval = setInterval(() => {
        loadWeblog(pipelineRun.id)
        loadSampleData(pipelineRun)
      }, 10000);
    }
    else
      clearInterval(interval)

    if (!showPipelineRunsDialog)
      clearInterval(interval)

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pipelineRun, showPipelineRunsDialog]);

  const onclick_openSampleDialog = (id?: string) => {
    if (id) {
      setShowPipelineRunsDialog(false)
      openSampleDialog(id, false, auth.user?.access_token ?? "")
    }
  }

  const getSampleByKey = (sampleKey: string) => sampleKeyIdMap.find(v2 => v2.identifier === sampleKey)?.sample

  const getSampleKeyFromScript = (script: string) => {
    let match = script.match(/--sample-id\s+([\w\\-]+)/)
    return match ? match[1] : ""
  }

  const getFlatWeblog = () => {
    const mappedWeblog = (getWeblog ?? []).map(v => ({
      sample: v.trace ? getSampleKeyFromScript(v.trace.script) : "",
      process: v.trace ? v.trace.process : "Nextflow Head",
      event: v.event,
      datetime: getDateTimeString(v.utcTime),
      errorMessage: v.metadata?.workflow?.errorReport,
      status: v.trace ? v.trace.status : null
    }))
    return mappedWeblog
  }

  const getFormattedWeblog = () => {
    return getFlatWeblog().reduce((pv, cv) => {
      if (cv.sample) {
        let sample = pv.filter(v => v.identifier === cv.sample)[0]
        if (sample) {//update sample
          sample.currentProcess = cv.process
          sample.currentProcessStatus = cv.status
          sample.updated = cv.datetime
          let process = sample.processes.filter(v => v.name === cv.process)[0]
          if (process) {//update process and add log
            process.updated = cv.datetime
            process.status = cv.status
            process.processLog.push({
              event: cv.status,
              datetime: cv.datetime
            })
          }
          else {//Add new process
            sample.processes.push({
              name: cv.process,
              status: cv.status,
              updated: cv.datetime,
              processLog: [{
                event: cv.status,
                datetime: cv.datetime
              }]
            })
          }
        }
        else {
          pv.push({//Add sample
            identifier: cv.sample,
            updated: cv.datetime,
            currentProcess: cv.process,
            currentProcessStatus: cv.status,
            processes: [
              {
                name: cv.process,
                status: cv.status,
                updated: cv.datetime,
                processLog: [{
                  event: cv.status,
                  datetime: cv.datetime
                }]
              }
            ]
          } as AggregatedWeblogSample)
        }
      }
      else {
        pv = pv.filter(v => v.identifier !== cv.process)
        pv.push({//Add sample
          identifier: cv.process,
          updated: cv.datetime,
          currentProcess: cv.process,
          currentProcessStatus: cv.status,
          processes: [
            {
              name: cv.process,
              status: cv.status,
              updated: cv.datetime,
              processLog: [{
                event: cv.status,
                datetime: cv.datetime
              }]
            }
          ]
        } as AggregatedWeblogSample)
      }
      return pv
    }, [] as AggregatedWeblogSample[])
  }

  const getSampleProcesses = (identfier: string) => getFormattedWeblog().find(sample => sample.identifier === identfier)

  const isError = () => {
    return getFlatWeblog().filter(v => ["completed"].includes(v.event))[0]?.errorMessage
  }

  const SampleCard_OnClick = (key: string) => {
    setCollapsedSamples({
      ...collapsedSamples,
      [key]: !collapsedSamples[key]
    })
  }

  const isNCBIPipeline = () => {
    const pipeline = pipelines?.find(v => v.id === pipelineRun?.pipelineId)
    return !!(pipeline?.statusField && pipeline?.statusField === "ncbiPipelineStatus")
  }

  const getPipelineStatus = (sampleKey: string) => {

    if (!getSampleByKey(sampleKey))
      return ""

    if (isNCBIPipeline()) {
      if (getSampleByKey(sampleKey)!.otherPipelineRunStatuses?.ncbiPipelineStatus)
        return getSampleByKey(sampleKey)!.otherPipelineRunStatuses?.ncbiPipelineStatus ?? ""
      else
        return ""
    }
    else
      return getSampleByKey(sampleKey)!.pipelineRunStatus
  }


  return <>
    {showPipelineRunsDialog ? <Dialog
      isOpen={showPipelineRunsDialog}
      title={`Pipeline Run Details`}
      canOutsideClickClose={false}
      onClose={() => setShowPipelineRunsDialog(false)}
      style={{ paddingBottom: "0px", width: "auto", zIndex: activeDialog === "PipelineRunsDialog" ? 21 : 20 }}
    >
      <div className="dialog-body2">
        {loading ? <Spinner></Spinner> :
          <form>
            <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
              <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
                <Card className='form-body' style={{ padding: 5, overflowY: "auto", width: "50%", maxHeight: "500px", margin: 5 }}>
                  {[
                    { label: 'Pipeline Run ID', value: pipelineRun?.id },
                    { label: 'Pipeline Name', value: pipelineRun?.pipelineRunName },
                    { label: 'Pipeline Version', value: pipelineRun?.pipelineVersion },
                    { label: 'Created By', value: pipelineRun?.createdBy },
                  ].map(({ label, value }, i) => (
                    <div className='input-ctr form-row2' key={i}>
                      <div className='form-label2' style={{ fontWeight: 600 }}>
                        <label>{label}</label>
                      </div>
                      <div className='form-value2'>
                        {Array.isArray(value) ? <ul>{value}</ul> : <p>{value}</p>}
                      </div>
                    </div>
                  ))}
                </Card>
                <div style={{ overflowY: "auto", width: "50%", maxHeight: "500px" }}>
                  <PipelineTracker {...{ weblog: getWeblog ?? [], pipelineRun }}></PipelineTracker>
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "column", gap: 5, height: 250, minHeight: 0, overflowY: "auto", padding: 5 }}>
                {pipelineRun?.sampleKeys?.map(sampleKey => <Card
                  style={{ display: "flex", padding: 3, flexDirection: "column" }}
                  elevation={2}
                  onClick={() => SampleCard_OnClick(sampleKey)}
                  interactive={getSampleProcesses(sampleKey) && getSampleProcesses(sampleKey)!.processes?.length > 0} >
                  <div style={{ display: "flex", gap: 5, justifyContent: "space-between" }}>
                    <div style={{ display: "flex", gap: 5, marginLeft: 5, alignItems: "baseline" }}>
                      {getSampleByKey(sampleKey)?.id ? <PipelineStatusIcon sampleId={getSampleByKey(sampleKey)!.id} status={getPipelineStatus(sampleKey)} ncbiPipeline={isNCBIPipeline()}></PipelineStatusIcon> : <Icon icon={"cross-circle"} intent="danger" title="Sample not found."></Icon>}
                      <Button minimal rightIcon="open-application" title="View sample details." style={{ fontWeight: 600 }} onClick={() => onclick_openSampleDialog(getSampleByKey(sampleKey)?.id)}>{sampleKey}</Button>
                      <div>{`${getSampleByKey(sampleKey)?.data.metadata.GENUS ?? ""} ${getSampleByKey(sampleKey)?.data.metadata.SPECIES ?? ""}`}</div>
                    </div>
                    <div style={{ display: "flex", gap: 5 }}>
                      {getSampleProcesses(sampleKey)?.processes && getSampleProcesses(sampleKey)!.processes?.filter(v => v.status === "FAILED")?.length > 0 && <div style={{ display: "flex", gap: 5, margin: 5 }}>
                        Failed Processes: {getSampleProcesses(sampleKey)?.processes.filter(v => v.status === "FAILED").length}
                      </div>}
                      {getSampleProcesses(sampleKey)?.processes && getSampleProcesses(sampleKey)!.processes?.length > 0 && <div style={{ display: "flex", gap: 5, margin: 5 }}>
                        Completed Processes: {getSampleProcesses(sampleKey)?.processes.filter(v => v.status === "COMPLETED").length}
                      </div>}
                    </div>
                  </div>
                  <Collapse isOpen={collapsedSamples[sampleKey]}>
                    <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                      {getSampleProcesses(sampleKey)?.processes.map((process, i) => <Card key={i} style={{ padding: 5 }}>
                        <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
                          <div style={{ display: "flex", gap: 5 }}>
                            <Icon icon="flows"></Icon>
                            <strong>{process.name}</strong>
                          </div>
                          <div style={{ display: "flex", gap: 5 }}>
                            <div style={{ width: 20 }}>
                              {["SUBMITTED", "RUNNING"].includes(process.status) ? isError() ? <Icon icon="warning-sign" intent="warning" title="Process did not complete." /> : <Spinner size={15}></Spinner> : null}
                              {["FAILED", "ABORTED"].includes(process.status) ? <Icon icon="error" intent="danger"></Icon> : null}
                              {["COMPLETED"].includes(process.status) ? <Icon icon="tick" intent="success"></Icon> : null}
                            </div>
                            <div>{process.status}</div>
                            <div>{process.updated}</div>
                          </div>
                        </div>
                      </Card>)}
                    </div>
                  </Collapse>
                </Card>)}
              </div>
              <div className="button-row2" style={{ display: "flex", gap: 5, }}>
                {/* <em style={{ margin: "10px 200px 0px 0px" }}>{`Pipeline run started by ${createdBy} at ${getDateTimeString(createdAt)}.`}</em> */}
                {/* {weblogLoading ? <Spinner size={20}></Spinner> : null} */}
                {/* {selectedPipelineRunId ? <Button icon="refresh" disabled={weblogLoading} onClick={() => loadWeblog(selectedPipelineRunId)}>Refresh</Button> : null} */}
                {["Error", "Completed", "Cancelled", "Completed with Errors"].includes(status) && user?.userId === pipelineRun?.createdBy && pipelineRun?.id ? <Button icon="refresh" onClick={() => restartPipelineRun(pipelineRun.id)}>Restart Pipeline</Button> : null}
                {["Pending", "Running", "Started", "Initiating"].includes(status) && pipelineRun?.id ? <Button icon="disable" intent="danger" onClick={() => cancelPipelineRun(pipelineRun.id)}>Cancel Pipeline</Button> : null}
                <Button onClick={() => {
                  setShowPipelineRunsDialog(false)
                  setShowPipelineRunsDrawer(true)
                }}>All Pipeline Runs</Button>
                <Button onClick={() => setShowPipelineRunsDialog(false)}>Close</Button>
              </div>
            </div>
          </form>
        }
      </div>
    </Dialog> : null}
    {(!showPipelineRunsDialog) && loading ?
      <div className="dialog-body3">
        <Spinner></Spinner>
      </div>
      :
      <Dialog
        isOpen={showErrorDialog}
        title="Error"
        icon="error"
        onClose={() => setShowErrorDialog(false)}
        style={{ width: "512px" }}
      >
        <div className="bp3-dialog-body" style={{ textAlign: "left", margin: "15px" }}>
          {errorMessage}
        </div>
        <div className="bp3-dialog-footer" style={{ display: "flex", justifyContent: "flex-end" }}>
          <div style={{ marginRight: "10px" }}>
            <Button intent="danger" onClick={() => setShowErrorDialog(false)}>Cancel</Button>
          </div>
        </div>
      </Dialog>}
  </>
}

interface PipelineTrackerProps {
  weblog: Weblog[]
  pipelineRun?: PipelineRun
}

interface CollapsedSamples {
  [identifier: string]: boolean
}

const PipelineTracker = ({ weblog, pipelineRun }: PipelineTrackerProps) => {

  const getSampleKeyFromScript = (script: string) => {
    let match = script.match(/--sample-id\s+([\w\\-]+)/)
    return match ? match[1] : ""
  }

  const getFlatWeblog = () => {
    const mappedWeblog = weblog.map(v => ({
      sample: v.trace ? getSampleKeyFromScript(v.trace.script) : "",
      process: v.trace ? v.trace.process : "Nextflow Head",
      event: v.event,
      datetime: getDateTimeString(v.utcTime),
      errorMessage: v.metadata?.workflow?.errorReport,
      status: v.trace ? v.trace.status : null
    }))
    return mappedWeblog
  }

  const getFormattedWeblog = () => {
    return getFlatWeblog().reduce((pv, cv) => {
      if (cv.sample) {
        let sample = pv.filter(v => v.identifier === cv.sample)[0]
        if (sample) {//update sample
          sample.currentProcess = cv.process
          sample.currentProcessStatus = cv.status
          sample.updated = cv.datetime
          let process = sample.processes.filter(v => v.name === cv.process)[0]
          if (process) {//update process and add log
            process.updated = cv.datetime
            process.status = cv.status
            process.processLog.push({
              event: cv.status,
              datetime: cv.datetime
            })
          }
          else {//Add new process
            sample.processes.push({
              name: cv.process,
              status: cv.status,
              updated: cv.datetime,
              processLog: [{
                event: cv.status,
                datetime: cv.datetime
              }]
            })
          }
        }
        else {
          pv.push({//Add sample
            identifier: cv.sample,
            updated: cv.datetime,
            currentProcess: cv.process,
            currentProcessStatus: cv.status,
            processes: [
              {
                name: cv.process,
                status: cv.status,
                updated: cv.datetime,
                processLog: [{
                  event: cv.status,
                  datetime: cv.datetime
                }]
              }
            ]
          } as AggregatedWeblogSample)
        }
      }
      else {
        pv = pv.filter(v => v.identifier !== cv.process)
        pv.push({//Add sample
          identifier: cv.process,
          updated: cv.datetime,
          currentProcess: cv.process,
          currentProcessStatus: cv.status,
          processes: [
            {
              name: cv.process,
              status: cv.status,
              updated: cv.datetime,
              processLog: [{
                event: cv.status,
                datetime: cv.datetime
              }]
            }
          ]
        } as AggregatedWeblogSample)
      }
      return pv
    }, [] as AggregatedWeblogSample[])
  }

  const getErrorEmailMessage = (pipelineRun: PipelineRun) => {
    const errorMessage = getFlatWeblog().find(v => v.event === "completed")?.errorMessage

    return pipelineRun?.errors.map(v => `${v.sampleKey ? v.sampleKey : "Error"} - ${v.errorMessage}`).join('\n')
      .concat(errorMessage ? `\nNextflow Error - ${errorMessage}` : "")
  }

  return <>
    <div style={{ display: "flex", flexDirection: "column", gap: 5, padding: "1px 5px 0 5px", width: 450, marginTop: 5 }}>
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", gap: 5 }}>
        <div>
          <Card style={{ display: "flex", gap: 5, alignItems: "center", padding: 10 }}>
            {pipelineRun?.status && ["Started", "Initiating", "Running", "Pending", "Restarting"].includes(pipelineRun?.status) ?
              <Spinner size={20}></Spinner>
              : null}
            {pipelineRun?.status && ["Error", "Completed with Errors"].includes(pipelineRun?.status) ?
              <Icon icon="error" intent="danger"></Icon>
              : null}
            {pipelineRun?.status && ["Completed"].includes(pipelineRun?.status) ?
              <Icon icon="tick-circle" intent="success"></Icon>
              : null}
            <H5 style={{ margin: 0, padding: 0 }}>Pipeline {pipelineRun?.status}</H5>
          </Card>
        </div>
        <Card style={{ display: "flex", flexDirection: "column", gap: 5, padding: 10 }}>
          <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            <div style={{display: "flex", justifyContent:"space-between"}}>
              <strong>Pipeline Initiated</strong>
              <div>{getDateTimeString(pipelineRun?.createdAt)}</div>
            </div>
            {getFlatWeblog().filter(v => ["started", "completed"].includes(v.event)).map((v, i) => <>
              {v.event === "started" ? <div key={i} style={{display: "flex", justifyContent:"space-between"}}>
                <strong>Pipeline Started</strong>
                <div>{v.datetime}</div>
              </div> : null}
              {v.event === "completed" ? <div key={i} style={{display: "flex", justifyContent:"space-between"}}>
                <strong>Pipeline Completed</strong>
                <div>{v.datetime}</div>
              </div> : null}
              {v.errorMessage ?
                <Popover2
                  key={"error_k"}
                  interactionKind="click"
                  content={<div style={{ padding: 5 }}><Pre style={{ overflow: "auto", margin: 0, maxWidth: 600, maxHeight: 300 }} >
                    {v.errorMessage}
                  </Pre>        </div>
                  }>
                  {/* <Button minimal intent="danger" icon={"error"} onClick={() => setShowError(!showError ? v.errorMessage : null)}>Error</Button> */}
                  <Button minimal intent="danger" icon={"error"}>Error</Button>
                </Popover2> : null}
            </>)}
          </div>
        </Card>
      </div>
      {pipelineRun?.status && !getFormattedWeblog().length && ["Running"].includes(pipelineRun?.status) ?
        <Card>
          <div style={{ display: "flex", gap: 5, alignItems: "center" }}>
            <Spinner size={30}></Spinner>
            <H5>Nextflow Pipeline Initializing</H5>
          </div>
        </Card>
        : null}
      {pipelineRun?.errors && pipelineRun?.errors.length > 0 && <Card style={{ display: "flex", flexDirection: "column", gap: 5, margin: "5px 0px 5px 0px" }}>
        <div style={{ display: "flex", gap: 5 }}>
          <Icon icon="error" intent="danger"></Icon>
          <H4>Pipeline Errors</H4>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: 5, maxHeight: 100, overflow: "auto", padding: 5 }}>
          {pipelineRun?.errors.map(error => <Card style={{ display: "flex", flexDirection: "column", gap: 5, padding: 5 }}>
            <div style={{ display: "flex", gap: 5 }}>
              {error.sampleKey && <div><strong>Sample Key:</strong> {error.sampleKey}</div>}
            </div>
            <div><strong>Error Message:</strong> {error.errorMessage}</div>
          </Card>)}
        </div>
        <div>
          <Button onClick={() => sendMail(pipelineRun.id, getErrorEmailMessage(pipelineRun))}>Report Error</Button>
        </div>
      </Card>}
    </div>
  </>
}

function sendMail(pipelineRunId: string, errorMessage: string) {
  var link = "mailto:pulsenet@cdc.gov"
    + "?subject=" + encodeURIComponent("PulseNet2.0 Pipeline Run Error Report")
    + "&body=" + encodeURIComponent(`A pipeline run error occured for pipeline run id ${pipelineRunId} . Please see the details below:\n\n ${errorMessage}`);
  window.location.href = link;
}