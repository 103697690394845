import { create } from 'zustand'
import { Sample } from '../types/SampleTypes';
import { Outbreak } from '../types/OutbreakTypes';


interface OutbreakDialogState {
  loading: boolean;
  showOutbreakForm: boolean;
  setShowOutbreakForm: (showOutbreakForm: boolean) => void
  selectedSampleId?: string | undefined;
  setSelectedSampleId: (selectedSampleId?: string | undefined) => void
  outbreaks: Outbreak[]
  setOutbreaks: (outbreaks: Outbreak[]) => void
  selectedOutbreak: Outbreak | undefined
  setSelectedOutbreak: (selectedOutbreak?: Outbreak | undefined) => void
  associatedSamples: Sample[]
  setAssociatedSamples: (samples: Sample[]) => void
  nextKey: string;
  setNextKey: (nextKey: string) => void
  isCreatingNewOutbreak: boolean,
  setIsCreatingNewOutbreak: (isCreatingNewOutbreak: boolean) => void
}


export const useOutbreakDialogStore = create<OutbreakDialogState>((set) => ({
  selectedSampleId: undefined,
  setSelectedSampleId: (selectedSampleId?: string | undefined) => set({ selectedSampleId }),
  loading: false,
  showOutbreakForm: false,
  setShowOutbreakForm: (showOutbreakForm: boolean) => set({ showOutbreakForm }),
  associatedSamples: [],
  setAssociatedSamples: (associatedSamples) => {
    set({ associatedSamples })
  },
  outbreaks: [],
  setOutbreaks: (outbreaks) => {
    set({ outbreaks })
  },
  selectedOutbreak: undefined,
  setSelectedOutbreak: (selectedOutbreak: Outbreak | undefined) => set({ selectedOutbreak }),
  nextKey: '',
  setNextKey: (nextKey: string) => set({ nextKey }),
  isCreatingNewOutbreak: false,
  setIsCreatingNewOutbreak: (isCreatingNewOutbreak: boolean) => set({ isCreatingNewOutbreak })
}))