import { create } from 'zustand'
import { Pattern } from '../types/PatternTypes';

interface PatternState {
  patterns: Pattern[]
  setPatterns: (patterns: Pattern[]) => void
}


export const usePatternStore = create<PatternState>((set) => {  
  return {
    patterns: [],
    setPatterns: (patterns) => {
      set({ patterns })
    }
  }
});