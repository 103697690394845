import { create } from "zustand";
import { PipelineRun } from "../types/PipelineRunTypes";
import { SampleKeyIdMapping } from "../types/SampleTypes";
import { Pipeline } from "../types/PipelineType";

interface PipelineRunsDialogState {
  createdAt: string
  createdBy: string
  errorMessage: string
  labId: string
  loading: boolean
  pipelineRun: PipelineRun | undefined
  sampleKeyIdMap: SampleKeyIdMapping[]
  status: string
  showErrorDialog: boolean
  showPipelineRunsDialog: boolean
  updatedAt: string | null
  updatedBy: string | null  
  pipeline: Pipeline | undefined
  setPipeline: (pipeline: Pipeline) => void
  setCreatedAt: (createdAt: string) => void
  setCreatedBy: (createdBy: string) => void
  setErrorMessage: (errorMessage: string) => void
  setSampleKeyIdMap: (sampleKeyIdMapping: SampleKeyIdMapping[]) => void
  setLabId: (labId: string) => void
  setLoading: (loading: boolean) => void
  setPipelineRun: (pipelineRun: PipelineRun | undefined) => void
  setStatus: (status: string) => void
  setShowErrorDialog: (showErrorDialog: boolean) => void
  setUpdatedAt: (updatedAt: string | null) => void
  setUpdatedBy: (updatedBy: string | null) => void  
}

export const usePipelineRunsDialogStore = create<PipelineRunsDialogState>((set) => ({
  createdAt: "",
  createdBy: "",
  errorMessage: "",
  labId: "",
  loading: false,
  pipelineRun: undefined,
  sampleKeyIdMap: [],
  status: "",
  showErrorDialog: false,
  showPipelineRunsDialog: false,
  updatedAt: "",
  updatedBy: "",
  pipeline: undefined,
  setPipeline: (pipeline) => set({ pipeline }),  
  setCreatedAt: (createdAt: string) => set({ createdAt }),
  setCreatedBy: (createdBy: string) => set({ createdBy }),
  setErrorMessage: (errorMessage: string) => set({ errorMessage }),
  setSampleKeyIdMap: (sampleKeyIdMap: SampleKeyIdMapping[]) => set({ sampleKeyIdMap }),
  setLabId: (labId: string) => set({ labId }),
  setLoading: (loading: boolean) => set({ loading }),
  setPipelineRun: (pipelineRun: PipelineRun | undefined) => set({ pipelineRun }),
  setStatus: (status: string) => set({ status }),
  setShowErrorDialog: (showErrorDialog: boolean) => set({ showErrorDialog }),
  setUpdatedAt: (updatedAt: string | null) => set({ updatedAt }),
  setUpdatedBy: (updatedBy: string | null) => set({ updatedBy }),  
}))