import { create } from "zustand"

interface ComparisonsDrawerState {
  showComparisonDrawer: boolean
  loading: boolean
  setLoading: (loading: boolean) => void
}

export const useComparisopnDrawerStore = create<ComparisonsDrawerState>((set) => ({
  showComparisonDrawer: false,
  loading: false,
  setLoading: (loading: boolean) => set({ loading })
}))