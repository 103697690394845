import { create } from 'zustand';
import { Lab } from '../types/LabTypes';


interface LabsState {
  labs?: Lab[]
  userLab?: Lab
  setLabs: (labs: Lab[]) => void
  setUserLab: (userLab: Lab) => void
}

export const useLabsStore = create<LabsState>((set) => ({
  setLabs: (labs) => set({ labs }),
  setUserLab: (userLab) => set({userLab})
}));