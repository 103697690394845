import { create } from "zustand";
import { ComparisonGroup } from "../types/ComparisonTypes";

interface AddToGroupDialogState {
  group: ComparisonGroup | undefined
  isColorPickerDialogOpen: boolean
  selectedGroup: ComparisonGroup | undefined
  showAddToGroupDialog: boolean
  showInput: boolean
  setGroup: (group: ComparisonGroup | undefined) => void
  setIsColorPickerDialogOpen: (isColorPickerDialogOpen: boolean) => void
  setSelectedGroup: (selectedGroup: ComparisonGroup | undefined) => void
  setShowAddToGroupDialog: (showAddToGroupDialog: boolean) => void
  setShowInput: (showInput: boolean) => void
}

export const useAddToGroupDialogStore = create<AddToGroupDialogState>((set) => ({
  group: undefined,
  isColorPickerDialogOpen: false,
  selectedGroup: undefined,
  showAddToGroupDialog: false,
  showInput: false,
  setGroup: (group: ComparisonGroup | undefined) => set({ group }),
  setIsColorPickerDialogOpen: (isColorPickerDialogOpen: boolean) => set({ isColorPickerDialogOpen }),
  setSelectedGroup: (selectedGroup: ComparisonGroup | undefined) => set({ selectedGroup }),
  setShowAddToGroupDialog: (showAddToGroupDialog: boolean) => set({ showAddToGroupDialog }),
  setShowInput: (showInput: boolean) => set({ showInput })
}))