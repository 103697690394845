import { create } from 'zustand';
import { Organization } from '../types/OrganizationTypes';

interface OrganizationState {
  organization?: Organization
  setOrganization: (organization: Organization) => void
  organizationList?: Organization[]
  setOrganizationList: (organizationList: Organization[]) => void
}

export const useOrganizationStore = create<OrganizationState>((set) => ({
  setOrganization: (organization) => {
    set({ organization })
  },
  setOrganizationList: (organizationList) => {
    set({ organizationList })
  }
}));