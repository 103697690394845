import { create } from 'zustand';
import { Pipeline } from '../types/PipelineType';


interface PipelinesState {
  pipelines?: Pipeline[]
  setPipelines: (pipelines: Pipeline[]) => void
}

export const usePipelinesStore = create<PipelinesState>((set) => ({
  setPipelines: (pipelines) => {
    set({ pipelines })
  }
}));