import { create } from 'zustand'
import { FavoriteComparison, FavoriteView, ImportTemplate, User } from '../types/UserTypes';
import cloneDeep from 'lodash.clonedeep';
import { UserAPI } from '../api/DataAPI';

interface UserState {
  user?: User
  loggedOut?: boolean
  setLoggedOut: (loggedOut: boolean) => void
  loadUser: (token: string) => Promise<User>
  setUser: (user: User) => void
  addFavoriteView: (viewId: string, token: string, organism?: string, organization?: string, defaultView?: boolean) => void
  deleteFavoriteView: (viewId: string, token: string) => void
  addFavoriteComparison: (comparisonId: string, token: string, organism?: string, organization?: string) => void
  deleteFavoriteComparison: (comparisonId: string, token: string) => void
  addImportTemplate: (importTemplate: ImportTemplate, token: string) => void
  deleteImportTemplate: (importTemplate: ImportTemplate, token: string) => void
  getDefaultFavoriteView: (organization: string, organism: string) => FavoriteView | undefined
}

export const useUserStore = create<UserState>((set, get) => {
  return {
    setLoggedOut: (loggedOut: boolean) => {
      set({ loggedOut })
    },
    loadUser: async (token: string) => {
      const userAPI = new UserAPI(token)
      let user = await userAPI.getcurrentuser()
      user = {
        ...user,
        favoriteViews: await Promise.all(user.favoriteViews.map(async v => ({
          viewId: v.viewId,
          defaultView: v.defaultView,
          organism: v.organism,
          organization: v.organization
        })))
      }
      set({ user })
      return user
    },
    setUser: (user: User) => {
      set({ user })
    },
    // deleteUser: async (userId: number) => {
    //   await userAPI.deleteUser(userId)
    //   const users = await userAPI.getAllUsers()
    //   set({users})
    // }
    addFavoriteView: (viewId: string, token: string, organism?: string, organization?: string, defaultView?: boolean) => {
      const userAPI = new UserAPI(token);
      set(({ user }) => {
        if (user) {
          user = cloneDeep(user)
          let newFavoriteViews: FavoriteView[] = []
          if (!user.favoriteViews.map(v => v.viewId).includes(viewId)) {
            newFavoriteViews = [...user.favoriteViews, {
              viewId,
              organism,
              defaultView: !!defaultView,
              organization
            }]
            user.favoriteViews = newFavoriteViews
            userAPI.update(user.id, user)
          }
        }
        return ({ user })
      })
    },
    deleteFavoriteView: (viewId: string, token: string) => {
      const userAPI = new UserAPI(token);
      set(({ user }) => {
        if (user) {
          user = cloneDeep(user)
          user.favoriteViews = user.favoriteViews.filter(v => v.viewId !== viewId)
          userAPI.update(user.id, user)
        }
        return ({ user })
      })
    },
    addFavoriteComparison: (comparisonId: string, token: string, organism?: string, organization?: string) => {
      const userAPI = new UserAPI(token);
      set(({ user }) => {
        if (user) {
          user = cloneDeep(user)
          let newFavoriteComparisons: FavoriteComparison[] = []
          if (!user.favoriteComparisons.map(v => v.comparisonId).includes(comparisonId)) {
            newFavoriteComparisons = [...user.favoriteComparisons, { comparisonId, organism, organization }]
            user.favoriteComparisons = newFavoriteComparisons
            userAPI.update(user.id, user)
          }
        }
        return ({ user })
      })
    },
    deleteFavoriteComparison: (comparisonId: string, token: string) => {
      const userAPI = new UserAPI(token);
      set(({ user }) => {
        if (user) {
          user = cloneDeep(user)
          user.favoriteComparisons = user.favoriteComparisons.filter(v => v.comparisonId !== comparisonId)
          userAPI.update(user.id, user)
        }
        return ({ user })
      })
    },
    addImportTemplate: (importTemplate: ImportTemplate, token: string) => {
      const userAPI = new UserAPI(token);
      set(({ user }) => {
        if (user) {
          user = cloneDeep(user)
          let newImportTemplates: ImportTemplate[] = []
          if (user) {
            newImportTemplates = [
              ...(user.importTemplates?.filter(v => v.name !== importTemplate.name) ?? []).map(v => ({
                ...v,
                defaultPattern: (v.defaultPattern && v.field === importTemplate.field) ? false : v.defaultPattern
              })),
              importTemplate
            ]
            user.importTemplates = newImportTemplates
            userAPI.update(user.id, user)
          }
        }
        return ({ user })
      })
    },
    deleteImportTemplate: (importTemplate: ImportTemplate, token: string) => {
      const userAPI = new UserAPI(token);
      set(({ user }) => {
        if (user) {
          user = cloneDeep(user)
          let newImportTemplates: ImportTemplate[] = []
          if (user) {
            newImportTemplates = [...(user.importTemplates?.filter(v => v.name !== importTemplate.name) ?? [])]
            user.importTemplates = newImportTemplates
            userAPI.update(user.id, user)
          }
        }
        return ({ user })
      })
    },
    getDefaultFavoriteView: (organization, organism) => {
      const favoriteViews = get().user?.favoriteViews ?? []
      const defaultView = favoriteViews.find((view) => view.defaultView === true && view.organism === organism && view.organization === organization)
      const topView = favoriteViews.find((view) => view.organism === organism && view.organization === organization)
      if (defaultView) 
        return defaultView      
      else if (topView)
        return topView
      else
        return (undefined)      
    }
  }
});