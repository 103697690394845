import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button } from "@blueprintjs/core/lib/esm/components/button/buttons";
import { ControlGroup } from "@blueprintjs/core/lib/esm/components/forms/controlGroup";
import { InputGroup } from "@blueprintjs/core/lib/esm/components/forms/inputGroup";
import { Control } from "react-hook-form/dist/types/form";
import { useController } from "react-hook-form";
import { Comparison, ComparisonGroup } from "../../types/ComparisonTypes";
import { ColorResult } from 'react-color';
import { Icon } from "@blueprintjs/core/lib/esm/components";
import { ColorPickerDialog } from "../ColorPickerDialog";

interface ComparisonGroupControlProps {
  initialGroup: ComparisonGroup
  removeGroup: () => void
  control: Control<Comparison, any>
  index: number
  setNewGroupName?: Dispatch<SetStateAction<string | undefined>>
  updatedGroups: Map<string, string>,
  setUpdatedGroups: (updatedGroups: Map<string, string>) => void
}

export function ComparisonGroupControl({ initialGroup, removeGroup, index, control, setNewGroupName, updatedGroups, setUpdatedGroups }: ComparisonGroupControlProps) {

  const { field: { value, onChange }, fieldState: { error } } = useController({ name: `groups.${index}`, control })
  const [group, setGroup] = useState<ComparisonGroup>(initialGroup)
  const [isColorPickerDialogOpen, setIsColorPickerDialogOpen] = useState(false);

  const onChangeColor = (colorResult: ColorResult) => {
    onChange({ ...value, color: colorResult.hex })
    setGroup({
      name: group?.name ?? "",
      color: colorResult.hex
    })
  }

  const updateGroupNames = (newName: string) => {
    const groupsMap = new Map(updatedGroups)
    if (initialGroup.name !== newName) {
      groupsMap.set(initialGroup.name, newName)
    } else if (updatedGroups.has(initialGroup.name)) {
      groupsMap.delete(initialGroup.name)
    }
    setUpdatedGroups(groupsMap)
  }

  const colorPickerMenu = (
    <Button
      icon={<Icon size={25} style={{ color: group?.color }} icon="tint" />}
      minimal={true}
      onClick={() => {
        setIsColorPickerDialogOpen(true)
        let element = document.getElementsByClassName('comparison-form')?.[0]?.parentElement?.parentElement
        if (element !== null && element !== undefined) {
          element.style.zIndex = '20'
        }
      }}
    />
  )

  useEffect(() => {
    if (setNewGroupName) {
      setNewGroupName(value?.name);
    }
    updateGroupNames(value?.name)
  }, [value?.name])

  return <>
    <ControlGroup fill={false} vertical={false} style={{ marginBottom: "3px" }}>
      <InputGroup
        placeholder="Enter a group name..."
        rightElement={colorPickerMenu}
        type={"text"}
        value={value?.name}
        onChange={(e) => onChange({ ...value, name: e.target.value })}
        intent={error ? "danger" : "none"}
      />
      <Button icon={"trash"} minimal={true} name="Delete Group" onClick={() => removeGroup()} />
    </ControlGroup>
    <ColorPickerDialog
      isOpen={isColorPickerDialogOpen}
      onClose={() => {
        setIsColorPickerDialogOpen(false)
        let element = document.getElementsByClassName('comparison-form')?.[0]?.parentElement?.parentElement
        if (element !== null && element !== undefined) {
          element.style.zIndex = '20'
        }
      }}
      color={group?.color ?? ''}
      onChangeComplete={onChangeColor}
    />
  </>
}